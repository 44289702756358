import { Delta } from '../delta';
import { DeltaType } from '../../../../util/delta-type.annotation';
import { StaticInject, StaticInjector } from '../../../../util/lifecycle/static-injector';
import { Userspace } from '../../../../model/userspace.service';
import { StorageService } from '../../../../storage.service';

@DeltaType('userSetting')
export class UserSettingDelta extends Delta {
  @StaticInject(StorageService)
  private storageService: StorageService;

  public key: string;
  public oldValue: string;
  public newValue: string;

  constructor(key: string, oldValue: string, newValue: string) {
    super();
    this.key = key;
    this.oldValue = oldValue;
    this.newValue = newValue;
  }

  public apply(): void {
    const userspace = StaticInjector.inject(Userspace);
    const user = userspace.getUserStore().findLoggedInUser();
    user.updateSetting(this.key, this.newValue);
    this.storageService.setCurrentUser(user.buildDto());
  }

  public override onConfirmation(confirmationDelta: Delta): void {}

  public override getUndoDelta(): Delta {
    return new UserSettingDelta(this.key, this.newValue, this.oldValue);
  }

  public override getErrorMessage(): string {
    return `Einstellung konnte nicht gespeichert werden!`;
  }
}
