import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class GrafitiLogger {
  private readonly entries: Entry[] = [];
  private readonly maxSize = 1000;
  private readonly entries$ = new BehaviorSubject<Entry[]>([]);

  public constructor() {
    const _consoleLog = console.log;
    console.log = (...data: any[]) => {
      this.log(...data);
      _consoleLog(...data);
    };

    const _consoleWarn = console.warn;
    console.warn = (...data: any[]) => {
      this.warn(...data);
      _consoleWarn(...data);
    };

    const _consoleError = console.error;
    console.error = (...data: any[]) => {
      this.error(...data);
      _consoleError(...data);
    };
  }

  public log(...data: any[]): void {
    this.write('LOG', data);
  }

  public warn(...data: any[]): void {
    this.write('WARN', data);
  }

  public error(...data: any[]): void {
    this.write('ERROR', data);
  }

  private write(level: Level, data: any[]): void {
    this.entries.push({
      level,
      data,
    });
    if (this.entries.length > this.maxSize) {
      this.entries.shift();
    }
    this.update();
  }

  private update(): void {
    this.entries$.next(this.entries);
  }

  public getEntries$(): Observable<Entry[]> {
    return this.entries$;
  }
}

export interface Entry {
  data: any[];
  level: Level;
}
type Level = 'LOG' | 'WARN' | 'ERROR';
