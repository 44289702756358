import { Component } from '@angular/core';

@Component({
  selector: 'grafiti-reset-code-sent',
  template: `
    <mat-card class="grpanel">
      <mat-card-title>
        <h1 id="title" class="tw-font-bold">Bestätigungscode gesendet</h1>
      </mat-card-title>
      <mat-card-content>
        Ein Bestätigungscode wurde an die angegebene Addresse gesendet, sofern diese existiert. Bitte überprüfen Sie ihr
        eMail-Postfach, und ggfs. den Spam-Ordner.
      </mat-card-content>
    </mat-card>
  `,
})
export class OnResetCodeSentComponent {}
