import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { Injector, isDevMode, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from './@core/core.module';
import { ThemeModule } from './@theme/theme.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { LoggedInGuard } from './auth/guard/logged-in.guard';
import { AuthenticationModule } from './auth/auth.module';
import { StaticInjector } from './util/lifecycle/static-injector';
import { CommunicationModule } from './communication/communication.module';
import { Router, RouteReuseStrategy } from '@angular/router';
import { MapModule } from './@map/map.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MyStrategy } from './router-reuse-strategy';
import { GrafitiTranslateModule } from './translation/grafiti-translate.module';
import { provideToastr, ToastrModule } from 'ngx-toastr';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { ServiceWorkerModule, SwUpdate } from '@angular/service-worker';
import { IsAdminGuard } from './auth/guard/is-admin.guard';
import { LoggingModule } from './logging/logging.module';
import { requestReload } from './util/request-reload';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletGeomanModule } from '@dvina/ngx-leaflet-geoman';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    CoreModule.forRoot(),
    ThemeModule.forRoot(),
    AuthenticationModule,
    CommunicationModule,
    MapModule,
    FontAwesomeModule,
    GrafitiTranslateModule,
    ToastrModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    LoggingModule,
    LeafletModule,
    LeafletGeomanModule,
  ],
  providers: [
    LoggedInGuard,
    IsAdminGuard,
    { provide: RouteReuseStrategy, useClass: MyStrategy },
    provideAnimations(),
    provideToastr(),
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  public constructor(private readonly injector: Injector, private readonly router: Router) {
    StaticInjector.init(injector);

    try {
      const swu = StaticInjector.inject(SwUpdate);
      swu?.checkForUpdate().then((update) => {
        if (update) {
          console.log('Update found');
          requestReload('Die Anwendung muss für ein Update neu gestartet werden');
        }
      });
    } catch (err) {
      console.error((err as Error)?.message);
    }
  }
}

export interface GrRouteConfigData {
  /**
   * Used to force router to rerender the component, when the url changes, but the same component is used (i.e. /users/:123 => /users/:456)
   * False by default.
   */
  preventReuse: boolean;
}
