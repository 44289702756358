import { JsonVisibility } from '../../../util/json';
import { UUID } from '../../../util/math/uuid';
import { DeltaFailedException } from '../delta-failed-exception';
import { deepEquals } from '../../../util/math/deep-equals';
import { JClass } from '../../../model/relink/jclass';

export const DELTA_CREATOR = UUID.randomUUID();

/**
 * Represents a difference in the dataset of the application and provides functionality on how to
 * apply this difference.
 */
export abstract class Delta extends JClass<Delta> {
  private readonly deltaId: UUID;
  private readonly serialId: number;

  private creationTime: number;

  private sentTime: number;

  private receivedTime: number;

  private creator: UUID;

  @JsonVisibility(false)
  private parent: Delta;

  /**
   * Whether the delta has been applied locally
   * @private
   */
  @JsonVisibility(false)
  private appliedLocally: boolean = false;

  private successful: boolean = false;

  protected constructor() {
    super();
    this.deltaId = UUID.randomUUID();
    this.creator = DELTA_CREATOR;
    this['type'] = this.getType();
    this.setCreationTime(Date.now());
  }

  // Delta lifecycle methodes
  public init(): void {}

  public prePublish(): void {}

  public abstract apply(): void;

  public abstract onConfirmation(confirmationDelta: Delta): void;

  /**
   * Gets called when the delta failes and no undo delta is specified
   */
  public onFailure(): void {}

  public flatten(): Delta[] {
    return [this];
  }

  /**
   * Returns the delta reverting the changes made by this delta
   */
  public getUndoDelta(): Delta {
    return null;
  }

  // regular accessors

  public getDeltaId(): UUID {
    return this.deltaId;
  }

  public getCreationTime(): number {
    return this.creationTime;
  }

  public setCreationTime(value: number): void {
    this.creationTime = value;
  }

  public getSentTime(): number {
    return this.sentTime;
  }

  public setSentTime(value: number): void {
    this.sentTime = value;
  }

  public getReceivedTime(): number {
    return this.receivedTime;
  }

  public setReceivedTime(value: number): void {
    this.receivedTime = value;
  }

  public getRTT(): number {
    return this.receivedTime - this.sentTime;
  }

  public isAppliedLocally(): boolean {
    return this.appliedLocally;
  }

  public setAppliedLocally(value: boolean): void {
    this.appliedLocally = value;
  }

  public wasSuccessful(): boolean {
    return this.successful;
  }

  /**
   * This is a random UUID and NOT the user, since one user could be online with two seperate clients at the same time!
   */
  public getCreator(): UUID {
    return this.creator;
  }

  public setCreator(creator: UUID): void {
    this.creator = creator;
  }

  public getParent(): Delta {
    return this.parent;
  }

  public setParent(parent: Delta): void {
    this.parent = parent;
  }

  public isMyOwnDelta(): boolean {
    return this.creator === DELTA_CREATOR;
  }

  public isStandalone(): boolean {
    return !this.parent;
  }

  public getSerialId(): number {
    return this.serialId;
  }

  public getType(): string {
    // Throws an error if the developer forgets to add the DeltaType annotation
    throw new Error('Delta has no type. Did you forget to add @DeltaType?');
  }

  public toString(): string {
    return this.getClassName() + '#' + this.getDeltaId();
  }

  public isUndoable(): boolean {
    return !!this.getUndoDelta();
  }

  public wouldBeNoop(): boolean {
    return false;
  }

  // Assertions

  /**
   * Uses deepEquals to check for equality
   * @param obj1
   * @param obj2
   * @param depth
   * @param message
   * @protected
   */
  protected requireDeepEquals<T>(obj1: T, obj2: T, depth: number, message: string): void {
    if (!deepEquals(obj1, obj2, depth)) {
      throw new DeltaFailedException(message, this);
    }
  }

  /**
   * uses === to check for equality
   * @param obj1
   * @param obj2
   * @param depth
   * @param message
   * @protected
   */
  protected requireEquals<T>(obj1: T, obj2: T, depth: number, message: string): void {
    if (obj1 !== obj2) {
      throw new DeltaFailedException(message, this);
    }
  }

  protected requireTrue(condition: boolean, message: string): void {
    if (!condition) {
      throw new DeltaFailedException(message, this);
    }
  }

  protected requireFalse(condition: boolean, message: string): void {
    this.requireTrue(!condition, message);
  }

  protected requireNonNullish<T>(object: T, message: string): T {
    if (!object) {
      throw new DeltaFailedException(message, this);
    }
    return object;
  }

  protected requireNullish<T>(object: T, message: string): T {
    if (!!object) {
      throw new DeltaFailedException(message, this);
    }
    return object;
  }

  public abstract getErrorMessage(): string;
}
