import { Delta } from '../delta';
import { DeltaType } from '../../../../util/delta-type.annotation';

import { JsonIgnore } from '../../../../util/json';
import { UUID } from '../../../../util/math/uuid';
import { GrafitiFlyerRun } from '../../../../model/entity/grafiti-flyer-run';
import { FromIndex } from '../../../../model/relink/managed-entity';
import { GrafitiFlyerType } from '../../../../model/entity/grafiti-flyer-type';

@DeltaType('updateFlyerRunType')
export class UpdateFlyerRunTypeDelta extends Delta {
  private readonly flyerRunId: UUID;

  private readonly oldTypeId: UUID;
  private readonly newTypeId: UUID;

  @JsonIgnore()
  @FromIndex<UpdateFlyerRunTypeDelta>((self) => self.flyerRunId)
  private flyerRun: GrafitiFlyerRun;

  @JsonIgnore()
  @FromIndex<UpdateFlyerRunTypeDelta>((self) => self.oldTypeId)
  private oldType: GrafitiFlyerType;

  @JsonIgnore()
  @FromIndex<UpdateFlyerRunTypeDelta>((self) => self.newTypeId)
  private newType: GrafitiFlyerType;

  public constructor(id: UUID, oldTypeId: UUID, newTypeId: UUID) {
    super();

    this.flyerRunId = id ?? this.flyerRunId;

    this.oldTypeId = oldTypeId ?? this.oldTypeId;
    this.newTypeId = newTypeId ?? this.newTypeId;
  }

  public apply(): void {
    if (this.newTypeId === this.flyerRun.getType().getId()) {
      return;
    }

    this.requireNonNullish(this.flyerRun, `Flyer run '${this.flyerRunId}' not found!`);
    this.requireNonNullish(this.oldType, `Flyer type '${this.oldTypeId}' not found!`);
    this.requireNonNullish(this.newType, `Flyer type '${this.newTypeId}' not found!`);
    this.flyerRun.setType(this.newType);
  }

  public onConfirmation(confirmationDelta: Delta): void {}

  public getUndoDelta(): Delta {
    return new UpdateFlyerRunTypeDelta(this.flyerRunId, this.newTypeId, this.oldTypeId);
  }

  public override getErrorMessage(): string {
    return `Flyertyp konnte nicht geändert werden!`;
  }
}
