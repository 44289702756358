<mat-card class="grpanel">
  <!--<mat-card-title class="tw-font-bold tw-text-blue-50">
    <h1 id="title" class="title">{{ 'AUTH.CONFIRM_RESET.TITLE' | translate }}</h1>
  </mat-card-title>-->
  <div class="image-container">
    <img src="assets/images/grafiti-logo-black.png" width="400px" />
  </div>
  <mat-card-subtitle>{{ 'AUTH.CONFIRM_RESET.SUBTITLE' | translate }} </mat-card-subtitle>

  <form (ngSubmit)="resetPass()" #resetPassForm="ngForm" aria-labelledby="title" class="tw-flex tw-flex-col tw-mb-6">
    <mat-form-field>
      <mat-label>{{ 'AUTH.CONFIRM_RESET.NEWPASS.LABEL' | translate }}</mat-label>
      <input
        matInput
        [(ngModel)]="user.password"
        #password="ngModel"
        type="password"
        id="input-password"
        name="password"
        class="first"
        placeholder="{{ 'AUTH.CONFIRM_RESET.NEWPASS.PLACEHOLDER' | translate }}"
        autofocus
        [required]="true"
        [minlength]="config.password.minLength"
        [maxlength]="config.password.maxLength"
        [attr.aria-invalid]="password.invalid && password.touched ? true : null" />
    </mat-form-field>
    <ng-container *ngIf="password.invalid && password.touched">
      <mat-error *ngIf="password.errors?.required">{{
        'AUTH.CONFIRM_RESET.NEWPASS.ERROR.REQUIRED' | translate
      }}</mat-error>
      <mat-error *ngIf="password.errors?.minlength || password.errors?.maxlength">
        {{
          'AUTH.CONFIRM_RESET.NEWPASS.ERROR.SIZE'
            | translate : { max: config.password.maxLength, min: config.password.minLength }
        }}
      </mat-error>
    </ng-container>

    <mat-form-field>
      <mat-label>{{ 'AUTH.CONFIRM_RESET.REPASS.LABEL' | translate }}</mat-label>
      <input
        matInput
        [(ngModel)]="user.confirmPassword"
        #rePass="ngModel"
        id="input-re-password"
        name="rePass"
        type="password"
        placeholder="{{ 'AUTH.CONFIRM_RESET.REPASS.PLACEHOLDER' | translate }}"
        [required]="true"
        [attr.aria-invalid]="rePass.invalid && rePass.touched ? true : null" />
    </mat-form-field>
    <ng-container *ngIf="rePass.touched">
      <mat-error *ngIf="rePass.invalid && rePass.errors?.required">{{
        'AUTH.CONFIRM_RESET.REPASS.ERROR.REQUIRED' | translate
      }}</mat-error>
      <mat-error *ngIf="password.value !== rePass.value && !rePass.errors?.required">
        {{ 'AUTH.CONFIRM_RESET.REPASS.ERROR.MATCHING' | translate }}
      </mat-error>
    </ng-container>

    <button mat-raised-button color="accent" [disabled]="submitted || !resetPassForm.valid">
      {{ 'AUTH.CONFIRM_RESET.SUBMIT' | translate }}
    </button>
  </form>

  <p>
    <a class="tw-text-blue-50 tw-font-bold" routerLink="../login">{{ 'AUTH.CONFIRM_RESET.TO_LOGIN' | translate }}</a>
  </p>
  <p>
    <a class="tw-text-blue-50 tw-font-bold" routerLink="../register">{{
      'AUTH.CONFIRM_RESET.TO_REGISTER' | translate
    }}</a>
  </p>
</mat-card>
