import { RoleDto } from './role.dto';
import { RoleAssignmentDto } from './role-assignment.dto';
import { PrivateUserDto } from './private-user.dto';
import { Observable } from 'rxjs';

export type GrafitiPermission =
  | 'READ'
  | 'MANAGE'
  | 'CREATE_CONTENT'
  | 'CREATE_TEMPLATES'
  | 'MUTATE_SELF'
  | 'MUTATE_CONTENT'
  | 'MUTATE_TEMPLATES'
  | 'DELETE_SELF'
  | 'DELETE_CONTENT'
  | 'DELETE_TEMPLATES'
  | 'CREATE_NOTES';

export const MUTATE_SELF_PERMISSION: GrafitiPermission = 'MUTATE_SELF';
export const MUTATE_TEMPLATES_PERMISSION: GrafitiPermission = 'MUTATE_TEMPLATES';
export const MUTATE_CONTENT_PERMISSION: GrafitiPermission = 'MUTATE_CONTENT';
export const READ_PERMISSION: GrafitiPermission = 'READ';
export const MANAGE_PERMISSION: GrafitiPermission = 'MANAGE';
export const DELETE_SELF_PERMISSION: GrafitiPermission = 'DELETE_SELF';
export const DELETE_CONTENT_PERMISSION: GrafitiPermission = 'DELETE_CONTENT';
export const DELETE_TEMPLATES_PERMISSION: GrafitiPermission = 'DELETE_TEMPLATES';
export const CREATE_NOTES_PERMISSION: GrafitiPermission = 'CREATE_NOTES';

export interface AccessManaged {
  hasPermission$(permission: GrafitiPermission): Observable<boolean>;
  hasPermission(permission: GrafitiPermission): boolean;
}

export function isAccessManaged(obj: unknown): obj is AccessManaged {
  return typeof obj['hasPermission'] === 'function' && typeof obj['hasPermission$'] === 'function';
}

export function getPermissionsFromRole(role: RoleDto): GrafitiPermission[] {
  const permissions: GrafitiPermission[] = [];

  const getValue = (value: string) => {
    return role.values.split(',').find((v) => v === value);
  };

  if (getValue('r')) {
    permissions.push(READ_PERMISSION);
  }
  if (getValue('m')) {
    permissions.push(MANAGE_PERMISSION);
  }

  if (getValue('cc')) {
    permissions.push('CREATE_CONTENT');
  }
  if (getValue('ct')) {
    permissions.push('CREATE_TEMPLATES');
  }

  if (getValue('ms')) {
    permissions.push(MUTATE_SELF_PERMISSION);
  }
  if (getValue('mc')) {
    permissions.push(MUTATE_CONTENT_PERMISSION);
  }
  if (getValue('mt')) {
    permissions.push(MUTATE_TEMPLATES_PERMISSION);
  }

  if (getValue('ds')) {
    permissions.push(DELETE_SELF_PERMISSION);
  }
  if (getValue('dc')) {
    permissions.push(DELETE_CONTENT_PERMISSION);
  }
  if (getValue('dt')) {
    permissions.push(DELETE_TEMPLATES_PERMISSION);
  }
  if (getValue('cn')) {
    permissions.push(CREATE_NOTES_PERMISSION);
  }

  return permissions;
}

export function collectPermissions(assignments: RoleAssignmentDto[], user: PrivateUserDto): GrafitiPermission[] {
  if (!!assignments?.length && !!user) {
    return assignments
      .filter((assignment) => assignment?.user?.id === user.id)
      .map((assignment) => assignment.role)
      .flatMap((role) => getPermissionsFromRole(role))
      .distinct();
  }
  return [];
}

export function select(self: GrafitiPermission, ...permission: GrafitiPermission[]): PermissionPipe {
  return new PermissionPipe(self, ...permission);
}

export function forContentDefault(self: GrafitiPermission): GrafitiPermission {
  return select(
    select(self, 'MANAGE', 'MUTATE_SELF', 'MUTATE_CONTENT').replaceWith('MUTATE_CONTENT'),
    'DELETE_SELF'
  ).replaceWith('DELETE_CONTENT');
}

export function forTemplatesDefault(self: GrafitiPermission): GrafitiPermission {
  return select(self, 'DELETE_SELF', 'MANAGE', 'MUTATE_SELF', 'MUTATE_CONTENT').replaceWith('MUTATE_TEMPLATES');
}

class PermissionPipe {
  private readonly original: GrafitiPermission;
  private readonly selection: GrafitiPermission[];

  public constructor(original: GrafitiPermission, ...permissions: GrafitiPermission[]) {
    this.original = original;
    this.selection = permissions;
  }

  public replaceWith(replacement: GrafitiPermission): GrafitiPermission {
    if (this.selection.some((p) => this.original === p)) {
      return replacement;
    }
    return this.original;
  }
}
