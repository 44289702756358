import { EnvironmentTypes } from './environment-types';
// also known as debug
export const environment: EnvironmentTypes = {
  production: false,
  // serverUrl: 'https://development.grafiti.info',
  backendPort: null,
  basePath: '/',
  webSocketEndpoint: 'api/websocket',
  restEndpoint: 'api',
  logging: {
    deltaReceiver: false,
  },
};
