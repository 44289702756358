import { Injectable } from '@angular/core';
import { KeyboardIoService } from './io/keyboard-io.service';
import { Observable, Subject } from 'rxjs';
import { buffer, debounceTime, filter, map, pairwise, take, tap, throttleTime } from 'rxjs/operators';
import { IoType, KeyboardIoEvent } from './io/model/keyboard-io-event';
import { KeyCombination } from './io/model/key-combination';

@Injectable()
export class KeySequenceService {
  private sameKeys = this.keyboardIoService.keyUp$.pipe(
    pairwise(),
    filter(([first, second]) => {
      const diff = second.timestamp - first.timestamp;
      return diff <= 400;
    }),
    filter((arr) => arr[0].keyCombination.key === arr[1].keyCombination.key)
  );

  public readonly doubleShift$: Observable<KeyboardIoEvent[]>;
  public constructor(private readonly keyboardIoService: KeyboardIoService) {
    this.doubleShift$ = this.listenToDoubleTap$('shift');
  }

  public listenToDoubleTap$(key: string): Observable<KeyboardIoEvent[]> {
    return this.sameKeys.pipe(filter((arr) => arr[0].keyCombination.key.toLowerCase() === key));
  }
}
