import { ProjectEntity } from './project-entity';
import { GrafitiProject } from './grafiti-project';
import { Observable } from 'rxjs';
import { FlyerTypeDto } from '../../dto/flyer-type.dto';
import { LeafletStyle } from '../../util/types/spatial-style';
import { map } from 'rxjs/operators';
import { ValueSubject } from '../../util/reactive/value-subject';
import { GrafitiEntity } from './grafiti-entity';
import { Searchable } from './searchable';
import { forTemplatesDefault, GrafitiPermission, AccessManaged } from '../../dto/permission';
import { Sortable } from './sortable';
import { StyleDto } from '../../dto/style.dto';

export class GrafitiFlyerType extends GrafitiEntity implements ProjectEntity, Searchable, AccessManaged, Sortable {
  public static readonly DEFAULT_STYLE: LeafletStyle = {
    weight: 8,
    stroke: true,
    color: 'rgba(255, 0, 0.8)',
  };

  private readonly project$: ValueSubject<GrafitiProject>;

  public constructor(dto: FlyerTypeDto, project: GrafitiProject) {
    super(dto);

    this.project$ = new ValueSubject<GrafitiProject>(project, this.destroy$);

    this.setPrice(dto.price);
    this.setMotive(dto.motive);
    this.setDescription(dto.description);
    this.setStyle((dto.style ?? GrafitiFlyerType.DEFAULT_STYLE) as unknown as LeafletStyle);
    this.ready();
  }

  public getProject(): GrafitiProject {
    return this.project$.getValue();
  }

  public getPrice(): number {
    return this.getEmbeddedAttribute('price');
  }

  public getPrice$(): Observable<number> {
    return this.getEmbeddedAttribute$('price');
  }

  public setPrice(value: number) {
    this.setEmbeddedAttribute('price', value);
  }

  public getMotive(): string {
    return this.getEmbeddedAttribute('motive');
  }

  public getMotive$(): Observable<string> {
    return this.getEmbeddedAttribute$('motive');
  }

  public setMotive(value: string) {
    this.setEmbeddedAttribute('motive', value);
  }

  public getDescription(): string {
    return this.getEmbeddedAttribute('description');
  }

  public getDescription$(): Observable<string> {
    return this.getEmbeddedAttribute$('description');
  }

  public setDescription(value: string) {
    this.setEmbeddedAttribute('description', value);
  }

  public getStyle(): LeafletStyle {
    return this.getEmbeddedAttribute('style');
  }

  public getStyle$(): Observable<LeafletStyle> {
    return this.getEmbeddedAttribute$('style');
  }

  public setStyle(value: LeafletStyle) {
    const style: StyleDto = {};
    Object.entries(value)
      .filter(([k, v]) => !!v)
      .forEach(([key, value]) => {
        style[key] = value;
      });
    this.setEmbeddedAttribute('style', style);
  }

  public getStyleString$(): Observable<string> {
    return this.getEmbeddedAttribute$('style').pipe(map((s) => JSON.stringify(s)));
  }

  /* this is not really elegant and I dislike these getters, but it makes the management table much more easier to work with
   * (at least for now, maybe this should be changed in the future)
   */
  public get motive(): string {
    return this.getMotive();
  }

  public get description(): string {
    return this.getDescription();
  }

  public get price(): number {
    return this.getPrice();
  }

  public get style(): LeafletStyle {
    return this.getStyle();
  }

  protected destructor(): void {}

  public buildDto(): FlyerTypeDto {
    return {
      ...super.buildDto(),
      projectId: this.getProject().getId(),
      motive: this.getMotive(),
      price: this.getPrice(),
      description: this.getDescription(),
      style: this.getStyle() as StyleDto,
    };
  }

  matchesQueryFilters(queries: string): boolean {
    return false;
  }

  matchesTextFilter(filter: string): boolean {
    return this.getEntireSearchString().includes(filter);
  }

  private getEntireSearchString(): string {
    return [this.getMotive(), this.getDescription()].join(',').toLowerCase();
  }

  hasPermission(permission: GrafitiPermission): boolean {
    return this.getProject().hasPermission(forTemplatesDefault(permission));
  }

  hasPermission$(permission: GrafitiPermission): Observable<boolean> {
    return this.getProject().hasPermission$(forTemplatesDefault(permission));
  }

  public getSortString(column: string): string {
    switch (column) {
      case 'motive':
        return this.getMotive();
      case 'description':
        return this.getDescription();
      default:
        return '';
    }
  }
}
