import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable()
export class SidebarService {
  private readonly changed$ = new Subject<void>();
  private readonly _left = new BehaviorSubject<boolean>(false);
  public readonly left$ = this._left.asObservable();

  public notify(): void {
    this.changed$.next();
  }

  public toggleLeft(): void {
    this._left.next(!this._left.getValue());
    this.notify();
  }

  public setLeftOpen(status: boolean): void {
    this._left.next(status);
    this.notify();
  }

  public onChanges$(): Observable<void> {
    return this.changed$.asObservable();
  }
}
