import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommunityDto } from '../dto/community.dto';
import { EntityController } from './entity-controller';
import { SparseCommunityDto } from '../dto/sparse-community.dto';
import { UUID } from '../util/math/uuid';
import { Observable, of } from 'rxjs';
import { PrivateUserDto } from '../dto/private-user.dto';
import { InviteLinkDto } from '../dto/invite-link.dto';
import { InviteLinkVerificationResponse } from '../dto/invite-link-verification-response';
import { catchError } from 'rxjs/operators';
import { SparseUserDto } from '../dto/sparse-user.dto';

@Injectable({
  providedIn: 'root',
})
export class CommunityController extends EntityController<CommunityDto, SparseCommunityDto> {
  constructor(protected readonly http: HttpClient) {
    super(http, '/communities');
  }

  public getMembers(communityId: UUID): Observable<SparseUserDto[]> {
    return this.http.get<PrivateUserDto[]>(this.getUrl(`/${communityId}/members`));
  }

  public getInviteLinks(communityId: UUID): Observable<InviteLinkDto[]> {
    return this.http.get<InviteLinkDto[]>(this.getUrl(`/${communityId}/invite-links`));
  }

  public verifyInviteLink(code: string): Observable<InviteLinkVerificationResponse> {
    return this.http.get<InviteLinkVerificationResponse>(this.getUrl(`/invite-links/${code}/verify`));
  }

  public joinCommunity(communityId: UUID, code: string): Observable<any> {
    return this.http.post<any>(this.getUrl(`/${communityId}/join?code=${code}`), null).pipe(
      catchError((err, caught) => {
        return of(err);
      })
    );
  }
}
