import { DeltaType } from '../../../../util/delta-type.annotation';
import { Delta } from '../delta';
import { FlyerTypeDto } from '../../../../dto/flyer-type.dto';
import { DeleteFlyerTypeDelta } from './delete-flyer-type-delta';
import { FromIndex } from '../../../../model/relink/managed-entity';
import { JsonIgnore } from '../../../../util/json';
import { GrafitiProject } from '../../../../model/entity/grafiti-project';
import { GrafitiFlyerType } from '../../../../model/entity/grafiti-flyer-type';

@DeltaType('createFlyerType')
export class CreateFlyerTypeDelta extends Delta {
  @JsonIgnore()
  @FromIndex<CreateFlyerTypeDelta>((self) => self.flyerTypeDto.projectId)
  private project: GrafitiProject;

  public flyerTypeDto: FlyerTypeDto;

  constructor(typeDto: FlyerTypeDto) {
    super();
    this.flyerTypeDto = this.flyerTypeDto ?? typeDto;
  }

  public apply(): void {
    const flyerType = new GrafitiFlyerType(this.flyerTypeDto, this.project);
    this.project.setFlyerTypes([...this.project.getFlyerTypes(), flyerType]);
  }

  public onConfirmation(confirmationDelta: CreateFlyerTypeDelta) {}

  public override getUndoDelta(): Delta {
    return new DeleteFlyerTypeDelta(this.flyerTypeDto);
  }

  public override getErrorMessage(): string {
    return `Neuer Flyertyp ${this.flyerTypeDto.motive} konnte nicht gespeichert werden!`;
  }
}
