export function comparingNumber<T>(fn: (e: T) => number): (e1: T, e2: T) => number {
  return (e1, e2) => fn(e1) - fn(e2);
}

export function comparingString<T>(fn: (e: T) => string): (e1: T, e2: T) => number {
  return (e1, e2) => fn(e1).localeCompare(fn(e2));
}

export function comparingStrNum<T>(fn: (e: T) => string | number): (e1: T, e2: T) => number {
  return (e1, e2) => {
    const v1 = fn(e1);
    const v2 = fn(e2);
    if (typeof v1 === 'number' && typeof v2 === 'number') {
      return v1 - v2;
    } else if (typeof v1 === 'string' && typeof v2 === 'string') {
      return v1.localeCompare(v2);
    } else {
      return (v1 + '').localeCompare(v2 + '');
    }
  };
}
