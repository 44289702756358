import { Injectable, NgZone } from '@angular/core';
import { AuthenticationService } from '../auth/authentication.service';
import { ReplaySubject } from 'rxjs';
import { AuthenticationError } from '../util/authentication-error';

@Injectable({
  providedIn: 'root',
})
export class TelegramLoginService {
  private readonly _result$ = new ReplaySubject<AuthenticationError>();
  public readonly result$ = this._result$.asObservable();
  public constructor(private readonly ngZone: NgZone, private readonly authService: AuthenticationService) {}
  init() {
    window['loginViaTelegram'] = (loginData: TelegramLoginData) => this.loginViaTelegram(loginData);
  }

  private loginViaTelegram(loginData: TelegramLoginData) {
    // If the login should trigger view changes, run it within the NgZone.
    this.ngZone.run(() => this.process(loginData));
  }

  private process(loginData: TelegramLoginData) {
    this.authService.telegramLogin(loginData).subscribe({
      next: async () => {
        setTimeout(() => {
          this._result$.next(null);
        }, 100);
      },
      error: (error: AuthenticationError) => {
        this._result$.next(error);
      },
    });
  }
}

export interface TelegramLoginData {
  id: number;
  auth_date: number;
  first_name: string;
  last_name: string;
  username: string;
  photo_url: string;
  hash: string;
}
