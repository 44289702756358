import { Observable } from 'rxjs';
import { LanguageService } from './language.service';

/**
 * A factory which ensures that all translations are loaded upon Angular initialization.
 */
export function appInitializerFactory(toolLanguageService: LanguageService): () => Observable<unknown> {
  return (): Observable<void> => {
    return toolLanguageService.init();
  };
}
