import { Component, HostListener } from '@angular/core';
import { SidebarService } from '../../sidebar.service';
import { map } from 'rxjs/operators';
import { BehaviorSubject, combineLatest } from 'rxjs';

@Component({
  selector: 'gr-layout',
  styleUrls: ['gr-layout.component.scss'],
  templateUrl: 'gr-layout.component.html',
})
export class GrLayoutComponent {
  protected readonly leftOpen$ = this.sidebarService.left$;
  protected readonly width$ = new BehaviorSubject<number>(window.innerWidth);
  protected readonly contentOpen$ = combineLatest([this.leftOpen$, this.width$]).pipe(
    map(([leftOpen, width]) => {
      if (leftOpen) {
        return width > 900;
      } else {
        return true;
      }
    })
  );

  public constructor(private readonly sidebarService: SidebarService) {}

  protected toggleLeft(): void {
    this.sidebarService.toggleLeft();
  }

  protected toggleRight(): void {
    this.sidebarService.notify();
  }

  @HostListener('window:resize', ['$event'])
  protected resize($event): void {
    this.width$.next(window.innerWidth);
  }
}
