import { Delta } from '../delta';
import { DeltaType } from '../../../../util/delta-type.annotation';
import { ProjectDto } from '../../../../dto/project.dto';
import { DeleteProjectDelta } from './delete-project-delta';
import { JsonIgnore } from '../../../../util/json';
import { FromIndex, ManagedEntity } from '../../../../model/relink/managed-entity';
import { GrafitiCommunity } from '../../../../model/entity/grafiti-community';
import { GrafitiProject } from '../../../../model/entity/grafiti-project';
import { SparseProjectDto } from '../../../../dto/sparse-project.dto';
import { StaticInject, StaticInjector } from '../../../../util/lifecycle/static-injector';
import { ProjectLoaderService } from '../../../../model/project-loader.service';

@DeltaType('createProject')
export class CreateProjectDelta extends Delta {
  @JsonIgnore()
  @FromIndex<CreateProjectDelta>((self) => self.dto.communityId)
  private community: GrafitiCommunity;

  @JsonIgnore()
  @StaticInject(ProjectLoaderService)
  private projectLoader: ProjectLoaderService;

  @JsonIgnore()
  private project: GrafitiProject;

  public dto: SparseProjectDto;

  public constructor(dto: SparseProjectDto) {
    super();

    this.dto = this.dto ?? dto;
  }

  public apply(): void {
    const existing = ManagedEntity.findFromIndex<GrafitiProject>(this.dto.id);
    if (existing) {
      this.project = existing;
      return;
    }
    this.project = new GrafitiProject(this.dto, this.community);
    this.projectLoader.setLoadedProject(this.project);
    this.community.setProjects([...this.community.getProjects(), this.project]);
  }

  public onConfirmation(confirmationDelta: CreateProjectDelta): void {
    this.project.updateRoleAssignments(confirmationDelta.dto.roleAssignments);
  }

  public override getUndoDelta(): Delta {
    return new DeleteProjectDelta(this.dto.id);
  }

  public override getErrorMessage(): string {
    return `Neues Projekt '${this.dto.name}' konnte nicht gespeichert werden!`;
  }
}
