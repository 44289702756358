import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from '../../storage.service';

@Injectable()
export class IsAdminGuard implements CanActivate {
  constructor(private readonly router: Router, private readonly storageService: StorageService) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.storageService.currentUser?.admin) {
      return true;
    }
    this.router.navigate(['/pages/home']).then();
    return false;
  }
}
