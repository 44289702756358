import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { IoType, KeyboardIoEvent } from './model/keyboard-io-event';
import { DeviceDetectorService } from 'ngx-device-detector';

/**
 * The threshold for when to count a key event as a repeated key event.
 * This is only needed to macOS when having the CMD key pressed.
 *
 * @see KeyboardIoService#isMacOsMetaKeyboardRepeat(KeyboardEvent)
 */
const REPEAT_THRESHOLD = 100;

/**
 * The lower bound for when to count the first repeated key event while having
 * CMD pressed as a repeated event.
 *
 * @see KeyboardIoService#isMacOsMetaKeyboardRepeat(KeyboardEvent)
 */
const FIRST_REPEAT_LOWER_BOUND = 450;

/**
 * The upper bound for when to count the first repeated key event while having
 * CMD pressed as a repeated event.
 *
 * @see KeyboardIoService#isMacOsMetaKeyboardRepeat(KeyboardEvent)
 */
const FIRST_REPEAT_UPPER_BOUND = 550;

/**
 * Listens for keyboard input and creates {@link KeyboardIoEvent}s.
 *
 * Can be used to subscribe to key up and key down events to handle them as needed.
 */
@Injectable()
export class KeyboardIoService {
  private readonly _keyUp$: Subject<KeyboardIoEvent>;
  private readonly _keyDown$: Subject<KeyboardIoEvent>;

  // This is only used to recognize the auto-repeat of the keydown event (i.e. the keydown event firing multiple times when holding a key).
  // It should not be used for anything else!
  private readonly keysPressed: Map<string, number>;

  /**
   * Used to catch the first repeating CMD keyboard event on macOS.
   * @see isMacOsMetaKeyboardRepeat
   */
  private isFirstRepeat: boolean;

  public constructor(private readonly deviceDetector: DeviceDetectorService) {
    this._keyUp$ = new Subject<KeyboardIoEvent>();
    this._keyDown$ = new Subject<KeyboardIoEvent>();

    this.keysPressed = new Map<string, number>();

    this.startListeningToKeyboard();
  }

  public get keyUp$(): Observable<KeyboardIoEvent> {
    return this._keyUp$.asObservable();
  }

  public get keyDown$(): Observable<KeyboardIoEvent> {
    return this._keyDown$.asObservable();
  }

  private startListeningToKeyboard(): void {
    document.addEventListener('keyup', (event: KeyboardEvent) => {
      this.onKeyUpEvent(this.fixKeyEvent(event));
    });

    document.addEventListener('keydown', (event: KeyboardEvent) => {
      this.onKeyDownEvent(this.fixKeyEvent(event));
    });
  }

  private onKeyDownEvent(event: KeyboardEvent): void {
    if (event?.key == null) {
      return;
    }

    // Mark auto-repeated keydown events. See KeyboardIoEvent.repeat documentation.
    let repeat: boolean;
    if (!this.keysPressed.has(event.key)) {
      this.keysPressed.set(event.key, event.timeStamp);
      repeat = false;
      this.isFirstRepeat = true;
    } else if (this.isMacOs() && event.metaKey) {
      repeat = this.isMacOsMetaKeyboardRepeat(event);
      this.isFirstRepeat = !repeat;
    } else {
      repeat = true;
      this.isFirstRepeat = false;
    }

    const keyboardEvent: KeyboardIoEvent = new KeyboardIoEvent(IoType.KEY_DOWN, event, repeat);
    this._keyDown$.next(keyboardEvent);
  }

  private onKeyUpEvent(event: KeyboardEvent): void {
    if (event?.key == null) {
      return;
    }

    const keyboardEvent: KeyboardIoEvent = new KeyboardIoEvent(IoType.KEY_UP, event);
    this.keysPressed.delete(event.key);
    this._keyUp$.next(keyboardEvent);
  }

  /**
   * Checks if a keyboard event with meta key pressed is a repeated keyboard event.
   * This is a workaround for macOS only!
   *
   * On macOS when having the meta key pressed, no more key-up events will be raised, which
   * causes the repeating mechanism to stop functioning and keyboard events being spammed.
   * One resulting behavior is that when holding CMD+V, copied elements will be pasted every
   * 80 ms, which is a lot. On Windows, these repeated events will simply not be handled.
   *
   * To work around this issue, we check if the timestamps of the keyboard events are within
   * certain thresholds - and yes the plural is intentional. There is one threshold for the first
   * repeated event, which is between 450-550 ms after the initial key-down event, and then
   * every subsequent keyboard event is being raised within a 70-90 ms after the previous.
   *
   * So to work around this we need to check for both thresholds and store whether we just
   * caught the first repeat or any subsequent repeat after that.
   *
   * @param event The keyboard event being raised by the OS.
   * @see https://stackoverflow.com/a/27512489
   */
  private isMacOsMetaKeyboardRepeat(event: KeyboardEvent): boolean {
    const timeStamp = this.keysPressed.get(event.key);
    const diff = event.timeStamp - timeStamp;
    this.keysPressed.set(event.key, event.timeStamp);

    if (this.isFirstRepeat && FIRST_REPEAT_LOWER_BOUND < diff && diff < FIRST_REPEAT_UPPER_BOUND) {
      return true;
    }

    return !this.isFirstRepeat && diff < REPEAT_THRESHOLD;
  }

  /**
   * Fixes the Key Event so that every LETTER ist returned correctly, even if modifiers are pressed.
   * For example, without this method, CTRL+ALT+E would result in event.key = '€' instead of 'E' for the last press.
   *
   * This is a workaround for web shortcuts and relies on deprecated properties, I would not really call it common or good.
   * It works for now, but we should seek for alternative ways to build our shortcuts.
   */
  private fixKeyEvent(event: KeyboardEvent): KeyboardEvent {
    if (event.code === 'AltLeft') {
      event.preventDefault(); // Otherwise the browsers default action for alt is used
    }

    const keyCode = event.keyCode ?? event.which;
    // Range A..Z
    if (keyCode >= 65 && keyCode <= 90) {
      const newEvent = new KeyboardEvent(event.type, {
        key: String.fromCharCode(keyCode).toLowerCase(),
        shiftKey: event.shiftKey,
        altKey: event.altKey,
        metaKey: event.metaKey,
        ctrlKey: event.ctrlKey,
      });
      newEvent.stopPropagation = event.stopPropagation.bind(event);
      newEvent.stopImmediatePropagation = event.stopImmediatePropagation.bind(event);
      newEvent.preventDefault = event.preventDefault.bind(event);
      return newEvent;
    }
    return event;
  }

  private isMacOs(): boolean {
    console.log(this.deviceDetector.os);
    return false;
  }
}
