import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faExclamationTriangle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { interval, Observable } from 'rxjs';
import { map, startWith, take } from 'rxjs/operators';

export interface ConfirmationDialogData {
  title: string;
  text: string;
  /**
   * Translate Parameters applied to ALL passed texts (title, text, okText, abortText)
   */
  translateParams?: { [key: string]: unknown };
  minWaitTime?: number;
  okText?: string;
  abortText?: string;
  okAndMuteText?: string;
}

@Component({
  selector: 'grafiti-confirmation-dialog',
  templateUrl: 'confirmation-dialog.component.html',
  styleUrls: ['confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {
  /**
   * Fontawesome icons
   */
  public readonly faExclamationTriangle = faExclamationTriangle;
  public readonly faTimes = faTimes;

  public title: string;
  public text: string;
  public okText: string;
  public abortText: string;
  public translateParams: { [key: string]: unknown };

  public buttonTimerText: Observable<number>;
  public buttonDisabled = true;

  public constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData) {
    this.title = data.title;
    this.text = data.text;
    this.translateParams = data.translateParams;
    this.okText = data.okText ?? 'Ok';
    this.abortText = data.abortText ?? 'Abbrechen';
    if (this.data.minWaitTime) {
      this.buttonTimerText = interval(1000).pipe(
        startWith([void 0]),
        take(this.data.minWaitTime + 1),
        map((_, i) => this.data.minWaitTime - i)
      );
      this.buttonTimerText.subscribe(
        () => {},
        () => {},
        () => (this.buttonDisabled = false)
      );
    } else {
      this.buttonDisabled = false;
    }
  }
}
