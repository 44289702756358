import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { IdFunction, IObservableSet } from './iobservableset';

/**
 * An ObservableSet is a Data Structure used for storing unique items of a specific type and efficiently notifying listeners of changes in
 * the Set. Every ObservableSet needs an idFunction, providing a way to build a unique ID from a given item. This can be a hash function,
 * object identity or just a property getter returning an existing unique ID in the item.
 */
export class ObservableSet<V, K = never> extends BehaviorSubject<
  ObservableSet<V, K>
> /*implements IObservableSet<V, K> */ {
  public readonly array$: Observable<V[]>;

  private data = new Map<K, V>();
  private readonly id: (data: V) => K;
  private readonly _changed$ = new ReplaySubject<void>();

  /**
   * Creates a new ObservableSet.
   * An Observable set is a Collection of unique objects which are identified by a unique ID.
   * The given idFunction matches an item to its unique ID.
   */
  public constructor(idFunction: IdFunction<V, K> = (data: V): K => data as unknown as K) {
    super(null);
    if (idFunction instanceof Function) {
      this.id = idFunction;
    } else {
      this.id = (data): K => data[idFunction] as unknown as K;
    }
    const nextFn = this.next.bind(this);
    this.next = (): void => {
      throw new Error('Cannot call next on ObservableSet');
    };
    this._changed$.subscribe(() => nextFn(this));
    this.array$ = this.pipe(
      map(() => this.array()),
      shareReplay(1)
    );
    this._changed$.next();
  }

  /**
   * Returns the number of items contained in this Set.
   */
  get size(): number {
    return this.data?.size ?? 0;
  }

  /**
   * Creates a new ObservableSet from the given Iterable.
   * The specified idFunction is a function returning a unique ID of an item.
   */
  public static from<T, H>(it: Iterable<T>, idFunction: IdFunction<T, H>): ObservableSet<T, H> {
    const set = new ObservableSet<T, H>(idFunction);
    set.addAll(it);
    return set;
  }

  /**
   * Adds the given item to this set, if not already contained.
   * Simply the short form of addAll([item]).
   */
  public add(item: V): this {
    return this.addAll([item]);
  }

  /**
   * Stores a new item under an already existing ID, for example after the original item changed.
   * This will also notify listeners.
   */
  public update(item: V): this {
    const id = this.id(item);
    this.data.set(id, item);
    this._changed$.next();
    return this;
  }

  /**
   * Adds all the given items which are not already contained to this Set.
   */
  public addAll(values: Iterable<V>): this {
    let added = false;
    for (const val of values) {
      const id = this.id(val);
      if (!this.data.has(id)) {
        this.data.set(id, val);
        added = true;
      }
    }
    if (added) {
      this._changed$.next();
    }
    return this;
  }

  public replace(values: Iterable<V>): this {
    this.data.clear();
    for (const val of values) {
      const id = this.id(val);
      if (!this.data.has(id)) {
        this.data.set(id, val);
      }
    }
    return this;
  }

  /**
   * Removes all items from this Set.
   */
  public clear(): void {
    this.data.clear();
    this._changed$.next();
  }

  /**
   * Removes the given item from this set.
   * If the ID of the given item exists, removes this. Otherwise, a full search is done to delete the correct value.
   */
  public remove(value: V): V | null {
    return this.delete(this.id(value)) || this.delete(this.findId((el) => el === value));
  }

  /**
   * Deletes the item specified by the given ID from this Set.
   */
  public delete(id: K): V | null {
    const data = this.data.get(id);
    if (data) {
      this.data.delete(id);
      this._changed$.next();
      return data;
    }
    return null;
  }

  /**
   * Applies a function to every item in this Set.
   * This does NOT change the Set itself.
   */
  public each(callback: (value: V, key: K, set: Map<K, V>) => void): void {
    this.data.forEach(callback);
  }

  /**
   * Maps any item in this array with the given mapper function.
   */
  /*public map<U>(mapper: (value: V) => U): Array<U> {
    return Array.from(this.values(), mapper);
  }*/

  /** Filter values satisfying the given predicate. */
  public filter(predicate: (value: V, index: number, array: V[]) => boolean): Array<V> {
    return [...this.values()].filter(predicate);
  }

  /**
   * Simplification of piping and mapping this Set at the same time.
   * This is short for `set.pipe(map(items => items.map(mapper)))`.
   */
  /*public pipeMap<U>(mapper: (value: V) => U): Observable<Array<U>> {
    return this.pipe(map((_) => this.map(mapper)));
  }*/

  /**
   * Returns true if this Set contains an item with the given ID.
   */
  public has(id: K): boolean {
    return this.data.has(id);
  }

  /**
   * Returns true if this Set contains the given Element or any element with the same ID.
   * Use {@link containsExact} to only match the same object reference.
   */
  contains(el: V): boolean {
    return this.has(this.id(el));
  }

  /**
   * Returns true if this Set contains the given Element by object equality.
   * Prefer using {@link contains} or {@link has} wherever applicable.
   */
  containsExact(searchEl: V): boolean {
    return this.find((el) => el === searchEl) != null;
  }

  /**
   * Queries this Set by a given ID. This is a very fast way of retrieving a specific item.
   * @param id
   */
  public get(id: K): V | undefined {
    return this.data.get(id);
  }

  /**
   * Queries this Set by a given ID, and runs an action if the item exists
   * This is a very fast way of retrieving a specific item
   * @param id
   * @param action
   */
  public getAnd(id: K, action: (e: V) => void): V | undefined {
    const item = this.get(id);
    if (item) {
      action(item);
    }
    return item;
  }

  get$(id: K): Observable<V | undefined> {
    return this._changed$.pipe(
      map(() => this.data.get(id)),
      distinctUntilChanged()
    );
  }

  find<U extends V>(finder: (data: V) => data is U): U;
  find(finder: (data: V) => boolean): V;
  /**
   * Tries to find an item matching a given condition. This function will stop when any item is found and return the found item.
   * If the provided finder function is a type guard, the result of this function will automatically be narrowed to the guarded type.
   */
  find(finder: (data: V) => boolean): V {
    for (const item of this.values()) {
      if (finder(item)) {
        return item;
      }
    }
    return null;
  }

  /**
   * Returns an array containing this Sets items. Wherever applicable, prefer using {@link values}.
   */
  array(): V[] {
    return [...this.values()];
  }

  /** Return whether the set is empty. */
  isEmpty(): boolean {
    return this.size === 0;
  }

  /**
   * Returns an Iterator of the Items in this Set.
   */
  values(): IterableIterator<V> {
    return this.data.values();
  }

  /**
   * Destroys this ObservableSet and frees all references to the data.
   */
  public destroy(): void {
    this.complete();
    this._changed$.complete();
    this.data.clear();
    delete this.data;
  }

  /**
   * You should probably not use this.
   * Basically calls next.
   */
  public emit(): void {
    this._changed$.next();
  }

  /**
   * Used to find the ID of an item after it has changed.
   */
  private findId(matcher: (el: V) => boolean): K {
    for (const entry of this.data.entries()) {
      if (matcher(entry[1])) {
        return entry[0];
      }
    }
    return null;
  }
}
