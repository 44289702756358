import { ProjectEntity } from './project-entity';
import { GrafitiProject } from './grafiti-project';
import { combineLatest, Observable } from 'rxjs';
import { GrafitiFlyerType } from './grafiti-flyer-type';
import { FlyerRunDto } from '../../dto/flyer-run.dto';
import { UUID } from '../../util/math/uuid';
import { map, shareReplay, takeUntil } from 'rxjs/operators';
import { GrafitiEntity } from './grafiti-entity';
import { ValueSubject } from '../../util/reactive/value-subject';
import { SimpleCoords } from '../../dto/simple-coords';
import { Searchable } from './searchable';
import { Feature, lineString, LineString, multiLineString, MultiLineString } from '@turf/turf';
import { forContentDefault, GrafitiPermission, AccessManaged } from '../../dto/permission';
import { Sortable } from './sortable';
import { addUnits } from '../../util/distance-util';
import { GrafitiRoleAssignmentHolder } from './grafiti-role-assignment-holder';
import { LeafletStyle } from '../../util/types/spatial-style';

export class GrafitiFlyerRun
  extends GrafitiRoleAssignmentHolder
  implements ProjectEntity, Searchable, AccessManaged, Sortable
{
  private readonly project$: ValueSubject<GrafitiProject>;
  private readonly typeId$: ValueSubject<UUID>;
  private readonly type$: Observable<GrafitiFlyerType>;
  private readonly amount$: ValueSubject<number>;
  private readonly distance$: ValueSubject<number>;
  private readonly distanceStr$: Observable<string>;
  private readonly startDate$: ValueSubject<Date>;
  private readonly endDate$: ValueSubject<Date>;
  private readonly description$: ValueSubject<string>;
  private readonly runnerId$: ValueSubject<UUID>;
  private readonly uploaderId$: ValueSubject<UUID>;

  private readonly flyersPerHour$: Observable<number>;
  private readonly flyersPerKm$: Observable<number>;

  public constructor(dto: FlyerRunDto, project: GrafitiProject) {
    super(dto);

    this.project$ = new ValueSubject<GrafitiProject>(project, this.destroy$);
    this.typeId$ = new ValueSubject<UUID>(dto.flyerTypeId, this.destroy$);
    this.type$ = this.typeId$.pipe(
      map((typeId) => {
        return this.getProject()
          .getFlyerTypes()
          .find((type) => type.getId() === typeId);
      }),
      shareReplay(1)
    );
    this.setArea(dto.area);
    this.amount$ = new ValueSubject<number>(dto.amount, this.destroy$);
    this.distance$ = new ValueSubject<number>(dto.distance, this.destroy$);
    this.distanceStr$ = this.distance$.pipe(map((d) => addUnits(d)));
    this.startDate$ = new ValueSubject<Date>(new Date(dto.startDate), this.destroy$);
    this.endDate$ = new ValueSubject<Date>(new Date(dto.endDate), this.destroy$);
    this.runnerId$ = new ValueSubject<UUID>(dto.runnerId);
    this.uploaderId$ = new ValueSubject<UUID>(dto.uploaderId);
    this.description$ = new ValueSubject<string>(dto.description);

    this.flyersPerHour$ = combineLatest([this.amount$, this.startDate$, this.endDate$]).pipe(
      takeUntil(this.destroy$),
      map(([amount, start, end]) => {
        const durationInMs = end.getTime() - start.getTime();
        const durationInHours = durationInMs / 1000 / 60 / 60;

        return amount / durationInHours;
      }),
      shareReplay(1)
    );

    this.flyersPerKm$ = combineLatest([this.amount$, this.distance$]).pipe(
      takeUntil(this.destroy$),
      map(([amount, distance]) => {
        return amount / distance;
      }),
      shareReplay(1)
    );
    this.ready();
  }

  public getProject(): GrafitiProject {
    return this.project$.getValue();
  }

  public getType(): GrafitiFlyerType {
    const typeId = this.typeId$.getValue();
    return this.getProject()
      .getFlyerTypes()
      .find((type) => type.getId() === typeId);
  }

  public getType$(): Observable<GrafitiFlyerType> {
    return this.type$;
  }

  public setType(type: GrafitiFlyerType): void {
    this.typeId$.next(type.getId());
  }

  public getBaseStyle(): LeafletStyle {
    return this.getType()?.getStyle() ?? {};
  }

  public setArea(boundaries: SimpleCoords[][]): void {
    this.setEmbeddedAttribute('area', boundaries);
  }

  public getArea(): SimpleCoords[][] {
    return this.getEmbeddedAttribute('area');
  }

  public getArea$(): Observable<SimpleCoords[][]> {
    return this.getEmbeddedAttribute$('area');
  }

  public getStartDate(): Date {
    return this.startDate$.getValue();
  }

  public setStartDate(date: Date): void {
    this.startDate$.next(date);
  }

  public getEndDate(): Date {
    return this.endDate$.getValue();
  }

  public setEndDate(date: Date): void {
    this.endDate$.next(date);
  }

  public getDistance(): number {
    return this.distance$.getValue();
  }

  public setDistance(d: number): void {
    this.distance$.next(d);
  }

  public getDistance$(): Observable<number> {
    return this.distance$;
  }

  public getDistanceStr$(): Observable<string> {
    return this.distanceStr$;
  }

  public getAmount(): number {
    return this.amount$.getValue();
  }

  public getAmount$(): Observable<number> {
    return this.amount$;
  }

  public setAmount(amount: number): void {
    this.amount$.next(amount);
  }

  public setDescription(description: string): void {
    this.description$.next(description);
  }

  public getDescription(): string {
    return this.description$.getValue();
  }

  public getDescription$(): Observable<string> {
    return this.description$;
  }

  public getRunnerId(): UUID {
    return this.runnerId$.getValue();
  }

  public setRunnerId(rid: UUID): void {
    this.runnerId$.next(rid);
  }

  public getUploaderId(): UUID {
    return this.runnerId$.getValue();
  }

  public getStartDate$(): Observable<Date> {
    return this.startDate$;
  }

  public getEndDate$(): Observable<Date> {
    return this.endDate$;
  }

  public getFlyersPerHour$(): Observable<number> {
    return this.flyersPerHour$;
  }

  public getFlyersPerHour(): number {
    const durationInMs = this.getEndDate().getTime() - this.getStartDate().getTime();
    const durationInHours = durationInMs / 1000 / 60 / 60;

    return this.getAmount() / durationInHours;
  }

  public getFlyersPerKm(): number {
    return this.getAmount() / this.getDistance();
  }

  public getFlyersPerKm$(): Observable<number> {
    return this.flyersPerKm$;
  }

  public get description(): string {
    return this.getDescription();
  }

  public get amount(): number {
    return this.getAmount();
  }

  public set description(v: string) {
    this.setDescription(v);
  }

  public set amount(v: number) {
    this.setAmount(v);
  }

  public override buildDto(): FlyerRunDto {
    return {
      ...super.buildDto(),
      projectId: this.getProject().getId(),
      area: this.getArea(),
      flyerTypeId: this.getType().getId(),
      startDate: this.getStartDate().toISOString(),
      endDate: this.getEndDate().toISOString(),
      distance: this.getDistance(),
      amount: this.getAmount(),
      runnerId: this.getRunnerId(),
      uploaderId: this.getUploaderId(),
      description: this.getDescription(),
    };
  }
  matchesQueryFilters(queries: string): boolean {
    return false;
  }
  matchesTextFilter(filter: string): boolean {
    return this.getEntireSearchString().includes(filter);
  }

  private getEntireSearchString(): string {
    return [this.getType().getMotive(), this.getDescription()].join(',').toLowerCase();
  }

  public getTurfLine(): Feature<MultiLineString> {
    return multiLineString(this.getArea().map((e) => e.map((a) => [a.lng, a.lat])));
  }

  public hasPermission(permission: GrafitiPermission): boolean {
    return this.anyRoleProvidesPermission(permission) || this.getProject().hasPermission(forContentDefault(permission));
  }

  public hasPermission$(permission: GrafitiPermission): Observable<boolean> {
    return combineLatest([
      this.anyRoleProvidesPermission$(permission),
      this.getProject().hasPermission$(forContentDefault(permission)),
    ]).pipe(map(([myRes, projectRes]) => myRes || projectRes));
  }

  public getSortString(column: string): string | number {
    switch (column) {
      case 'description':
        return this.getDescription();
      case 'type':
        return this.getType().getMotive();
      case 'startDate':
        return this.getStartDate().toISOString();
      case 'startTime':
        return this.getStartDate().toISOString();
      case 'endTime':
        return this.getEndDate().toISOString();
      case 'distance':
        return this.getDistance().toString();

      case 'amount':
        return this.getAmount();
      case 'amountPerTime':
        return this.getFlyersPerHour();
      case 'amountPerDistance':
        return this.getFlyersPerKm();
      default:
        return '';
    }
  }

  protected destructor(): void {}
}
