<div id="toolbar" class="tw-flex tw-justify-between">
  <div class="left-container">
    <span *ngIf="showToggle" (click)="toggleLeftPanel.emit()">
      <button mat-icon-button>
        <mat-icon [class.rotated]="leftOpen$ | async">menu</mat-icon>
      </button>

      <img id="header-sidebar-toggle" src="assets/images/grafiti-logo.png" />
    </span>

    <gr-breadcrumbs></gr-breadcrumbs>
  </div>

  <div>
    <button mat-icon-button>
      <mat-icon>search</mat-icon>
    </button>

    <!--<button mat-icon-button class="hide-small" (click)="toggleRightPanel.emit()">
      <mat-icon>list</mat-icon>
    </button>-->
    <!--<button mat-icon-button matTooltip="Ausloggen" routerLink="/auth/logout">
      <fa-icon [icon]="faRightFromBracket"> </fa-icon>
    </button>-->
  </div>
</div>
<mat-progress-bar *ngIf="loading$ | async" [mode]="mode$ | async" [value]="progress$ | async"></mat-progress-bar>
