export interface ModifierKeys {
  ctrl?: boolean;
  shift?: boolean;
  alt?: boolean;
  cmd?: boolean;
}

/**
 * Models a key combination of one key + eventual modifier keys (Control, Shift, Alt, etc.).
 */
export class KeyCombination {
  constructor(public readonly key: string, public readonly modifierKeys: ModifierKeys = {}) {
    if (key == null) {
      throw new Error('key combinations must not have null key');
    }
  }

  static fromEvent(keyboardEvent: KeyboardEvent): KeyCombination {
    if (keyboardEvent?.key == null) {
      return null;
    }

    const modifierKeys: ModifierKeys = {
      ctrl: keyboardEvent.ctrlKey,
      shift: keyboardEvent.shiftKey,
      alt: keyboardEvent.altKey,
      cmd: keyboardEvent.metaKey,
    };

    return new KeyCombination(keyboardEvent.key, modifierKeys);
  }

  equals(otherKeyCombination: KeyCombination): boolean {
    if (otherKeyCombination == null) {
      return false;
    }

    if (this.key.toUpperCase() !== otherKeyCombination.key.toUpperCase()) {
      return false;
    }

    const otherModifierKeys: ModifierKeys = otherKeyCombination.modifierKeys;
    return (
      (this.modifierKeys?.ctrl ?? false) === (otherModifierKeys?.ctrl ?? false) &&
      (this.modifierKeys?.shift ?? false) === (otherModifierKeys?.shift ?? false) &&
      (this.modifierKeys?.alt ?? false) === (otherModifierKeys?.alt ?? false) &&
      (this.modifierKeys?.cmd ?? false) === (otherModifierKeys?.cmd ?? false)
    );
  }
}
