import { Delta } from '../delta';
import { DeltaType } from '../../../../util/delta-type.annotation';
import { UUID } from '../../../../util/math/uuid';
import { CreateProjectDelta } from './create-project-delta';
import { ProjectDto } from '../../../../dto/project.dto';
import { JsonIgnore } from '../../../../util/json';
import { FromIndex } from '../../../../model/relink/managed-entity';
import { GrafitiProject } from '../../../../model/entity/grafiti-project';
import { StaticInject } from '../../../../util/lifecycle/static-injector';
import { ProjectLoaderService } from '../../../../model/project-loader.service';

@DeltaType('deleteProject')
export class DeleteProjectDelta extends Delta {
  @JsonIgnore()
  private projectDto: ProjectDto;

  @JsonIgnore()
  @FromIndex<DeleteProjectDelta>((self) => self.projectId)
  public project: GrafitiProject;

  @JsonIgnore()
  @StaticInject(ProjectLoaderService)
  public projectLoader: ProjectLoaderService;

  public projectId: UUID;

  public constructor(projectId: UUID) {
    super();
    this.projectId = this.projectId ?? projectId;
  }

  public apply(): void {
    const toDelete = this.project;
    this.projectDto = toDelete.buildDto();
    const community = toDelete.getCommunity();
    community.setProjects(community.getProjects().filter((p) => p.getId() !== toDelete.getId()));
    this.projectLoader.remove(this.projectId);
    toDelete.destroy();
  }

  public onConfirmation(confirmationDelta: DeleteProjectDelta): void {}

  public override getUndoDelta(): Delta {
    return new CreateProjectDelta(this.projectDto);
  }

  public override getErrorMessage(): string {
    return `Kampagne '${this.projectDto.name}' konnte nicht gelöscht werden!`;
  }
}
