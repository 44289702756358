<div>
  <h2>
    <fa-icon [icon]="faExclamationTriangle"></fa-icon>
    {{ title }}
    <span class="spacer"></span>
    <button class="close" mat-icon-button [matDialogClose]="false"><fa-icon [icon]="faTimes"></fa-icon></button>
  </h2>
  <mat-dialog-content>
    <p style="display: inline">{{ text }}</p>
  </mat-dialog-content>
  <mat-dialog-actions class="gr-actions">
    <button mat-raised-button class="okay-button" [matDialogClose]="true" [disabled]="buttonDisabled">
      {{ okText }}
      <ng-container *ngIf="buttonDisabled"> ({{ buttonTimerText | async }})</ng-container>
    </button>
    <button mat-raised-button color="accent" cdkFocusInitial [matDialogClose]="false" class="abort-button">
      {{ abortText }}
    </button>
  </mat-dialog-actions>
</div>
