import { EditPropertyDelta } from '../edit-property.delta';
import { invertDiff, invertDiffs, PropertyDiff } from '../../property-diff';
import { UUID } from '../../../../util/math/uuid';
import { GrafitiProject } from '../../../../model/entity/grafiti-project';
import { DeltaType } from '../../../../util/delta-type.annotation';
import { JsonIgnore } from '../../../../util/json';
import { StaticInject } from '../../../../util/lifecycle/static-injector';
import { Userspace } from '../../../../model/userspace.service';
import { FromIndex } from '../../../../model/relink/managed-entity';

@DeltaType('editProject')
export class EditProjectDelta extends EditPropertyDelta<keyof GrafitiProject> {
  @JsonIgnore()
  @StaticInject(Userspace)
  private readonly userspace: Userspace;

  @JsonIgnore()
  @FromIndex<EditProjectDelta>((self) => self.entityId)
  private project: GrafitiProject;

  public constructor(projectId: UUID, diffs: PropertyDiff<keyof GrafitiProject>[]) {
    super();
    this.entityId = this.entityId ?? projectId;
    this.diff = this.diff ?? diffs;
  }

  public apply(): void {
    if (!this.project) {
      return;
    }
    this.diff.forEach((diff) => {
      if (diff.name === 'name') {
        this.project.setName(diff.newValue as string);
      }
      if (diff.name === 'description') {
        this.project.setDescription(diff.newValue as string);
      }
      if (diff.name === 'startDate') {
        this.project.setStartDate(diff.newValue as number);
      }
      if (diff.name === 'endDate') {
        this.project.setEndDate(diff.newValue as number);
      }
    });
  }

  public onConfirmation(confirmationDelta: EditProjectDelta): void {}

  public getUndoDelta(): EditProjectDelta {
    return new EditProjectDelta(this.entityId, invertDiffs(this.diff));
  }

  public override getErrorMessage(): string {
    return `Projekt konnte nicht bearbeitet werden`;
  }
}
