import { Component } from '@angular/core';

@Component({
  selector: 'gr-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <div id="infobar">
      <span class="created-by">
        Created with ♥ by <b><a href="https://patriotec.de" target="_blank">Patriotec</a></b> 2023
      </span>
      <!--<div class="socials">
        <a href="#" target="_blank" class="ion ion-social-github"></a>
        <a href="#" target="_blank" class="ion ion-social-facebook"></a>
        <a href="#" target="_blank" class="ion ion-social-twitter"></a>
        <a href="#" target="_blank" class="ion ion-social-linkedin"></a>
      </div>-->
    </div>
  `,
})
export class FooterComponent {}
