import { Delta } from '../delta';
import { DeltaType } from '../../../../util/delta-type.annotation';
import { UUID } from '../../../../util/math/uuid';
import { JsonIgnore } from '../../../../util/json';
import { FromIndex } from '../../../../model/relink/managed-entity';
import { GrafitiFlyerRun } from '../../../../model/entity/grafiti-flyer-run';
import { EditPropertyDelta } from '../edit-property.delta';
import { PropertyDiff } from '../../property-diff';

@DeltaType('editFlyerRunProperties')
export class EditFlyerRunPropertiesDelta extends EditPropertyDelta {
  @JsonIgnore()
  @FromIndex<EditFlyerRunPropertiesDelta>((self) => self.entityId)
  private flyerRun: GrafitiFlyerRun;

  public constructor(id: UUID, diffs: PropertyDiff<keyof GrafitiFlyerRun>[]) {
    super();
    this.entityId = id;
    this.diff = diffs;
  }
  public override apply(): void {
    if (!this.flyerRun) {
      return;
    }

    this.forEachDiff(this.flyerRun);
  }

  public override getErrorMessage(): string {
    return 'Could not edit properties of flyer run ' + this.entityId;
  }

  public override onConfirmation(confirmationDelta: Delta): void {}
}
