import { Delta } from './delta';
import { DeltaType } from '../../../util/delta-type.annotation';

@DeltaType('compound')
export class CompoundDelta extends Delta {
  public deltas: Delta[] = [];

  public constructor(deltas: Delta[]) {
    super();
    this.deltas = deltas ?? this.deltas;
  }

  public override init() {
    super.init();
    this.deltas.forEach((delta) => {
      delta.init();
      delta.setCreator(this.getCreator());
      delta.setParent(this);
    });
  }

  public apply(): void {
    this.deltas.forEach((delta) => delta.apply());
  }

  public prePublish() {
    super.prePublish();
    this.deltas.forEach((delta) => delta.prePublish());
  }

  public setAppliedLocally(value: boolean) {
    super.setAppliedLocally(value);
    this.deltas.forEach((delta) => delta.setAppliedLocally(value));
  }

  public onConfirmation(confirmationDelta: CompoundDelta) {
    console.warn('It is probably not a good idea to call onConfirmation() on a compoundDelta...');
    for (let i = 0; i < this.deltas.length; i++) {
      this.deltas[i].onConfirmation(confirmationDelta.deltas[i]);
    }
  }

  public override onFailure() {
    super.onFailure();

    // in reverse because later deltas may depend on earlier ones
    [...this.deltas].reverse().forEach((delta) => {
      delta.onFailure();
    });
  }

  public override flatten(): Delta[] {
    return [this, ...this.deltas.flatMap((delta) => delta.flatten())].distinct();
  }

  public override getUndoDelta(): Delta {
    return new CompoundDelta([...this.deltas.reverse()].map((delta) => delta.getUndoDelta()));
  }

  public override getErrorMessage(): string {
    return `Konnte ${this.deltas.length} Änderungen nicht speichern!`;
  }
}
