import { Delta } from '../delta';
import { DeltaType } from '../../../../util/delta-type.annotation';
import { ManagedEntity } from '../../../../model/relink/managed-entity';
import { NoteDto } from '../../../../dto/note.dto';
import { isNoteHolder } from '../../../../model/interface/notes-holder';
import { GrafitiNote } from '../../../../model/entity/grafiti-note';
import { comparingNumber } from '../../../../util/comparator';
import { DeleteNoteDelta } from './delete-note-delta';
import { JsonIgnore } from '../../../../util/json';

@DeltaType('createNote')
export class CreateNoteDelta extends Delta {
  public noteDto: NoteDto;

  @JsonIgnore()
  private note: GrafitiNote;

  public constructor(noteDto: NoteDto) {
    super();
    this.noteDto = this.noteDto ?? noteDto;
  }

  public apply(): void {
    const holder = ManagedEntity.findFromIndex(this.noteDto.objectId);

    if (!holder) {
      return;
    }

    if (!isNoteHolder(holder)) {
      return;
    }

    this.note = new GrafitiNote(this.noteDto);
    const notes = [...holder.getNotes(), this.note].sort(comparingNumber((e) => e.getNoteIndex()));
    holder.setNotes(notes);
  }

  public onConfirmation(confirmationDelta: CreateNoteDelta): void {
    this.note.setNoteIndex(confirmationDelta.noteDto.noteIndex);
  }

  public override getUndoDelta(): Delta {
    return new DeleteNoteDelta(this.noteDto.id);
  }

  public override getErrorMessage(): string {
    return `Neuer Vermerk konnte nicht gespeichert werden!`;
  }
}
