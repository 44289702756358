import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { ValueSubject } from '../../util/reactive/value-subject';
import { filter, map, shareReplay, takeUntil } from 'rxjs/operators';
import { AttributeName } from './types';
import { JClass } from './jclass';
import { LockableSubject } from '../../util/reactive/lockable-subject';
import { UUID } from '../../util/math/uuid';
import { EntityDto } from '../../dto/entity.dto';

interface SetterOptions {
  forceEmit: boolean;
  tableEmit: boolean;
}
export abstract class ManagedEntity<I extends String = String> extends JClass<ManagedEntity> {
  private static readonly INDEX = new Map<String, ManagedEntity<String>>();
  private static readonly INDEX_UPDATED$ = new ReplaySubject<String>();

  public static findFromIndex<T extends ManagedEntity>(key: String): T {
    return ManagedEntity.INDEX.get(key) as T;
  }
  public static findFromIndex$<T extends ManagedEntity>(key: String): Observable<T> {
    return ManagedEntity.INDEX_UPDATED$.pipe(
      filter((k) => k === key),
      shareReplay(1),
      map((k) => {
        return ManagedEntity.INDEX.get(k) as T;
      })
    );
  }

  protected readonly destroy$: Subject<void> = new Subject<void>();

  private readonly id$: ValueSubject<I>;

  private readonly embeddedAttributes = new Map<AttributeName, ValueSubject<any>>();

  private _embeddedAttributeChanged$ = new LockableSubject<boolean>(false);

  private embeddedAttributeChanged$ = this._embeddedAttributeChanged$.pipe(
    takeUntil(this.destroy$),
    filter((e) => e),
    shareReplay(1)
  );

  protected constructor(id: I) {
    super();
    this.id$ = new ValueSubject<I>(id);
    const existing = ManagedEntity.INDEX.get(id);
    if (existing) {
      existing.destroy();
      console.warn(`Overriding index of ${this.getClassName()}#${this.getId()}`);
    }
  }

  /**
   * Adds the constructed element to the managed entity index.
   * @protected
   */
  protected ready(): void {
    ManagedEntity.INDEX.set(this.getId(), this);
    ManagedEntity.INDEX_UPDATED$.next(this.getId());
  }

  public update(dto: EntityDto): void {}

  public getId(): I {
    return this.id$.getValue();
  }

  public getId$(): Observable<I> {
    return this.id$;
  }

  public setId(id: I): void {
    this.id$.next(id);
  }

  protected getEmbeddedAttribute<T>(name: AttributeName, defaultValue?: T): T {
    if (!this.embeddedAttributes.has(name)) {
      return defaultValue;
    } else {
      return this.embeddedAttributes.get(name).getValue();
    }
  }

  protected getEmbeddedAttribute$<T>(name: AttributeName, defaultValue?: T): Observable<T> {
    if (!this.embeddedAttributes.has(name)) {
      const vs = new ValueSubject<T>(defaultValue, this.destroy$);
      this.embeddedAttributes.set(name, vs);
    }
    return this.embeddedAttributes.get(name).pipe(shareReplay(1));
  }

  protected setEmbeddedAttribute<T>(
    name: AttributeName,
    attribute: T,
    options: SetterOptions = { forceEmit: false, tableEmit: false }
  ): void {
    if (!this.embeddedAttributes.has(name)) {
      this.embeddedAttributes.set(name, new ValueSubject<T>(attribute, this.destroy$));
    } else {
      this.embeddedAttributes.get(name).next(attribute);
    }
    if (options.forceEmit) {
      this.embeddedAttributes.get(name).emitAgain();
    }
    this._embeddedAttributeChanged$.next(true);
  }

  /**
   * To be called by the entity responsible for creating and/or thus removing this item.
   */
  public destroy(): void {
    if (!ManagedEntity.INDEX.has(this.getId())) {
      return;
    }
    this.destroy$.next();
    this.destroy$.complete();
    this.destructor();
    ManagedEntity.INDEX.delete(this.getId());
    ManagedEntity.INDEX_UPDATED$.next(this.getId());
  }

  protected abstract destructor(): void;

  public onDestruction$(): Observable<void> {
    return this.destroy$.asObservable();
  }

  public toString(): string {
    return `${this.getClassName()}#${this.getId()}`;
  }

  public static updateArray<T extends ManagedEntity, D extends EntityDto>(config: {
    current: T[];
    updatedDtos: D[];
    createFn: (dto: D) => T;
  }): T[] {
    const updatedIDSet = new Set(config.updatedDtos.map((e) => e.id));
    const currentIDSet = new Set(config.current.map((e) => e.getId()));
    const toRemove = config.current.filter((e) => !updatedIDSet.has(e.getId()));
    const remaining = config.current.filter((e) => updatedIDSet.has(e.getId()));
    const toAdd = config.updatedDtos.filter((d) => !currentIDSet.has(d.id));

    toRemove.forEach((e) => {
      e.destroy();
    });

    const added = toAdd.map((d) => config.createFn(d));

    // remaining.remaining.forEach((e) => e.update(d));

    return [...remaining, ...added];
  }
}

export function FromIndex<T>(idArg: String | ((self: T) => String)) {
  return function (target: unknown, propertyName): void {
    delete target[propertyName];
    // Create new property with getter and setter
    Object.defineProperty(target, propertyName, {
      get: function (this: T) {
        if (typeof idArg === 'function') {
          try {
            return ManagedEntity.findFromIndex(idArg(this));
          } catch (e) {
            console.warn('Id function threw error, returning undefined instead', e);
            return undefined;
          }
        } else {
          return ManagedEntity.findFromIndex(idArg);
        }
      },
      set: (el) => {
        throw new Error(propertyName + ' is immutable!');
      },
      enumerable: false,
      configurable: false,
    });
  };
}

export function FromIndex$<T>(idArg: String | ((self: T) => String), nullable = false) {
  return function (target: unknown, propertyName): void {
    delete target[propertyName];
    // Create new property with getter and setter
    Object.defineProperty(target, propertyName, {
      get: function (this: T) {
        if (typeof idArg === 'function') {
          try {
            return nullable
              ? ManagedEntity.findFromIndex$(idArg(this))
              : ManagedEntity.findFromIndex$(idArg(this)).pipe(filter((e) => !!e));
          } catch (e) {
            console.warn('Id function threw error, returning undefined instead', e);
            return undefined;
          }
        } else {
          return nullable
            ? ManagedEntity.findFromIndex$(idArg)
            : ManagedEntity.findFromIndex$(idArg).pipe(filter((e) => !!e));
        }
      },
      set: (el) => {
        throw new Error(propertyName + ' is immutable!');
      },
      enumerable: false,
      configurable: false,
    });
  };
}
