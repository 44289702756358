import { ActivatedRouteSnapshot, BaseRouteReuseStrategy, DetachedRouteHandle } from '@angular/router';
import { Injectable } from '@angular/core';
import { deepEquals } from './util/math/deep-equals';

// see https://blog.bitsrc.io/angular-route-reuse-strategy-c7939ebbf797
@Injectable({
  providedIn: 'root',
})
export class MyStrategy extends BaseRouteReuseStrategy {
  private routeStore = new Map<string, DetachedRouteHandle>();

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return super.shouldAttach(route);
  }

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return super.shouldDetach(route);
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    const curClass = curr['_routerState']?._root?.children[0]?.children[0]?.children[0]?.value?.component;
    const currParams = curr.paramMap['params'];
    const futureParams = future.paramMap['params'];
    const sup = super.shouldReuseRoute(future, curr);
    const self = sup && deepEquals(currParams, futureParams);
    return self;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null): void {
    super.store(route, handle);
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return super.retrieve(route);
  }
}
