import { Delta } from '../delta';
import { DeltaType } from '../../../../util/delta-type.annotation';
import { UUID } from '../../../../util/math/uuid';
import { getRoleFromId } from '../../../../dto/roles';

import { JsonIgnore } from '../../../../util/json';
import { FromIndex } from '../../../../model/relink/managed-entity';
import { GrafitiRoleAssignment } from '../../../../model/entity/grafiti-role-assignment';
import { GrafitiRoleAssignmentHolder } from '../../../../model/entity/grafiti-role-assignment-holder';

@DeltaType('editPermission')
export class EditPermissionDelta extends Delta {
  @JsonIgnore()
  @FromIndex<EditPermissionDelta>((self) => self.assignmentId)
  private roleAssignment: GrafitiRoleAssignment;

  @JsonIgnore()
  @FromIndex<EditPermissionDelta>((self) => self.holderId)
  private holder: GrafitiRoleAssignmentHolder;

  @JsonIgnore()
  private roleBefore: UUID;

  public constructor(private holderId: UUID, protected assignmentId: UUID, protected roleId: UUID) {
    super();
  }

  public apply(): void {
    // TODO naja

    this.requireNonNullish(this.roleAssignment, `Assignment ${this.assignmentId} does not exist!`);
    this.roleBefore = this.roleAssignment.getRole().id;
    this.roleAssignment.setRole(getRoleFromId(this.roleId));

    const otherAssignments = this.holder.getRoleAssignments().filter((a) => a.getId() !== this.assignmentId);
    this.holder.setRoleAssignments([...otherAssignments, this.roleAssignment]);
  }

  public onConfirmation(confirmationDelta: Delta): void {}

  public getUndoDelta(): Delta {
    return new EditPermissionDelta(this.holderId, this.assignmentId, this.roleBefore);
  }

  public override getErrorMessage(): string {
    return `Berechtigung konnte nicht geändert werden`;
  }
}
