import { Injectable, Type } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable, of } from 'rxjs';
import {
  ConfirmationDialogComponent,
  ConfirmationDialogData,
} from './confirmation-dialog/confirmation-dialog.component';
import { ButtonDefinition, ButtonValues, OptionsDialogComponent } from './options-dialog/options-dialog.component';
import { EnterTextDialogComponent, EnterTextDialogData } from './enter-text-dialog/enter-text-dialog.component';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';

/**
 * This service is used to show common dialogs (e.g. yes/no-dialog or a "pick one"-dialog) to the user.
 * The methods encapsulate all the dialog logic and return a single value after the user takes action.
 */
@Injectable()
export class CommonDialogsService {
  private isDialogOpen: boolean = false;

  public constructor(private readonly dialog: MatDialog, private readonly device: DeviceDetectorService) {}

  /**
   * Asks the user for confirmation.
   *
   * @return a one-time-observable, value is true if the user clicked yes, false otherwise.
   */
  public askForConfirmation(
    title: string,
    description: string,
    okText?: string,
    options?: Partial<ConfirmationDialogData>
  ): Observable<boolean> {
    if (this.isDialogOpen) {
      return of(false);
    }
    return new Observable<boolean>((subscriber) => {
      this.isDialogOpen = true;
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '600px',
        data: {
          title: title,
          text: description,
          okText: okText,
          ...options,
        },
      });
      const sub = dialogRef.afterClosed().subscribe(subscriber);

      return (): void => {
        this.isDialogOpen = false;
        sub.unsubscribe();
      };
    });
  }

  /**
   * Asks the user for confirmation.
   * User has a minimum wait time of three seconds by default.
   * This method should be used when the user performs an operation that cannot be undone.
   *
   * @return a one-time-observable, value is true if the user clicked yes, false otherwise.
   */
  public askForImportantConfirmation(
    title: string,
    description: string,
    options?: Partial<ConfirmationDialogData>
  ): Observable<boolean> {
    return this.askForConfirmation(title, description, null, { minWaitTime: 3, ...options });
  }

  public askForTextInput(options?: Partial<EnterTextDialogData>): Observable<string> {
    if (this.isDialogOpen) {
      return of(null);
    }
    return new Observable<string>((subscriber) => {
      this.isDialogOpen = true;
      const dialogRef = this.dialog.open(EnterTextDialogComponent, {
        width: '600px',
        data: {
          ...options,
        },
      });
      const sub = dialogRef.afterClosed().subscribe(subscriber);

      return (): void => {
        this.isDialogOpen = false;
        sub.unsubscribe();
      };
    });
  }

  /**
   * Asks the user for options.
   *
   * @return a one-time-observable, value is the `value` property of the clicked button.
   */
  public selectUserOption<T, Options extends ButtonDefinition<T>[]>(
    title: string,
    description: string,
    ...options: Options
  ): Observable<ButtonValues<Options>> {
    return new Observable<ButtonValues<Options>>((subscriber) => {
      const dialogRef = this.dialog.open(OptionsDialogComponent, {
        width: '400px',
        data: {
          title: title,
          text: description,
          options: options,
        },
      });
      const sub = dialogRef.afterClosed().subscribe(subscriber);
      return (): void => sub.unsubscribe();
    });
  }

  public getDefaultLargeDialogDimensions(): { width: string; height: string } {
    return {
      height: !this.device.isDesktop() ? '90%' : '70%',
      width: !this.device.isDesktop() ? '100%' : '50%',
    };
  }

  public getDefaultSmallDialogDimensions(): { width: string; height: string } {
    return {
      height: undefined, // !this.device.isDesktop() ? '70%' : '70%',
      width: !this.device.isDesktop() ? '100%' : '20%',
    };
  }

  public open(component: Type<any>, data = {}, afterClose: (args: unknown) => void = (args) => {}) {
    const dialogRef = this.dialog.open(component, {
      ...this.getDefaultSmallDialogDimensions(),
      data: data,
    });
    const sub = dialogRef.afterClosed().subscribe((args) => afterClose(args));
    sub.unsubscribe();
  }

  public alert(
    title: string,
    description: string,
    okText?: string,
    options?: Partial<ConfirmationDialogData>
  ): Observable<boolean> {
    if (this.isDialogOpen) {
      return of(false);
    }
    return new Observable<boolean>((subscriber) => {
      this.isDialogOpen = true;
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        width: '600px',
        data: {
          title: title,
          text: description,
          okText: okText,
          ...options,
        },
      });
      const sub = dialogRef.afterClosed().subscribe(subscriber);

      return (): void => {
        this.isDialogOpen = false;
        sub.unsubscribe();
      };
    });
  }
}
