import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faExclamationTriangle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { interval, Observable } from 'rxjs';

export interface AlertDialogData {
  title: string;
  text: string;
  /**
   * Translate Parameters applied to ALL passed texts (title, text, okText, abortText)
   */
  translateParams?: { [key: string]: unknown };
  okText?: string;
}

@Component({
  selector: 'grafiti-alert-dialog',
  templateUrl: 'alert-dialog.component.html',
  styleUrls: ['alert-dialog.component.scss'],
})
export class AlertDialogComponent {
  /**
   * Fontawesome icons
   */
  public readonly faExclamationTriangle = faExclamationTriangle;
  public readonly faTimes = faTimes;

  public title: string;
  public text: string;
  public okText: string;
  public translateParams: { [key: string]: unknown };

  public constructor(@Inject(MAT_DIALOG_DATA) public data: AlertDialogData) {
    this.title = data.title;
    this.text = data.text;
    this.translateParams = data.translateParams;
    this.okText = data.okText ?? 'Ok';
  }
}
