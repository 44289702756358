export enum AuthenticationErrorType {
  CREDENTIALS = 'credentials',
  CONNECTION_FAILED = 'connection_failed',
}

export class AuthenticationError extends Error {
  constructor(public readonly type: AuthenticationErrorType, public readonly originalError?: Error) {
    super('Authentication failure: ' + type);
  }
}
