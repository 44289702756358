import { NgModule } from '@angular/core';
import { ComposeActivityComponent } from './compose-placard-activity/compose-activity.component';
import { AddActivityDialogComponent } from './add-activity-dialog/add-activity-dialog.component';
import { AvailableFlyerTypeSelectionComponent } from './available-flyer-type-selection/available-flyer-type-selection.component';
import { AvailablePlacardTypeSelectionComponent } from './available-placard-type-selection/available-placard-type-selection.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatListModule } from '@angular/material/list';
import { FunctionExpressionModule } from 'ngx-function-expression';
import { AsyncPipe, NgForOf, NgIf, NgOptimizedImage } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { UtilitiesUiModule } from '../utilities-ui/utilities-ui.module';
import { AvailablePlacardTypeSmallSelectionComponent } from './available-placard-type-small-selection/available-placard-type-small-selection.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    ComposeActivityComponent,
    AddActivityDialogComponent,
    AvailableFlyerTypeSelectionComponent,
    AvailablePlacardTypeSelectionComponent,
    AvailablePlacardTypeSmallSelectionComponent,
  ],
  exports: [
    ComposeActivityComponent,
    AvailableFlyerTypeSelectionComponent,
    AvailablePlacardTypeSelectionComponent,
    AvailablePlacardTypeSmallSelectionComponent,
  ],
  imports: [
    MatDialogModule,
    MatInputModule,
    MatSelectModule,
    MatListModule,
    FunctionExpressionModule,
    AsyncPipe,
    MatButtonModule,
    NgIf,
    MatTableModule,
    NgOptimizedImage,
    NgForOf,
    UtilitiesUiModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class RecordingModule {}
