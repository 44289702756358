import { EditPropertyDelta, SetterGetterOverwrite } from '../edit-property.delta';
import { invertDiffs, PropertyDiff } from '../../property-diff';
import { UUID } from '../../../../util/math/uuid';
import { JsonIgnore } from '../../../../util/json';
import { DeltaType } from '../../../../util/delta-type.annotation';
import { GrafitiFlyerType } from '../../../../model/entity/grafiti-flyer-type';
import { FromIndex } from '../../../../model/relink/managed-entity';
import { StyleDto } from '../../../../dto/style.dto';
import { LeafletStyle } from '../../../../util/types/spatial-style';

@DeltaType('editFlyerType')
export class EditFlyerTypeDelta extends EditPropertyDelta<keyof GrafitiFlyerType> {
  @JsonIgnore()
  @FromIndex<EditFlyerTypeDelta>((self) => self.entityId)
  private flyerType: GrafitiFlyerType;

  public constructor(id: UUID, diffs: PropertyDiff<keyof GrafitiFlyerType>[]) {
    super();
    this.entityId = id ?? this.entityId;
    this.diff = diffs ?? this.diff;
  }

  public apply(): void {
    this.forEachDiff(this.flyerType);
  }

  public onConfirmation(confirmationDelta: EditFlyerTypeDelta): void {}

  public getUndoDelta(): EditFlyerTypeDelta {
    return new EditFlyerTypeDelta(this.entityId, invertDiffs(this.diff));
  }

  public getOverwrites(): { [p: string]: SetterGetterOverwrite } {
    return {
      style: {
        getter: () => {
          return this.flyerType.getStyle();
        },
        setter: (el: StyleDto) => {
          this.flyerType?.setStyle(el as LeafletStyle);
        },
      },
    };
  }

  public override getErrorMessage(): string {
    return `Flyertyp konnte nicht bearbeitet werden`;
  }
}
