import { EditPropertyDelta, SetterGetterOverwrite } from '../edit-property.delta';
import { DeltaType } from '../../../../util/delta-type.annotation';
import { invertDiffs, PropertyDiff } from '../../property-diff';
import { UUID } from '../../../../util/math/uuid';
import { JsonIgnore } from '../../../../util/json';

import { AddressDto } from '../../../../dto/address.dto';
import { FromIndex } from '../../../../model/relink/managed-entity';
import { GrafitiLocation } from '../../../../model/entity/grafiti-location';
import { SimpleCoords } from '../../../../dto/simple-coords';

@DeltaType('editLocation')
export class EditLocationDelta extends EditPropertyDelta {
  @JsonIgnore()
  @FromIndex<EditLocationDelta>((self) => self.entityId)
  private location: GrafitiLocation;

  public constructor(id: UUID, diffs: PropertyDiff[]) {
    super();
    this.entityId = id;
    this.diff = diffs;
  }

  public apply(): void {
    const location = this.location;
    this.forEachDiff(location);
  }

  public override onConfirmation(confirmationDelta: EditLocationDelta): void {}

  public override getUndoDelta(): EditLocationDelta {
    return new EditLocationDelta(this.entityId, invertDiffs(this.diff));
  }

  public override getErrorMessage(): string {
    return `Gruppe konnte nicht bearbeitet werden`;
  }

  public getOverwrites(): { [p: string]: SetterGetterOverwrite } {
    return {
      longitude: {
        getter: () => {
          return this.location.getPosition().lng;
        },
        setter: (lng: number) => {
          const loc = this.location;
          const pos = <SimpleCoords>{ lat: loc.getPosition().lat, lng };
          loc.setPosition(pos);
        },
      },
      latitude: {
        getter: () => {
          return this.location.getPosition().lat;
        },
        setter: (lat: number) => {
          const loc = this.location;
          const pos = <SimpleCoords>{ lat, lng: loc.getPosition().lng };
          loc.setPosition(pos);
        },
      },

      address: {
        getter: () => {
          return this.location.getAddress();
        },
        setter: (el: AddressDto) => {
          this.location?.setAddress(el);
        },
      },
    };
  }
}
