import { Delta } from './delta/delta';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StaticInject, StaticInjector } from '../../util/lifecycle/static-injector';
import { WebSocketService } from '../websocket/web-socket.service';
import { first } from 'rxjs/operators';
import { DeltaResponseDto } from './delta-response.dto';

export class DeltaQueue {
  @StaticInject(WebSocketService)
  private readonly ws: WebSocketService;

  private deltas: Delta[] = [];

  public constructor() {}

  public insert(delta: Delta): void {
    this.deltas.push(delta);
  }

  public submit(onConnectionAvailable: (deltas: Delta[]) => void): void {
    const currentDeltas = [...this.deltas];
    this.deltas = [];

    if (this.ws.isConnected()) {
      onConnectionAvailable(currentDeltas);
      return;
    }

    this.ws.connected$.pipe(first()).subscribe(() => {
      onConnectionAvailable(currentDeltas);
    });
  }
}
