import { StaticInjector } from './lifecycle/static-injector';
import { CommonDialogsService } from '../dialog/common-dialogs.service';

export function requestReload(description: string): void {
  const dialogService = StaticInjector.inject(CommonDialogsService);
  dialogService.askForConfirmation('Grafiti neustarten?', description, 'Neustart').subscribe((result) => {
    if (result) {
      location.reload();
    }
  });
}
