import { Delta } from '../delta';
import { DeltaType } from '../../../../util/delta-type.annotation';
import { UUID } from '../../../../util/math/uuid';
import { JsonIgnore } from '../../../../util/json';
import { FromIndex } from '../../../../model/relink/managed-entity';
import { ResponsibilityAreaDto } from '../../../../dto/responsibility-area.dto';
import { GrafitiResponsibilityArea } from '../../../../model/entity/grafiti-responsibility-area';
import { ResponsibilityLinkDto } from '../../../../dto/responsibility-link.dto';
import { CreateRepsonsibilityLinkDelta } from './create-responsibility-link.delta';

@DeltaType('deleteResponsibilityLink')
export class DeleteResponsibilityLinkDelta extends Delta {
  @JsonIgnore()
  private projectId: UUID;

  @JsonIgnore()
  @FromIndex<DeleteResponsibilityLinkDelta>((self) => self.responsibilityLinkDto.areaId)
  private area: GrafitiResponsibilityArea;

  private responsibilityLinkDto: ResponsibilityLinkDto;

  public constructor(responsibilityLinkDto: ResponsibilityLinkDto) {
    super();
    this.responsibilityLinkDto = responsibilityLinkDto ?? this.responsibilityLinkDto;
  }

  public apply(): void {
    const toDelete = this.responsibilityLinkDto;
    const project = this.area.getProject();
    this.projectId = project.getId();
    project.setResponsibilityLinks(project.getResponsibilityLinks().filter((link) => link.id !== toDelete.id));
    this.area.setResponsibilityLinkIds(this.area.getResponsibilityLinkIds().filter((lid) => lid !== toDelete.id));
  }

  public onConfirmation(confirmationDelta: DeleteResponsibilityLinkDelta): void {}

  public override getUndoDelta(): Delta {
    return new CreateRepsonsibilityLinkDelta(this.responsibilityLinkDto, this.projectId);
  }

  public override getErrorMessage(): string {
    return `Zuständigkeit'${this.responsibilityLinkDto.id}' konnte nicht gelöscht werden!`;
  }
}
