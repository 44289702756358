// Define a Map to store instances
import { Class, Constructable } from '../../../util/lifecycle/constructable';
import { BehaviorSubject, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { Delta } from '../delta/delta';
import { filter, first, shareReplay, takeUntil } from 'rxjs/operators';
import { hash } from '../../../util/hash';
import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';

type DeltaTopic = string;

let loggingEnabled = environment.logging.deltaReceiver;
export function enableDeltaReceiverLogging(val: boolean = true): void {
  loggingEnabled = val;
}

@Injectable({
  providedIn: 'root',
})
export class DeltaReceiverRegistry {
  private readonly receivers = new Map<DeltaTopic, DeltaReceiver[]>();
  private readonly topicsReverse = new Map<DeltaReceiver, DeltaTopic[]>();

  private readonly _addedTopics$ = new BehaviorSubject<DeltaTopic[]>([]);
  private readonly _removedTopics$ = new BehaviorSubject<DeltaTopic[]>([]);

  public readonly addedTopics$ = this._addedTopics$.pipe(shareReplay(1));
  public readonly removedTopics$ = this._removedTopics$.pipe(shareReplay(10));

  private readonly unloadedTopics$ = new ReplaySubject<string>(1);

  public constructor() {}
  public getAllTopics(): DeltaTopic[] {
    return [...this.receivers.keys()];
  }

  public register<T extends DeltaReceiver>(receiver: T): DeltaReceiverReference<T> {
    const protoName = Object.getPrototypeOf(receiver).constructor.name;
    if (!deltaReceiverGuard(receiver)) {
      throw new Error(`${receiver} does not implement DeltaReceiver!`);
    }

    const instance = receiver;
    //const instanceId = hash(instance);
    // instance['__instance_id'] = instanceId;

    if (loggingEnabled) {
      // console.log(`DeltaReceiver::${protoName}#${instanceId}: Creating instance...`);
    }

    const until$ = instance.receiveDeltasUntil$();

    const receiveFrom$ = instance.receiveDeltasFrom$ ? instance.receiveDeltasFrom$() : of(null as void);

    const setup = (start$: Observable<void>, topics: string[]) => {
      start$.subscribe(() => {
        if (loggingEnabled) {
          // console.log(`DeltaReceiver::${protoName}#${instanceId}: Start receiving...`);
        }

        if (loggingEnabled) {
          // console.log(`DeltaReceiver::${protoName}#${instanceId}: Set topics to ${JSON.stringify(topics)}`);
        }
        if (topics.length === 0) {
          /*console.warn(
            `DeltaReceiver::${protoName}#${instanceId}: provided empty list of topics.
  This is likely not intended.`
          );*/
        }
        topics.forEach((topic) => {
          this.addToReceivers(topic, instance);
        });
        this._addedTopics$.next(topics);
      });
    };

    let topics: string[];

    if (receiveFrom$ instanceof Observable) {
      // setup all at once, legacy
      const start$ = receiveFrom$ ? receiveFrom$.pipe(first(), takeUntil(until$)) : of(null as void);
      topics = instance.getTopics();
      setup(start$, topics);
    } else {
      // setup individual topics
      receiveFrom$.forEach((topicStart) => {
        topics = [topicStart.topic];
        setup(topicStart.when$, topics);
      });
    }
    const unloadThen$ = this.unloadedTopics$.pipe(filter((topic) => topics.some((t) => t === topic)));
    const reference = new DeltaReceiverReference<T>(receiver, unloadThen$);

    until$.subscribe(() => {
      if (loggingEnabled) {
        // console.log(`DeltaReceiver::${protoName}#${instanceId}: Unsubscribing`);
      }
      this.removeFromReceivers(instance);
      this._removedTopics$.next(instance.getTopics());
    });

    return reference;
  }

  public unload(topic: string): void {
    this.unloadedTopics$.next(topic);
  }

  private addToReceivers(topic: string, receiver: DeltaReceiver): void {
    const forTopic = this.receivers.get(topic) ?? [];
    const present = forTopic.find((r) => r === receiver);
    if (present) {
      return;
    }
    this.receivers.set(topic, [...forTopic, receiver]);
    this.topicsReverse.set(receiver, [...(this.topicsReverse.get(receiver) ?? []), topic].distinct());
  }

  private removeFromReceivers(receiver: DeltaReceiver): void {
    const topics = this.topicsReverse.get(receiver) ?? [];
    this.topicsReverse.delete(receiver);

    topics.forEach((topic) => {
      this.receivers.set(
        topic,
        this.receivers.get(topic).filter((r) => r !== receiver)
      );
    });
  }
}

class DeltaReceiverReference<T extends DeltaReceiver> {
  public onUnload$ = new Subject<{ receiver: T; topic: string }>();

  public constructor(private readonly receiver: T, private readonly unloadWhen$: Observable<string>) {
    unloadWhen$.subscribe((topic) => {
      this.onUnload$.next({
        receiver,
        topic,
      });
    });
  }
  public getOnUnload$(): Observable<{ receiver: T; topic: string }> {
    return this.onUnload$.pipe(first());
  }
}

export interface DeltaReceiver {
  /**
   * Provides an observable that will emit up to once.
   * If this method is implemented, the instance waits up until the observable emits,
   * and only then subscribes to deltas.
   * If this method is not implemented, the instance will subscribe to deltas right away.
   */
  receiveDeltasFrom$?(): Observable<void> | TopicStart[];

  /**
   * Provide an observable that will emit once.
   * The instance will receive deltas until the observable emits.
   * If the observable does not emit, you may run into MEMORY LEAKS
   */
  receiveDeltasUntil$(): Observable<void>;

  /**
   * Called whenever a delta has been received on one of the subscribed topics.
   */
  receivedDelta?(source: DeltaSource, delta: Delta): void;

  /**
   * Provide a list of topics to subscribe to, which are static.
   *
   * When defining the topics you should leave out the /delta part
   * (instead of /user/delta/communities/123 write /communities/123)
   */
  getTopics(): string[];
}

function deltaReceiverGuard(obj: any): obj is DeltaReceiver {
  return (
    // typeof obj['receivedDelta'] === 'function' &&
    typeof obj['receiveDeltasUntil$'] === 'function' &&
    (typeof obj['getTopics'] === 'function' || typeof obj['getTopics$'] === 'function')
  );
}

function deltaReceiverClassGuard(constr: any): constr is Constructable<DeltaReceiver> {
  const proto = constr.prototype;
  return /*proto['receivedDelta'] &&*/ proto['receiveDeltasUntil$'] && (proto['getTopics'] || proto['getTopics$']);
}

export type DeltaSource = 'LOCAL' | 'REMOTE';
export type TopicStart = { topic: string; when$: Observable<void> };
