import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { GrLoginComponent } from './auth/login/gr-login.component';
import { GrLogoutComponent } from './auth/logout/gr-logout.component';
import { GrRegisterComponent } from './auth/register/gr-register.component';
import { GrRequestPasswordComponent } from './auth/request-password/request-password.component';
import { GrConfirmResetComponent } from './auth/confirm-reset/confirm-reset.component';
import { GrAuthEntryComponent } from './auth/auth-entry/auth-entry.component';
import { OnResetCodeSentComponent } from './auth/on-reset-code-sent/on-reset-code-sent.component';

export const routes: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module').then((m) => m.PagesModule),
  },
  {
    path: 'map',
    loadChildren: () => import('./map-explorer/map-explorer.module').then((m) => m.MapExplorerModule),
  },
  {
    path: 'auth',
    component: GrAuthEntryComponent,
    children: [
      {
        path: '',
        component: GrLoginComponent,
      },
      {
        path: 'login',
        component: GrLoginComponent,
      },
      {
        path: 'register',
        component: GrRegisterComponent,
      },
      {
        path: 'logout',
        component: GrLogoutComponent,
      },
      {
        path: 'request-password',
        component: GrRequestPasswordComponent,
      },
      {
        path: 'reset-password/:token',
        component: GrConfirmResetComponent,
      },
      {
        path: 'code-sent',
        component: OnResetCodeSentComponent,
      },
    ],
  },
  { path: '*', redirectTo: 'pages', pathMatch: 'full' },
  { path: '**', redirectTo: '/pages/home' },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, { ...config })],
  exports: [RouterModule],
})
export class AppRoutingModule {
  public constructor() {}
}
