import { UUID } from '../util/math/uuid';
import { RoleDto } from './role.dto';

export const ROLE_NONE_ID = UUID.fromString('00000000-0000-0000-0000-100000000000');
export const ROLE_OWNER_ID = UUID.fromString('00000000-0000-0000-0000-100000000001');
export const ROLE_COLLABORATEUR_ID = UUID.fromString('00000000-0000-0000-0000-100000000002');
export const ROLE_MODERATOR_ID = UUID.fromString('00000000-0000-0000-0000-100000000005');
export const ROLE_GUEST_ID = UUID.fromString('00000000-0000-0000-0000-100000000003');
export const ROLES = {
  owner: <RoleDto>{
    id: ROLE_OWNER_ID,
    name: 'OWNER',
    values: 'r,m,cc,ct,ms,mc,mt,ds,dc,dt,cn',
  },
  moderator: <RoleDto>{
    id: ROLE_MODERATOR_ID,
    values: 'r,cc,ct,ms,mc,mt,dc,dt,cn',
    name: 'NONE',
  },
  collaborateur: <RoleDto>{
    id: ROLE_COLLABORATEUR_ID,
    name: 'COLLABORATEUR',
    values: 'r,cc,cn',
  },
  guest: <RoleDto>{
    id: ROLE_GUEST_ID,
    name: 'GUEST',
    values: 'r',
  },
  none: <RoleDto>{
    id: ROLE_NONE_ID,
    values: '',
    name: 'NONE',
  },
};

// TODO also build a proper repository for this
export function getRoleFromId(id: UUID): RoleDto {
  if (id === ROLE_OWNER_ID) {
    return ROLES.owner;
  }
  if (id === ROLE_COLLABORATEUR_ID) {
    return ROLES.collaborateur;
  }
  if (id === ROLE_GUEST_ID) {
    return ROLES.guest;
  }
  return ROLES.none;
}
