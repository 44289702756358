import { Observable } from 'rxjs';
import { PrivateUserDto } from '../../dto/private-user.dto';
import { SparseUserDto } from '../../dto/sparse-user.dto';
import { GrafitiEntity } from './grafiti-entity';
import { Searchable } from './searchable';
import { ValueSubject } from '../../util/reactive/value-subject';
import { AccessManaged, GrafitiPermission } from '../../dto/permission';
import { EmailLoginOptionDto } from '../../dto/email-login-option.dto';
import { TelegramLoginOptionDto } from '../../dto/telegram-login-option.dto';

export class GrafitiUser extends GrafitiEntity implements Searchable {
  private readonly username$: ValueSubject<string>;
  private readonly name$: ValueSubject<string>;
  private readonly admin$: ValueSubject<boolean>;

  private readonly active$: ValueSubject<boolean>;

  public constructor(dto: SparseUserDto, setActive: boolean = false) {
    super(dto);

    this.username$ = new ValueSubject<string>(dto.username);
    this.name$ = new ValueSubject<string>(dto.name);
    this.admin$ = new ValueSubject<boolean>(dto.admin);
    this.active$ = new ValueSubject<boolean>(setActive);
    this.ready();
  }

  public setName(name: string): void {
    this.name$.next(name);
  }

  public getName(): string {
    return this.name$.getValue();
  }

  public getName$(): Observable<string> {
    return this.name$;
  }

  public setUsername(name: string): void {
    this.username$.next(name);
  }

  public getUsername(): string {
    return this.username$.getValue();
  }

  public getUsername$(): Observable<string> {
    return this.username$;
  }

  public setAdmin(value: boolean): void {
    this.admin$.next(value);
  }

  public isAdmin(): boolean {
    return this.admin$.getValue();
  }

  public isAdmin$(): Observable<boolean> {
    return this.admin$;
  }

  public isActive(): boolean {
    return this.active$.getValue();
  }

  public setActive(active: boolean): void {
    this.active$.next(active);
  }

  public isActive$(): Observable<boolean> {
    return this.active$;
  }

  public get name(): string {
    return this.getName();
  }

  public get username(): string {
    return this.getUsername();
  }

  protected destructor(): void {}

  public override buildDto(): PrivateUserDto {
    return {
      ...super.buildDto(),
      username: this.getUsername(),
      name: this.getName(),
      admin: this.isAdmin(),
    };
  }

  public buildSparseDto(): SparseUserDto {
    return {
      id: this.getId(),
      name: this.getName(),
      username: this.getUsername(),
      admin: this.isAdmin(),
    };
  }

  matchesQueryFilters(queries: string): boolean {
    return false;
  }

  matchesTextFilter(filter: string): boolean {
    return this.getEntireSearchString().includes(filter);
  }

  protected getEntireSearchString(): string {
    return [this.getUsername(), this.getName()]
      .filter((e) => !!e)
      .join(',')
      .toLowerCase();
  }
}
