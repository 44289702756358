import { Injectable } from '@angular/core';
import { DeltaReceiver, DeltaReceiverRegistry } from './editing/target/delta-receiver';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../auth/authentication.service';
import { UUID } from '../util/math/uuid';

@Injectable()
export class CommonTopicsService implements DeltaReceiver {
  public onLogout$ = this.authService.onLogout$.asObservable();
  private userId: UUID;

  public constructor(
    private readonly authService: AuthenticationService,
    private readonly registry: DeltaReceiverRegistry
  ) {}

  public activate(userId: UUID): void {
    this.userId = userId;
    this.registry.register(this);
  }
  public getTopics(): string[] {
    return [`/personal/${this.userId.toString()}`, `/overview`];
  }

  public receiveDeltasUntil$(): Observable<void> {
    return this.onLogout$;
  }
}
