import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbsService {
  public breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>([]);

  public constructor(private readonly title: Title) {}

  public setBreadcrumbs(bcs: Breadcrumb[]): void {
    this.breadcrumbs$.next(bcs);
    this.title.setTitle('Grafiti - ' + bcs.map((bc) => bc.name$.immediate()).join(' > '));
  }
}

export interface Breadcrumb {
  routerLink: string;
  name$: Observable<string>;
}
