import { EditPropertyDelta } from '../edit-property.delta';
import { DeltaType } from '../../../../util/delta-type.annotation';
import { invertDiffs, PropertyDiff } from '../../property-diff';
import { UUID } from '../../../../util/math/uuid';
import { JsonIgnore } from '../../../../util/json';
import { GrafitiUser } from '../../../../model/entity/grafiti-user';
import { FromIndex } from '../../../../model/relink/managed-entity';

@DeltaType('editUser')
export class EditUserDelta extends EditPropertyDelta<keyof GrafitiUser> {
  @JsonIgnore()
  @FromIndex<EditUserDelta>((self) => self.entityId)
  private user: GrafitiUser;

  public constructor(id: UUID, diffs: PropertyDiff<keyof GrafitiUser>[]) {
    super();
    this.entityId = id;
    this.diff = diffs;
  }

  public apply(): void {
    if (!this.user) {
      return;
    }

    this.forEachDiff(this.user);
  }

  public override onConfirmation(confirmationDelta: EditUserDelta): void {}

  public getUndoDelta(): EditUserDelta {
    return new EditUserDelta(this.entityId, invertDiffs(this.diff));
  }

  public override getErrorMessage(): string {
    return `Benutzer konnte nicht geändert werden`;
  }
}
