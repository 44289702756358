import { Delta } from '../communication/editing/delta/delta';
import { deltaTypes } from './delta-type.annotation';
import { CompoundDelta } from '../communication/editing/delta/compound.delta';
import { NoopDelta } from '../communication/editing/delta/NoopDelta';

export interface DeltaLike {
  type: string;
}

/**
 * The ChangeParser holds utility functions to parse JSON objects into Changes.
 */
export class DeltaParser {
  /**
   * Used to parse a JSON object into a delta object.
   *
   * @param object JSON object representing a delta
   */
  public static parseDelta(object: DeltaLike): Delta {
    const deltaType = deltaTypes[object.type];
    if (!deltaType) {
      console.warn("Unknown delta type '" + deltaType + "'");
    }
    if (deltaType === CompoundDelta && typeof object['deltas'] !== 'undefined') {
      object['deltas'] = object['deltas'].map(DeltaParser.parseDelta);
    }
    try {
      const delta = Object.assign(new deltaType(), object);
      delta.setReceivedTime(Date.now());
      return delta;
    } catch (e) {
      console.log(deltaTypes, object.type, deltaType);
      console.error('Cannot parse object as delta: ', object, e);
      return new NoopDelta();
    }
  }

  /**
   * Merges the server response into the existing cached change.
   */
  public static combine(localDelta: Delta, serverChange: Delta): Delta {
    // Delete the createdTimestamp of the server delta so that the timestamp of the local change isn't overridden in the combined Change.
    delete serverChange['createdTimestamp'];
    return Object.assign(
      localDelta,
      serverChange,
      serverChange['deltas']
        ? { deltas: localDelta['deltas']?.map((sub, i) => DeltaParser.combine(sub, serverChange['deltas'][i])) ?? [] }
        : null
    );
  }
}
