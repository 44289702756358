import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';

import { filter, map, switchMap, tap } from 'rxjs/operators';
import { PrivateUserDto } from '../dto/private-user.dto';
import { AbstractRestService } from './abstract-rest.service';
import { StorageService } from '../storage.service';
import { DeltaService } from './editing/delta.service';
import { UserController } from './user/user-controller.service';
import { CommunityController } from './community-controller';
import { Userspace } from '../model/userspace.service';
import { GrafitiUser } from '../model/entity/grafiti-user';
import { GrafitiCommunity } from '../model/entity/grafiti-community';
import { CommonTopicsService } from './common-topics.service';
import { ProjectLoaderService } from '../model/project-loader.service';
import { GrafitiPrivateUser } from '../model/entity/grafiti-private-user';

@Injectable()
export class ApplicationInitService extends AbstractRestService {
  private activated = false;

  private readonly ready$ = new ReplaySubject<boolean>();

  public constructor(
    protected readonly http: HttpClient,
    private readonly storageService: StorageService,
    private readonly userController: UserController,
    private readonly communityController: CommunityController,
    private readonly projectLoader: ProjectLoaderService,
    private readonly userspace: Userspace,
    private readonly commonTopicService: CommonTopicsService,
    private readonly deltaService: DeltaService
  ) {
    super(http); //
    const userDto = this.storageService.currentUser;
    if (userDto) {
      const user =
        (this.userspace.getUserStore().find(userDto.id) as GrafitiPrivateUser) ?? new GrafitiPrivateUser(userDto);
      this.userspace.getUserStore().save(user);
    }
  }

  public activate(path: string = ''): Observable<void> {
    if (this.activated) {
      return of();
    }
    this.activated = true;

    this.userController
      .fetchCurrentUser$()
      .pipe(
        switchMap((userDto) => {
          this.setCurrentUser(userDto);
          return this.communityController.getAllSparse();
        }),
        switchMap((sparseCommunityDtos) => {
          // initialize all available communities
          const communities = sparseCommunityDtos.map((dto) => new GrafitiCommunity(dto));
          this.userspace.getCommunities().addAll(communities);

          const projectIds = sparseCommunityDtos.flatMap((c) => c.projectIds);
          return this.projectLoader.fetchMultiple(projectIds, false);
        })
      )
      .subscribe((value) => {
        this.deltaService.activate();
        const loggedIn = this.userspace.getUserStore().findLoggedInUser();
        this.commonTopicService.activate(loggedIn.getId());
        this.ready$.next(true);
      });
    return this.ready$.pipe(
      filter((e) => e),
      map((e) => null)
    );
  }

  public onReady$(): Observable<boolean> {
    return this.ready$.asObservable();
  }

  private setCurrentUser(userDto: PrivateUserDto): void {
    const user =
      (this.userspace.getUserStore().find(userDto.id) as GrafitiPrivateUser) ?? new GrafitiPrivateUser(userDto);
    user.setActive(true);
    this.userspace.getUserStore().save(user);
    this.storageService.setCurrentUser(userDto);
  }
}
