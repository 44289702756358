import { Delta } from '../delta';
import { DeltaType } from '../../../../util/delta-type.annotation';

import { JsonIgnore } from '../../../../util/json';
import { UUID } from '../../../../util/math/uuid';
import { SimpleCoords } from '../../../../dto/simple-coords';
import { FromIndex } from '../../../../model/relink/managed-entity';
import { GrafitiFlyerRun } from '../../../../model/entity/grafiti-flyer-run';

@DeltaType('editFlyerRun')
export class EditFlyerRunDelta extends Delta {
  private readonly flyerRunId: UUID;

  private readonly oldArea: SimpleCoords[][];
  private readonly newArea: SimpleCoords[][];

  @JsonIgnore()
  @FromIndex<EditFlyerRunDelta>((self) => self.flyerRunId)
  private flyerRun: GrafitiFlyerRun;

  public constructor(id: UUID, oldArea: SimpleCoords[][], newArea: SimpleCoords[][]) {
    super();

    throw new Error('Flyer runs tracks is currently immutable!');

    this.flyerRunId = id ?? this.flyerRunId;

    this.oldArea = oldArea ?? this.oldArea;
    this.newArea = newArea ?? this.oldArea;
  }

  public apply(): void {
    // this.requireTrue(this.newArea.length >= 3, 'Newbounds length is too small');

    // TODO check for preconditions
    this.flyerRun.setArea(this.newArea); //
  }

  public onConfirmation(confirmationDelta: Delta): void {}

  public getUndoDelta(): Delta {
    return new EditFlyerRunDelta(this.flyerRunId, this.newArea, this.oldArea);
  }

  public override getErrorMessage(): string {
    return `Flyerbereich konnte nicht bearbeitet werden`;
  }
}
