import { Delta } from '../delta';
import { DeltaType } from '../../../../util/delta-type.annotation';
import { DeleteCommunityDelta } from './delete-community-delta';
import { ROLES } from '../../../../dto/roles';
import { UUID } from '../../../../util/math/uuid';
import { CommunityDto } from '../../../../dto/community.dto';
import { JsonIgnore } from '../../../../util/json';
import { StaticInject } from '../../../../util/lifecycle/static-injector';
import { Userspace } from '../../../../model/userspace.service';
import { GrafitiCommunity } from '../../../../model/entity/grafiti-community';
import { GrafitiRoleAssignment } from '../../../../model/entity/grafiti-role-assignment';
import { SparseCommunityDto } from '../../../../dto/sparse-community.dto';

@DeltaType('createCommunity')
export class CreateCommunityDelta extends Delta {
  @JsonIgnore()
  @StaticInject(Userspace)
  private readonly userspace: Userspace;

  public dto: SparseCommunityDto;

  public constructor(dto: SparseCommunityDto) {
    super();

    this.dto = this.dto ?? dto;
  }

  public apply(): void {
    if (this.userspace.getCommunities().has(this.dto.id)) {
      return;
    }
    const community = new GrafitiCommunity(this.dto);
    this.userspace.getCommunities().update(community);

    const ownerRole = ROLES.owner;
    const ownerRoleAssignment = new GrafitiRoleAssignment({
      role: ownerRole,
      id: UUID.randomUUID(),
      holder: community.getId(),
      user: this.userspace.getUserStore().findLoggedInUser().buildSparseDto(),
    });
    community.setRoleAssignments([...community.getRoleAssignments(), ownerRoleAssignment]);
  }

  public onConfirmation(confirmationDelta: CreateCommunityDelta): void {}

  public override getUndoDelta(): Delta {
    return new DeleteCommunityDelta(this.dto.id);
  }

  public override getErrorMessage(): string {
    return `Neue Community'${this.dto.name}' konnte nicht gespeichert werden!`;
  }
}
