import { ClassName } from './class-name';
import { JsonIgnore } from '../../util/json';
import { Class } from '../../util/lifecycle/constructable';

export abstract class JClass<T> {
  @JsonIgnore()
  public getClassName(): ClassName {
    return this.getClass().constructor.name;
  }

  @JsonIgnore()
  public getPrototype(): unknown {
    return Object.getPrototypeOf(this);
  }
  @JsonIgnore()
  public getClassStack(): ClassName[] {
    const stack = [];
    let proto = this.getPrototype();
    while (!!proto) {
      stack.push(proto.constructor.name);
      proto = Object.getPrototypeOf(proto);
    }
    return stack;
  }

  @JsonIgnore()
  public getClass(): Class<T> {
    return this as unknown as Class<T>;
  }
}
