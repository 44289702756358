import { GrafitiNote } from '../entity/grafiti-note';
import { Observable } from 'rxjs';
import { UUID } from '../../util/math/uuid';

export interface NotesHolder {
  setNotes(notes: GrafitiNote[]): void;
  getNotes(): GrafitiNote[];
  getNotes$(): Observable<GrafitiNote[]>;
  getId(): UUID;
}

export function isNoteHolder(obj: unknown): obj is NotesHolder {
  return (
    typeof obj['setNotes'] === 'function' &&
    typeof obj['getNotes'] === 'function' &&
    typeof obj['getNotes$'] === 'function'
  );
}
