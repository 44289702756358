import { ProjectEntity } from './project-entity';
import { GrafitiProject } from './grafiti-project';
import { PlacardTypeDto } from '../../dto/placard-type.dto';
import { Observable } from 'rxjs';
import { ValueSubject } from '../../util/reactive/value-subject';
import { GrafitiEntity } from './grafiti-entity';
import { Searchable } from './searchable';
import { forTemplatesDefault, GrafitiPermission, AccessManaged } from '../../dto/permission';
import { Sortable } from './sortable';

export class GrafitiPlacardType extends GrafitiEntity implements ProjectEntity, Searchable, AccessManaged, Sortable {
  private readonly material$: ValueSubject<string>;
  private readonly price$: ValueSubject<number>;
  private readonly reattachingPrice$: ValueSubject<number>;
  private readonly motive$: ValueSubject<string>;
  private readonly format$: ValueSubject<string>;
  private readonly name$: ValueSubject<string>;
  private readonly description$: ValueSubject<string>;

  private readonly project$: ValueSubject<GrafitiProject>;

  public constructor(dto: PlacardTypeDto, project: GrafitiProject) {
    super(dto);

    this.material$ = new ValueSubject(dto.material, this.destroy$);
    this.price$ = new ValueSubject(dto.price, this.destroy$);
    this.reattachingPrice$ = new ValueSubject(dto.reattachingPrice);
    this.motive$ = new ValueSubject(dto.motive, this.destroy$);
    this.format$ = new ValueSubject(dto.format, this.destroy$);
    this.name$ = new ValueSubject(dto.name, this.destroy$);
    this.description$ = new ValueSubject(dto.description, this.destroy$);
    this.project$ = new ValueSubject<GrafitiProject>(project, this.destroy$);
    this.ready();
  }

  public getProject(): GrafitiProject {
    return this.project$.getValue();
  }

  public getMaterial(): string {
    return this.material$.getValue();
  }

  public getMaterial$(): Observable<string> {
    return this.material$;
  }

  public setMaterial(value: string) {
    this.material$.next(value);
  }

  public getPrice(): number {
    return this.price$.getValue();
  }

  public getPrice$(): Observable<number> {
    return this.price$;
  }

  public setPrice(value: number) {
    this.price$.next(value);
  }

  public getReattachingPrice(): number {
    return this.reattachingPrice$.getValue();
  }

  public getReattachingPrice$(): Observable<number> {
    return this.reattachingPrice$;
  }

  public setReattachingPrice(value: number) {
    this.reattachingPrice$.next(value);
  }

  public getMotive(): string {
    return this.motive$.getValue();
  }

  public getMotive$(): Observable<string> {
    return this.motive$;
  }

  public setMotive(value: string) {
    this.motive$.next(value);
  }

  public getFormat(): string {
    return this.format$.getValue();
  }

  public getFormat$(): Observable<string> {
    return this.format$;
  }

  public setFormat(value: string) {
    this.format$.next(value);
  }

  public getName(): string {
    return this.name$.getValue();
  }

  public getName$(): Observable<string> {
    return this.name$;
  }

  public setName(value: string) {
    this.name$.next(value);
  }

  public getDescription(): string {
    return this.description$.getValue();
  }

  public getDescription$(): Observable<string> {
    return this.description$;
  }

  public setDescription(value: string) {
    this.description$.next(value);
  }

  /* this is not really elegant and I dislike these getters, but it makes the management table much more easier to work with
   * (at least for now, maybe this should be changed in the future)
   */
  public get motive(): string {
    return this.getMotive();
  }

  public get name(): string {
    return this.getName();
  }

  public get description(): string {
    return this.getDescription();
  }

  public get material(): string {
    return this.getMaterial();
  }

  public get format(): string {
    return this.getFormat();
  }

  public get price(): number {
    return this.getPrice();
  }

  public get reattachingPrice(): number {
    return this.getReattachingPrice();
  }

  protected destructor(): void {}

  public buildDto(): PlacardTypeDto {
    const dto = super.buildDto() as PlacardTypeDto;
    dto.material = this.getMaterial();
    dto.price = this.getPrice();
    dto.reattachingPrice = this.getReattachingPrice();
    dto.motive = this.getMotive();
    dto.format = this.getFormat();
    dto.name = this.getName();
    dto.description = this.getDescription();
    dto.project = this.getProject().getId();
    return dto;
  }

  matchesQueryFilters(queries: string): boolean {
    return false;
  }

  matchesTextFilter(filter: string): boolean {
    return this.getEntireSearchString().includes(filter);
  }

  private getEntireSearchString() {
    return [this.getName(), this.getDescription(), this.getFormat(), this.getMotive(), this.getMaterial()]
      .join(',')
      .toLowerCase();
  }

  hasPermission(permission: GrafitiPermission): boolean {
    return this.getProject().hasPermission(forTemplatesDefault(permission));
  }

  hasPermission$(permission: GrafitiPermission): Observable<boolean> {
    return this.getProject().hasPermission$(forTemplatesDefault(permission));
  }

  public getSortString(column: string): string {
    switch (column) {
      case 'name':
        return this.getName();
      case 'description':
        return this.getDescription();
      default:
        return '';
    }
  }
}
