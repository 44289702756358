import { Delta } from '../delta';
import { DeltaType } from '../../../../util/delta-type.annotation';
import { UUID } from '../../../../util/math/uuid';
import { JsonIgnore } from '../../../../util/json';
import { FromIndex } from '../../../../model/relink/managed-entity';
import { GrafitiProject } from '../../../../model/entity/grafiti-project';
import { CreateRepsonsibilityAreaDelta } from './create-responsibility-area-delta';
import { ResponsibilityAreaDto } from '../../../../dto/responsibility-area.dto';
import { GrafitiResponsibilityArea } from '../../../../model/entity/grafiti-responsibility-area';

@DeltaType('deleteResponsibilityArea')
export class DeleteResponsibilityAreaDelta extends Delta {
  @JsonIgnore()
  private responsibilityAreaDto: ResponsibilityAreaDto;

  @JsonIgnore()
  @FromIndex<DeleteResponsibilityAreaDelta>((self) => self.responsibilityAreaId)
  public responsibilityArea: GrafitiResponsibilityArea;

  public responsibilityAreaId: UUID;

  public constructor(responsibilityAreaId: UUID) {
    super();
    this.responsibilityAreaId = responsibilityAreaId ?? this.responsibilityAreaId;
  }

  public apply(): void {
    const toDelete = this.responsibilityArea;
    this.responsibilityAreaDto = toDelete.buildDto();
    const project = toDelete.getProject();
    project.setResponsibilityAreas(
      project.getResponsibilityAreas().filter((area) => area.getId() !== toDelete.getId())
    );
    toDelete.destroy();
  }

  public onConfirmation(confirmationDelta: DeleteResponsibilityAreaDelta): void {}

  public override getUndoDelta(): Delta {
    return new CreateRepsonsibilityAreaDelta(this.responsibilityAreaDto);
  }

  public override getErrorMessage(): string {
    return `Zuständigkeitsbereich '${this.responsibilityAreaDto.name}' konnte nicht gelöscht werden!`;
  }
}
