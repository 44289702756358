import { Injectable } from '@angular/core';
import { AbstractRestService } from '../abstract-rest.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProjectDto } from '../../dto/project.dto';
import { UUID } from '../../util/math/uuid';
import { EntityController } from '../entity-controller';
import { SparseProjectDto } from '../../dto/sparse-project.dto';

@Injectable({
  providedIn: 'root',
})
export class ProjectController extends EntityController<ProjectDto, SparseProjectDto> {
  constructor(protected readonly http: HttpClient) {
    super(http, '/projects');
  }

  public getSize(id: UUID): Observable<number> {
    return this.http.get<number>(this.getUrl(`/${id}/size`));
  }
}
