import { GrafitiProject } from './grafiti-project';
import { ActivityDto } from '../../dto/activity.dto';
import { ACTIVITY_TYPES, ActivityType, ActivityTypeName } from './activity-type';
import { UUID } from '../../util/math/uuid';
import { ImageDto } from '../../dto/image.dto';
import { GrafitiPlacardType } from './grafiti-placard-type';
import { GrafitiPlacard } from './grafiti-placard';
import { Observable, of } from 'rxjs';
import { ValueSubject } from '../../util/reactive/value-subject';
import { GrafitiEntity } from './grafiti-entity';
import { Searchable } from './searchable';
import { forContentDefault, GrafitiPermission, AccessManaged } from '../../dto/permission';
import { Sortable } from './sortable';

export class GrafitiActivity extends GrafitiEntity implements Searchable, AccessManaged, Sortable {
  private readonly placard$: ValueSubject<GrafitiPlacard>;
  private readonly addedType$: ValueSubject<GrafitiPlacardType>;
  private readonly imageId: ValueSubject<UUID>;
  private readonly activityType$: ValueSubject<ActivityType>;
  private readonly multiplier$: ValueSubject<number>;

  public constructor(dto: ActivityDto, placard: GrafitiPlacard) {
    super(dto);
    this.placard$ = new ValueSubject<GrafitiPlacard>(placard, this.destroy$);
    this.addedType$ = new ValueSubject<GrafitiPlacardType>(
      placard
        .getLocation()
        .getProject()
        .getPlacardTypes()
        .find((type) => type.getId() === dto.addedType),
      this.destroy$
    );
    this.activityType$ = new ValueSubject<ActivityType>(ACTIVITY_TYPES[dto.type as ActivityTypeName]);
    this.multiplier$ = new ValueSubject<number>(dto.multiplier, this.destroy$);

    this.setEmbeddedAttribute('imageId', dto.image);

    this.setPerformingUser(dto.performingUser);

    this.setDescription(''); // TODO currently descriptions by user are not supported
    this.setReported(dto.reported);
    this.ready();
  }

  public override update(dto: ActivityDto) {
    super.update(dto);
    this.setActivityType(ACTIVITY_TYPES[dto.type as ActivityTypeName]);
    this.setImageId(dto.image);
    this.setPerformingUser(dto.performingUser);
    this.setReported(dto.reported);
  }

  public getProject(): GrafitiProject {
    return this.getPlacard().getProject();
  }

  public getPerformingUser(): string {
    return this.getEmbeddedAttribute('performingUser');
  }
  public setPerformingUser(user: string): void {
    this.setEmbeddedAttribute('performingUser', user);
  }

  public getActivityType(): ActivityType {
    return this.activityType$.getValue();
  }

  public setActivityType(type: ActivityType): void {
    this.activityType$.next(type);
  }

  public getDescription(): string {
    return this.getEmbeddedAttribute('description');
  }

  public setDescription(description: string): void {
    this.setEmbeddedAttribute('description', description);
  }

  public getImage(): ImageDto {
    return undefined; // return this.imageService.getImage(this.getImageId());
  }

  public getImageId(): UUID {
    return this.getEmbeddedAttribute('imageId');
  }

  public setImageId(id: UUID): void {
    this.setEmbeddedAttribute('imageId', id);
  }

  public getPlacard(): GrafitiPlacard {
    return this.placard$.getValue();
  }

  public getAddedPlacardType(): GrafitiPlacardType {
    return this.addedType$.getValue();
  }

  public setPlacard(placard: GrafitiPlacard): void {
    this.placard$.next(placard);
  }

  public setAddedType(addedType: GrafitiPlacardType): void {
    this.addedType$.next(addedType);
  }

  public getLocationString(): string {
    return this.getPlacard().getLocation().getStringifiedAddress();
  }

  public getCoordinates(): string {
    return this.getPlacard().getLocation().getStringifiedPosition();
  }

  public getPredecessor(): GrafitiActivity {
    const abd = this.getPlacard().getActivitiesAscendingByDate();
    const idx = abd.findIndex((e) => e.getId() === this.getId());
    if (idx === 0) {
      return null;
    }
    return abd[idx - 1];
  }

  public getMotive(): string {
    return this.getAddedPlacardType()?.getMotive() ?? '';
  }
  public getMotive$(): Observable<string> {
    return this.getAddedPlacardType()?.getMotive$() ?? of('');
  }

  public isReported(): boolean {
    return this.getEmbeddedAttribute('reported');
  }

  public setReported(reported: boolean): void {
    this.setEmbeddedAttribute('reported', reported);
  }

  public isReported$(): Observable<boolean> {
    return this.getEmbeddedAttribute$('reported');
  }

  public setMultiplier(multiplier: number): void {
    this.multiplier$.next(multiplier);
  }

  public getMultiplier(): number {
    return this.multiplier$.getValue();
  }

  public getMultiplier$(): Observable<number> {
    return this.multiplier$;
  }

  protected destructor(): void {}

  public buildDto(): ActivityDto {
    const dto = super.buildDto() as ActivityDto;
    dto.type = this.getActivityType().getTypeName();
    dto.performingUser = this.getPerformingUser();
    dto.image = this.getImage()?.id;
    dto.placard = this.getPlacard()?.getId();
    dto.addedType = this.getAddedPlacardType()?.getId();
    dto.multiplier = this.getMultiplier();
    return dto;
  }

  public getLatestPlacardTypeBeforeAndIncludingMe(): GrafitiPlacardType {
    let activity: GrafitiActivity = this;
    while (!!activity) {
      let type = activity.getAddedPlacardType();
      if (!!type) {
        return type;
      }
      activity = activity.getPredecessor();
    }
    return null;
  }

  public matchesTextFilter(filter: string): boolean {
    return this.getEntireSearchString().includes(filter);
  }

  public matchesQueryFilters(queries: string): boolean {
    const parts = queries.split('&');
    const res = parts.map((p) => this.matchesQueryFilter(p));
    if (res.length === 0) {
      return false;
    }
    const reduced = res.reduce((p, c) => p && c);
    return reduced;
  }

  public matchesQueryFilter(query): boolean {
    const isrep = this.isReported();
    if (query === 'reported=true') {
      return isrep === true;
    } else if (query === 'reported=false') {
      return isrep === false;
    }
    return false;
  }

  private getEntireSearchString(): string {
    const activityType = this.getActivityType();
    const placardType = this.getAddedPlacardType();
    const location = this.getPlacard().getLocation();

    const str = [
      activityType.getTypeName(),
      activityType.getDescription(),
      placardType?.getName(),
      placardType?.getMotive(),
      placardType?.getMaterial(),
      location.getAllAddressPropertiesAsString(),
    ]
      .filter((e) => !!e)
      .join(',')
      .toLowerCase();
    return str;
  }

  public hasPermission(permission: GrafitiPermission): boolean {
    return this.getPlacard().hasPermission(forContentDefault(permission));
  }

  public hasPermission$(permission: GrafitiPermission): Observable<boolean> {
    return this.getPlacard().hasPermission$(forContentDefault(permission));
  }

  public getSortString(column: string): string {
    switch (column) {
      case 'date':
        return this.getCreatedDate().toISOString();
      case 'action':
        return this.getActivityType().getDescription();
      case 'area':
        return this.getPlacard().getLocation().getAddress().district ?? '<N/A>';
      case 'type':
        return this.getAddedPlacardType()?.getMotive() ?? '<N/A>';
      default:
        return '';
    }
  }
}
