import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { faExclamationTriangle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { interval, Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

export interface EnterTextDialogData {
  title: string;
  text: string;
  /**
   * Translate Parameters applied to ALL passed texts (title, text, okText, abortText)
   */
  translateParams?: { [key: string]: unknown };
  okText?: string;
  abortText?: string;

  defaultValue?: string;
}

@Component({
  selector: 'grafiti-enter-text-dialog',
  templateUrl: 'enter-text-dialog.component.html',
  styleUrls: ['enter-text-dialog.component.scss'],
})
export class EnterTextDialogComponent {
  protected readonly enterTextGroup: FormGroup;
  /**
   * Fontawesome icons
   */
  public readonly faExclamationTriangle = faExclamationTriangle;
  public readonly faTimes = faTimes;

  public buttonDisabled = true;

  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: EnterTextDialogData,
    private readonly dialogRef: MatDialogRef<EnterTextDialogComponent>,
    private readonly formBuilder: FormBuilder
  ) {
    this.enterTextGroup = formBuilder.group({
      text: ['', [Validators.required]],
    });

    this.data.okText ??= 'Ok';
    this.data.abortText ??= 'Abbrechen';
    if (this.data.defaultValue) {
      this.enterTextGroup.get('text').setValue(this.data.defaultValue);
      this.buttonDisabled = false;
    }

    this.enterTextGroup.get('text').valueChanges.subscribe(() => {
      const val = this.enterTextGroup.get('text').value;
      this.buttonDisabled = !val;
    });
  }

  public getText(): string {
    return this.enterTextGroup.get('text').value;
  }

  public submit(): void {
    this.dialogRef.close(this.getText());
  }
}
