import { HttpClient, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { AbstractRestService } from './abstract-rest.service';
import { EntityDto } from '../dto/entity.dto';
import { finalize, Observable, of } from 'rxjs';
import { UUID } from '../util/math/uuid';
import { catchError, map, tap } from 'rxjs/operators';
import { CanFail } from '../util/can-fail';
import { StaticInject } from '../util/lifecycle/static-injector';
import { LoadingService } from '../shared-ui/loading.service';

export abstract class EntityController<
  Dto extends EntityDto,
  Sparse extends Omit<EntityDto, 'content'> = never
> extends AbstractRestService {
  @StaticInject(LoadingService)
  private loadingService: LoadingService;

  private supportSize = new Map<string, boolean>();

  // partial will do for now
  protected constructor(protected readonly http: HttpClient, endpoint: string) {
    super(http, endpoint);
  }

  public getAll(): Observable<Dto[]> {
    const url = this.getUrl('');
    const progressKey = url + '.getAll()';
    this.estimate(url, progressKey);
    return this.http
      .get<Dto[]>(this.getUrl(''))
      .pipe(finalize(() => this.loadingService.endLoading(this.getUrl('') + '.getAll()')));
  }

  public getById(id: UUID, sparse = false): Observable<Dto> {
    const url = this.getUrl('/' + id + (sparse ? '?sparse=true' : '')).replace('/?', '?');
    this.estimate(this.getUrl(`/${id}`), url);
    return this.http.get<Dto>(url).pipe(
      /*map((res) => ({
        ok: res,
        error: null,
      })),*/
      /*catchError((err: HttpErrorResponse) => {
        return of({
          ok: null,
          error: err,
        } as CanFail<Dto>);
      }),*/
      finalize(() => this.loadingService.endLoading(url))
    );
  }

  public getAllSparse(): Observable<Sparse[]> {
    return this.http.get<Sparse[]>(this.getUrl('?sparse=true').replace('/?', '?'));
  }

  public getWhereKeyIsValue(key: string, value: string): Observable<Dto[]> {
    return this.http.get<Dto[]>(this.getUrl(`/?${key}=${value}`));
  }

  protected estimate(url: string, progressKey: string) {
    this.loadingService.startLoading(progressKey);
    if (this.supportSize.has(url)) {
      this.http.get<number>(url + '/_estimate').subscribe((estimate) => {
        this.loadingService.setExpectedRemainingTime(progressKey, estimate);
      });
    } else {
      this.http
        .get<number>(url + '/_estimate')
        .pipe(
          catchError((err) => {
            return of(-1);
          })
        )
        .subscribe((estimate) => {
          if (estimate >= 0) {
            this.supportSize.set(url, true);
            this.loadingService.setExpectedRemainingTime(progressKey, estimate);
          } else {
            this.supportSize.set(url, false);
          }
        });
    }
  }
}
