import { combineLatest, Observable } from 'rxjs';
import { HasName } from '../interface/has-name';
import { HasDescription } from '../interface/has-description';
import { CommunityDto } from '../../dto/community.dto';
import { generateSeededColor } from '../../util/color';
import { hash } from '../../util/hash';
import { GrafitiProject } from './grafiti-project';
import { ValueSubject } from '../../util/reactive/value-subject';
import { GrafitiRoleAssignmentHolder } from './grafiti-role-assignment-holder';
import { Searchable } from './searchable';
import { ManagedEntity } from '../relink/managed-entity';
import { InviteLinkDto } from '../../dto/invite-link.dto';
import { SparseCommunityDto } from '../../dto/sparse-community.dto';
import { isContentDto } from '../../dto/content.dto';
import { CommunityContentDto } from '../../dto/community-content.dto';
import { map, switchMap } from 'rxjs/operators';
import { comparingString } from '../../util/comparator';

export class GrafitiCommunity extends GrafitiRoleAssignmentHolder implements HasName, HasDescription, Searchable {
  private readonly name$: ValueSubject<string>;
  private readonly description$: ValueSubject<string>;
  private readonly projects$: ValueSubject<GrafitiProject[]>;
  private readonly inviteLinks$: ValueSubject<InviteLinkDto[]>;

  public constructor(dto: SparseCommunityDto) {
    super(dto);

    const fullDto = dto as CommunityDto;

    this.name$ = new ValueSubject<string>(dto.name);
    this.description$ = new ValueSubject<string>(dto.description);
    this.projects$ = new ValueSubject<GrafitiProject[]>(
      fullDto.content?.projects.map((p) => new GrafitiProject(p, this)) ?? []
    );
    this.inviteLinks$ = new ValueSubject<InviteLinkDto[]>([]);

    this.ready();
  }

  public override update(dto: CommunityDto) {
    super.update(dto);

    this.setName(dto.name);
    this.setDescription(dto.description);
    if (isContentDto<CommunityContentDto>(dto)) {
      this.setProjects(
        ManagedEntity.updateArray({
          current: this.getProjects(),
          updatedDtos: dto.content.projects,
          createFn: (d) => new GrafitiProject(d, this),
        })
      );
    }
  }

  public getProjects(): GrafitiProject[] {
    return this.projects$.getValue();
  }
  public getProjects$(): Observable<GrafitiProject[]> {
    return this.projects$.asObservable();
  }

  public getProjectsOrderedByName$(): Observable<GrafitiProject[]> {
    return this.projects$.pipe(
      switchMap((projects) => combineLatest(projects.map((project) => project.getName$()))),
      map(() => [...this.getProjects()].sort(comparingString((e) => e.getName())))
    );
  }

  public setProjects(projects: GrafitiProject[]) {
    this.projects$.next(projects);
  }

  protected destructor(): void {}

  public matchesQueryFilters(queries: string): boolean {
    return false;
  }

  public matchesTextFilter(filter: string): boolean {
    return [this.getName().toLowerCase(), this.getDescription().toLowerCase()].join(';').includes(filter);
  }

  public buildDto(): CommunityDto {
    return {
      ...super.buildDto(),
      description: this.getDescription(),
      name: this.getName(),
      roleAssignments: this.getRoleAssignments().map((ra) => ra.buildDto()),
      projectIds: this.getProjects().map((p) => p.getId()),
      content: {
        projects: this.getProjects().map((p) => p.buildDto()),
      },
    };
  }

  public getArchives(): string[] {
    // return ['Wahlkreisdaten - BTW', 'Wahlkreisdaten LTW-BW', 'DFS-KA', 'Keine-Werbung-Verteilung'];
    return ['(Platzhalter)'];
  }

  /**
   *
   * ==============================
   *  ONLY DEFAULT IMPLEMENTATIONS
   * ==============================
   *
   */

  public getName(): string {
    return this.name$.getValue();
  }

  public getName$(): Observable<string> {
    return this.name$.asObservable();
  }

  public setName(name: string): void {
    this.name$.next(name);
  }

  getDescription(): string {
    return this.description$.getValue();
  }

  getDescription$(): Observable<string> {
    return this.description$.asObservable();
  }

  setDescription(description: string): void {
    this.description$.next(description);
  }

  public get name(): string {
    return this.getName();
  }

  public get description(): string {
    return this.getDescription();
  }

  public getBackgroundColor(): string {
    return generateSeededColor(hash(this.getId()), 1);
  }

  public setInviteLinks(links: InviteLinkDto[]): void {
    this.inviteLinks$.next(links);
  }

  public getInviteLinks(): InviteLinkDto[] {
    return this.inviteLinks$.getValue();
  }

  public getInviteLinks$(): Observable<InviteLinkDto[]> {
    return this.inviteLinks$.asObservable();
  }
}
