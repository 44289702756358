import { UUID } from '../../util/math/uuid';
import { ValueSubject } from '../../util/reactive/value-subject';
import { SimpleCoords } from '../../dto/simple-coords';
import { combineLatest, Observable } from 'rxjs';
import { ResponsibilityAreaDto } from '../../dto/responsibility-area.dto';
import { ProjectEntity } from './project-entity';
import { GrafitiProject } from './grafiti-project';
import { ResponsibilityLinkDto } from '../../dto/responsibility-link.dto';
import { Feature, polygon, Polygon } from '@turf/turf';
import { map, tap } from 'rxjs/operators';
import { forContentDefault, GrafitiPermission } from '../../dto/permission';
import { StyleDto } from '../../dto/style.dto';
import { removeNull } from '../../util/object';
import { Searchable } from './searchable';
import { GeoMath } from '../../util/math/geomath';
import { comparingNumber } from '../../util/comparator';
import { GrafitiNote } from './grafiti-note';
import { NotesHolder } from '../interface/notes-holder';
import { GrafitiRoleAssignmentHolder } from './grafiti-role-assignment-holder';

export class GrafitiResponsibilityArea
  extends GrafitiRoleAssignmentHolder
  implements ProjectEntity, Searchable, NotesHolder
{
  public static readonly DEFAULT_STYLE: StyleDto = {
    weight: 3,
    color: 'rgba(123, 31, 162, 0.8)',
    fillColor: 'rgba(105, 240, 174, 0.3)',
  };
  private readonly bounds$: ValueSubject<SimpleCoords[]>;
  private readonly turfPolygon$: Observable<Feature<Polygon>>;
  private readonly style$: ValueSubject<StyleDto>;
  private readonly name$: ValueSubject<string>;
  private readonly responsibilityLinkIds$: ValueSubject<UUID[]>;
  private readonly project: GrafitiProject;
  private readonly notes$: ValueSubject<GrafitiNote[]>;

  public constructor(dto: ResponsibilityAreaDto, project: GrafitiProject) {
    super(dto);

    this.bounds$ = new ValueSubject<SimpleCoords[]>(GeoMath.ensureOpenRing(dto.area[0]), this.destroy$);
    this.turfPolygon$ = this.bounds$.pipe(
      map((bds) => {
        const ring = bds.map((bd) => [bd.lng, bd.lat]);
        const repeatFirst = ring[0];
        return polygon([[...ring, repeatFirst]]);
      }),
      tap((a) => a)
    );
    this.style$ = new ValueSubject<StyleDto>(
      removeNull(dto.style) ?? GrafitiResponsibilityArea.DEFAULT_STYLE,
      this.destroy$
    );
    this.name$ = new ValueSubject<string>(dto.name, this.destroy$);
    this.responsibilityLinkIds$ = new ValueSubject<UUID[]>(dto.linkIds, this.destroy$);
    this.notes$ = new ValueSubject<GrafitiNote[]>([], this.destroy$);
    this.setNotes(dto.notes.map((note) => new GrafitiNote(note)));
    this.project = project;

    this.ready();
  }

  public getProject(): GrafitiProject {
    return this.project;
  }

  protected destructor(): void {}

  public getBounds(): SimpleCoords[] {
    return this.bounds$.getValue();
  }

  public getBoundingBox(): { top: number; left: number; bottom: number; right: number } {
    const top = this.getBounds().max((e) => e.lat).lat;
    const bottom = this.getBounds().min((e) => e.lat).lat;
    const left = this.getBounds().min((e) => e.lng).lng;
    const right = this.getBounds().max((e) => e.lng).lng;
    return {
      left,
      right,
      bottom,
      top,
    };
  }

  public getBounds$(): Observable<SimpleCoords[]> {
    return this.bounds$.asObservable();
  }

  public setBounds(bounds: SimpleCoords[]): void {
    this.bounds$.next(GeoMath.ensureOpenRing(bounds));
  }

  public getName(): string {
    return this.name$.getValue();
  }

  public getName$(): Observable<string> {
    return this.name$.asObservable();
  }

  public setName(name: string): void {
    this.name$.next(name);
  }

  public getStyle(): StyleDto {
    return this.style$.getValue();
  }

  public getStyle$(): Observable<StyleDto> {
    return this.style$.asObservable();
  }

  public setStyle(style: StyleDto): void {
    this.style$.next(style);
  }

  public getResponsibilityLinkIds(): UUID[] {
    return this.responsibilityLinkIds$.getValue();
  }

  public getResponsibilityLinks(): ResponsibilityLinkDto[] {
    return this.project.getResponsibilityLinks().filter((link) => link.areaId === this.getId());
  }

  public getResponsibilityLinkIds$(): Observable<UUID[]> {
    return this.responsibilityLinkIds$;
  }

  public setResponsibilityLinkIds(ids: UUID[]): void {
    this.responsibilityLinkIds$.next(ids);
  }

  public getNotes(): GrafitiNote[] {
    return this.notes$.getValue();
  }

  public getNotes$(): Observable<GrafitiNote[]> {
    return this.notes$;
  }

  public setNotes(notes: GrafitiNote[]): void {
    this.notes$.next([...notes].sort(comparingNumber((e) => e.getNoteIndex())));
  }

  public getTurfPolygon(): Feature<Polygon> {
    return this.turfPolygon$.immediate();
  }

  public override buildDto(): ResponsibilityAreaDto {
    return {
      ...super.buildDto(),
      area: [this.getBounds()],
      projectId: this.project.getId(),
      style: this.getStyle(),
      name: this.getName(),
      linkIds: this.getResponsibilityLinkIds(),
      notes: [],
    };
  }

  public get name(): string {
    return this.getName();
  }

  public hasPermission(permission: GrafitiPermission): boolean {
    if (this.anyRoleProvidesPermission(permission)) {
      return true;
    }
    return this.getProject().hasPermission(forContentDefault(permission));
  }

  public hasPermission$(permission: GrafitiPermission): Observable<boolean> {
    return combineLatest([
      this.anyRoleProvidesPermission$(permission),
      this.getProject().hasPermission$(permission),
    ]).pipe(map(([myRes, superRes]) => myRes || superRes));
  }

  public matchesQueryFilters(queries: string): boolean {
    return false;
  }

  public matchesTextFilter(filter: string): boolean {
    return this.getName().includes(filter);
  }
}
