<mat-card class="grpanel">
  <mat-card-title>
    <h1 id="title" class="tw-font-bold">Passwort vergessen</h1>
  </mat-card-title>
  <mat-card-subtitle>
    Geben Sie Ihre eMail an, wir senden Ihnen einen Link zum Zurücksetzen des Passworts
  </mat-card-subtitle>

  <form
    (ngSubmit)="requestPass()"
    #requestPassForm="ngForm"
    aria-labelledby="title"
    class="tw-flex tw-flex-col tw-mb-6">
    <mat-form-field>
      <mat-label>eMail-Addresse eingeben</mat-label>
      <input
        matInput
        [(ngModel)]="user.email"
        #email="ngModel"
        id="input-email"
        name="email"
        pattern=".+@.+\..+"
        placeholder="Email address"
        autofocus
        [required]="true"
        [attr.aria-invalid]="email.invalid && email.touched ? true : null" />
    </mat-form-field>
    <ng-container *ngIf="email.invalid && email.touched">
      <mat-error *ngIf="email.errors?.required">eMail-Addresse ist notwendig!</mat-error>
      <mat-error *ngIf="email.errors?.pattern">Keine gültige eMail-Addresse!</mat-error>
    </ng-container>

    <button mat-raised-button color="accent" [disabled]="submitted || !requestPassForm.valid">
      Reset-Code anfordern
    </button>
  </form>

  <section class="sign-in-or-up" aria-label="Sign in or sign up">
    <p><a class="text-link" routerLink="../login">Stattdessen einloggen</a></p>
    <p><a routerLink="../register" class="text-link">Registrieren</a></p>
  </section>
</mat-card>
