import { Delta } from '../delta';
import { DeltaType } from '../../../../util/delta-type.annotation';
import { ManagedEntity } from '../../../../model/relink/managed-entity';
import { NoteDto } from '../../../../dto/note.dto';
import { isNoteHolder, NotesHolder } from '../../../../model/interface/notes-holder';
import { GrafitiNote } from '../../../../model/entity/grafiti-note';
import { comparingNumber } from '../../../../util/comparator';
import { UUID } from '../../../../util/math/uuid';
import { CreateNoteDelta } from './create-note-delta';
import { JsonIgnore } from '../../../../util/json';

@DeltaType('deleteNote')
export class DeleteNoteDelta extends Delta {
  public noteId: UUID;

  @JsonIgnore()
  private deletedNote: NoteDto;

  public constructor(noteId: UUID) {
    super();
    this.noteId = this.noteId ?? noteId;
  }

  public apply(): void {
    const note = ManagedEntity.findFromIndex<GrafitiNote>(this.noteId);
    this.deletedNote = note.buildDto();
    if (!note) {
      return;
    }

    const holder = ManagedEntity.findFromIndex(note.getObjectId());
    if (!isNoteHolder(holder)) {
      return;
    }

    holder.setNotes(holder.getNotes().filter((n) => n.getId() !== this.noteId));
  }

  public onConfirmation(confirmationDelta: DeleteNoteDelta): void {}

  public override getUndoDelta(): Delta {
    return new CreateNoteDelta(this.deletedNote);
  }

  public override getErrorMessage(): string {
    return `Vermerk konnte nicht gelöscht werden!`;
  }
}
