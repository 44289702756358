import { SimpleCoords } from '../../dto/simple-coords';
import { GrafitiLocation } from '../../model/entity/grafiti-location';
import { deepEquals } from './deep-equals';
import { Feature, MultiPolygon, Polygon, point, polygon, multiPolygon } from '@turf/turf';

export const EARTH_RADIUS = 6371;
/**
 * Calculates the distance between 2 points on the earths surface "as the crow flies" using haversine formula.
 * @param pos1
 * @param pos2
 * @return the distance in km
 */
export function getDistanceBetweenPositions(pos1: SimpleCoords, pos2: SimpleCoords): number {
  const dLat = deg2rad(pos2.lat - pos1.lat);
  const dLon = deg2rad(pos2.lng - pos1.lng);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(pos1.lat)) * Math.cos(deg2rad(pos2.lat)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return EARTH_RADIUS * c;
}

export function deg2rad(degrees: number): number {
  const pi = Math.PI;
  return degrees * (pi / 180);
}

export function getNearestLocations(
  myLocation: SimpleCoords,
  locations: GrafitiLocation[],
  amount: number
): NearestLocationResult[] {
  locations.sort((a, b) => {
    return (
      getDistanceBetweenPositions(myLocation, a.getPosition()) -
      getDistanceBetweenPositions(myLocation, b.getPosition())
    );
  });

  return locations.slice(0, amount).map((location) => {
    return {
      distance: getDistanceBetweenPositions(myLocation, location.getPosition()),
      location: location,
    };
  });
}

export function getNextCoordIndex(coordsList: SimpleCoords[], givenCoord: SimpleCoords): number {
  let minDist = Number.MAX_VALUE;
  let nextIndex = 0;

  for (let i = 0; i < coordsList.length; i++) {
    const dist = getDistanceBetweenPositions(coordsList[i], givenCoord);
    if (dist < minDist) {
      minDist = dist;
      nextIndex = i;
    }
  }

  return nextIndex;
}

export interface NearestLocationResult {
  location: GrafitiLocation;
  distance: number;
}

export function accessLatLngAltArray(array: number[], index: number): { lat: number; lng: number; alt: number } {
  const baseIdx = Math.floor(index / 3);
  const coords = {
    lat: array[baseIdx],
    lng: array[baseIdx + 1],
    alt: array[baseIdx + 2],
  };
  return coords;
}

export function latLngAltToSimpleCoords(arr: number[]): SimpleCoords[] {
  const ret: SimpleCoords[] = [];
  for (let i = 0; i < arr.length; i = i + 3) {
    ret.push({
      lat: arr[i],
      lng: arr[i + 1],
    });
  }
  return ret;
}

export function getMiddleCoords(coords1: SimpleCoords, coords2: SimpleCoords): SimpleCoords {
  const lat1 = coords1.lat;
  const lng1 = coords1.lng;
  const lat2 = coords2.lat;
  const lng2 = coords2.lng;

  const lat = (lat1 + lat2) / 2;
  const lng = (lng1 + lng2) / 2;

  return { lat, lng };
}

export function getMiddleCoordsListForPolygon(coordsList: SimpleCoords[]): SimpleCoords[] {
  const result: SimpleCoords[] = [];
  const numCoords = coordsList.length;

  for (let i = 0; i < numCoords; i++) {
    const coords1 = coordsList[i];
    const coords2 = i < numCoords - 1 ? coordsList[i + 1] : coordsList[0];
    const middleCoords = getMiddleCoords(coords1, coords2);
    result.push(middleCoords);
  }

  return result;
}

export function getMiddleCoordsList(coordsList: SimpleCoords[]): SimpleCoords[] {
  const result: SimpleCoords[] = [];
  const numCoords = coordsList.length;

  for (let i = 0; i < numCoords - 1; i++) {
    const coords1 = coordsList[i];
    const coords2 = coordsList[i + 1];
    const middleCoords = getMiddleCoords(coords1, coords2);
    result.push(middleCoords);
  }

  return result;
}

export function getMiddleCoordsGP(coords1: SimpleCoords, coords2: SimpleCoords): SimpleCoords {
  const lat1 = coords1.lat;
  const lng1 = coords1.lng;
  const lat2 = coords2.lat;
  const lng2 = coords2.lng;

  const lat = (lat1 + lat2) / 2;
  const lng = (lng1 + lng2) / 2;

  return <SimpleCoords>{ lat, lng };
}

export function getMiddleCoordsListGP(coordsList: SimpleCoords[]): SimpleCoords[] {
  const result: SimpleCoords[] = [];
  const numCoords = coordsList.length;

  for (let i = 0; i < numCoords; i++) {
    const coords1 = coordsList[i];
    const coords2 = i < numCoords - 1 ? coordsList[i + 1] : coordsList[0];
    const middleCoords = getMiddleCoordsGP(coords1, coords2);
    result.push(middleCoords);
  }

  return result;
}

/**
 * Berechnet ein Quadrat um die gegebenen Koordinate herum
 * @param center
 * @param d kantenlänge des quadrats in Metern
 */
export function createSquareCoords(center: SimpleCoords, d: number): SimpleCoords[] {
  const R = 6371e3; // Radius der Erde in Metern

  // Konvertierung der Koordinate in Radiant
  const lat = (center.lat * Math.PI) / 180;
  const lng = (center.lng * Math.PI) / 180;

  // Berechnung der Koordinaten des Quadrats
  const lat1 = Math.asin(Math.sin(lat) * Math.cos(d / R) + Math.cos(lat) * Math.sin(d / R) * Math.cos(0));
  const lng1 =
    lng + Math.atan2(Math.sin(0) * Math.sin(d / R) * Math.cos(lat), Math.cos(d / R) - Math.sin(lat) * Math.sin(lat1));
  const lat2 = Math.asin(Math.sin(lat) * Math.cos(d / R) + Math.cos(lat) * Math.sin(d / R) * Math.cos(Math.PI / 2));
  const lng2 =
    lng +
    Math.atan2(
      Math.sin(Math.PI / 2) * Math.sin(d / R) * Math.cos(lat),
      Math.cos(d / R) - Math.sin(lat) * Math.sin(lat2)
    );
  const lat3 = Math.asin(Math.sin(lat) * Math.cos(d / R) + Math.cos(lat) * Math.sin(d / R) * Math.cos(Math.PI));
  const lng3 =
    lng +
    Math.atan2(Math.sin(Math.PI) * Math.sin(d / R) * Math.cos(lat), Math.cos(d / R) - Math.sin(lat) * Math.sin(lat3));
  const lat4 = Math.asin(
    Math.sin(lat) * Math.cos(d / R) + Math.cos(lat) * Math.sin(d / R) * Math.cos((3 * Math.PI) / 2)
  );
  const lng4 =
    lng +
    Math.atan2(
      Math.sin((3 * Math.PI) / 2) * Math.sin(d / R) * Math.cos(lat),
      Math.cos(d / R) - Math.sin(lat) * Math.sin(lat4)
    );

  // Konvertierung der Koordinaten zurück in Grad
  const coord1: SimpleCoords = { lat: (lat1 * 180) / Math.PI, lng: (lng1 * 180) / Math.PI };
  const coord2: SimpleCoords = { lat: (lat2 * 180) / Math.PI, lng: (lng2 * 180) / Math.PI };
  const coord3: SimpleCoords = { lat: (lat3 * 180) / Math.PI, lng: (lng3 * 180) / Math.PI };
  const coord4: SimpleCoords = { lat: (lat4 * 180) / Math.PI, lng: (lng4 * 180) / Math.PI };

  return [coord1, coord2, coord3, coord4];
}

export function createEastWestCoords(center: SimpleCoords, d: number): SimpleCoords[] {
  const distanceInDegrees = d / (111 * 1000); // 1 degree is approximately 111km
  const east = {
    lat: center.lat,
    lng: center.lng + distanceInDegrees,
  };
  const west = {
    lat: center.lat,
    lng: center.lng - distanceInDegrees,
  };
  return [east, west];
}
export function findCenterCoordinates(coordinates: SimpleCoords[]): SimpleCoords {
  if (coordinates.length === 0) {
    throw new Error('Die Koordinatenliste ist leer.');
  }

  const totalLatitude = coordinates.reduce((sum, coord) => sum + coord.lat, 0);
  const totalLongitude = coordinates.reduce((sum, coord) => sum + coord.lng, 0);

  const centerLatitude = totalLatitude / coordinates.length;
  const centerLongitude = totalLongitude / coordinates.length;

  return { lat: centerLatitude, lng: centerLongitude };
}

export function sequentialDistance(waypoints: SimpleCoords[]): number {
  let distance = 0;
  for (let i = 0; i < waypoints.length - 1; i++) {
    const current = waypoints[i];
    const next = waypoints[i + 1];
    const curDist = getDistanceBetweenPositions(current, next);
    distance += curDist;
  }
  return distance;
}

interface Edge {
  start: SimpleCoords;
  end: SimpleCoords;
}

interface Point {
  x: number;
  y: number;
}

export class GeoMath {
  private constructor() {}

  static ensureClosedRing(source: SimpleCoords[]): SimpleCoords[] {
    if (source.length === 0) {
      return [];
    }
    if (deepEquals(source[0], source.last())) {
      return [...source];
    }
    return [...source, source.first()];
  }

  static ensureOpenRing(source: SimpleCoords[]): SimpleCoords[] {
    if (source.length === 0) {
      return [];
    }
    if (deepEquals(source[0], source.last())) {
      return source.slice(0, source.length - 1);
    }

    return [...source];
  }

  static toXY(coords: SimpleCoords): { x: number; y: number } {
    return {
      x: coords.lat,
      y: coords.lng,
    };
  }

  static fromXY(point: { x: number; y: number }): SimpleCoords {
    return {
      lat: point.x,
      lng: point.y,
    };
  }

  static toEdges(bounds: SimpleCoords[]): Edge[] {
    const safeBounds = this.ensureClosedRing(bounds);
    const edges = safeBounds
      .map((el, i, arr) => [el, arr[i + 1]])
      .filter(([a, b]) => !!b)
      .map(([a, b]) => ({ start: a, end: b } as Edge));
    return edges;
  }

  static isVertical(edge: { start: SimpleCoords; end: SimpleCoords }, deviation = 45): boolean {
    const dx = edge.end.lat - edge.start.lat;
    const dy = edge.end.lng - edge.start.lng;

    const between = (num: number, lesser: number, bigger: number) => {
      return num >= lesser && num <= bigger;
    };

    // Berechnung des Winkels zwischen der Kante und der horizontalen Achse
    const angle = Math.atan2(dy, dx) * (180 / Math.PI);
    return between(angle, deviation * -1, deviation) || between(angle, 180 - deviation, 180 + deviation);
  }

  static scToTurfPolygon(bds: SimpleCoords[]) {
    const ring = bds.map((bd) => [bd.lng, bd.lat]);
    const repeatFirst = ring[0];
    return polygon([[...ring, repeatFirst]]);
  }

  static scToTurfMultiPolygon(bds2: SimpleCoords[][]) {
    const a = bds2.map((bds) => {
      const b = bds.map((bd) => {
        return [bd.lat, bd.lng];
      });
      return b;
    });
    return multiPolygon([a]);
  }

  static turfMultiPolygonToSc(polygon: Feature<Polygon | MultiPolygon>): [boolean, SimpleCoords[][][]] {
    if (polygon.geometry.type === 'MultiPolygon') {
      const a = polygon.geometry.coordinates.map((c3) => c3.map((c2) => c2.map(([lng, lat]) => ({ lat, lng }))));
      return [true, a];
    }
    return [false, [[this.turfPolygonToSc(polygon as Feature<Polygon>)]]];
  }

  static turfPolygonToSc(polygon: Feature<Polygon>): SimpleCoords[] {
    return polygon.geometry.coordinates[0].map(([lng, lat]) => ({ lat, lng }));
  }
}
