import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { LoadingService } from '../../../shared-ui/loading.service';
import { debounceTime, delay, map } from 'rxjs/operators';
import { SidebarService } from '../../sidebar.service';

@Component({
  selector: 'gr-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  @Input() showToggle = true;

  @Output() toggleLeftPanel = new EventEmitter<void>();
  @Output() toggleRightPanel = new EventEmitter<void>();
  protected readonly faRightFromBracket = faRightFromBracket;

  protected readonly leftOpen$ = this.sidebarService.left$;

  protected readonly loading$ = this.loadingService.getIsLoading$().pipe(debounceTime(500), delay(100));

  protected readonly progress$ = this.loadingService.getProgress$();
  protected readonly mode$ = this.loadingService
    .isDeterminate$()
    .pipe(map((d) => (d ? 'determinate' : 'indeterminate')));

  public constructor(
    private readonly loadingService: LoadingService,
    private readonly sidebarService: SidebarService
  ) {}
}
