import { equalsOf } from '../../util/equalsMany';
import { ManagedEntity } from '../relink/managed-entity';
import { LocationState } from '../../util/location-state';

// only used for repository
export interface ActivityTypeDto {
  /**
   * Equals typename
   */
  id: ActivityTypeName;
  order: number;
  description: string;
  juristicalDescription?: string;
}
export class ActivityType extends ManagedEntity {
  private jd: string = '';

  public constructor(typeName: ActivityTypeName, private readonly order: number, private readonly description: string) {
    super(typeName);
    this.ready();
  }

  public setJuristicalDescription(jd: string): void {
    this.jd = jd;
  }

  public getJursiticalDescription(): string {
    return this.jd ?? '';
  }

  public getOrder(): number {
    return this.order;
  }

  public getTypeName(): ActivityTypeName {
    return this.getId().toString() as ActivityTypeName;
  }

  public getDescription(): string {
    return this.description;
  }

  protected destructor(): void {}

  public firstInSequence() {
    return this.getTypeName() === 'AT_ATTACH';
  }

  public requireImage(): boolean {
    return equalsOf<ActivityTypeName>(
      this.getTypeName(),
      'AT_REATTACH',
      'AT_REPORT_MISSING',
      'AT_REPORT_DESTROYED',
      'AT_REPORT_FOUND'
    );
  }

  public allowImage(): boolean {
    return true;
  }

  public requirePlacardTypeAddition(): boolean {
    return equalsOf<ActivityTypeName>(this.getTypeName(), 'AT_REATTACH', 'AT_ATTACH');
  }
}

export type ActivityTypeName =
  | 'AT_ATTACH'
  | 'AT_REMOVE'
  | 'AT_REATTACH'
  | 'AT_REPAIR'
  | 'AT_REPORT_MISSING'
  | 'AT_REPORT_DESTROYED'
  | 'AT_REPORT_FOUND'
  | 'REPORT_ACTION_NEEDED'
  | 'OTHER'
  | 'NONE'
  | 'AT_OK';

export function getLocationStateForActivityType(type: ActivityTypeName): LocationState {
  switch (type) {
    case 'AT_ATTACH':
    case 'AT_REATTACH':
    case 'AT_REPAIR':
    case 'AT_OK':
      return 'OK';
    case 'REPORT_ACTION_NEEDED':
    case 'AT_REPORT_DESTROYED':
    case 'AT_REPORT_MISSING':
    case 'AT_REPORT_FOUND':
      return 'ACTION_NEEDED';
    case 'AT_REMOVE':
      return 'INACTIVE';
    default:
      return 'UNKOWN';
  }
}

export const ACTIVITY_TYPES: { [key in ActivityTypeName]: ActivityType } = {
  AT_ATTACH: new ActivityType('AT_ATTACH', 0, 'Aufhängung eintragen'),
  AT_REMOVE: new ActivityType('AT_REMOVE', -1, 'Entfernen eintragen'),
  AT_REATTACH: new ActivityType('AT_REATTACH', 0, 'Wiederaufhängung eintragen'),
  AT_REPAIR: new ActivityType('AT_REPAIR', 0, 'Reparatur eintragen'),
  AT_REPORT_MISSING: new ActivityType('AT_REPORT_MISSING', 10, 'Schwund melden'),
  AT_REPORT_DESTROYED: new ActivityType(
    'AT_REPORT_DESTROYED',
    10,
    'Herunterreißen und Wegnehmen von Plakaten, Diebstahl i.S.d. § 242 StGB'
  ),
  AT_REPORT_FOUND: new ActivityType('AT_REPORT_FOUND', 0, 'Fund melden'),
  REPORT_ACTION_NEEDED: new ActivityType('REPORT_ACTION_NEEDED', 10, 'Hilfeersuchen'),
  OTHER: new ActivityType('OTHER', 1, 'Sonstiges'),
  NONE: new ActivityType('NONE', -2, 'Nichts'),
  AT_OK: new ActivityType('AT_OK', 0, 'Zustand in Ordnung'),
};

export const ACTIVITY_TYPES_LIST = Object.values(ACTIVITY_TYPES);
