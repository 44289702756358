import { NgModule, Type } from '@angular/core';
import { WebSocketService } from './websocket/web-socket.service';
import { DeltaService } from './editing/delta.service';
import { ApplicationInitService } from './application-init.service';
import { AuthenticationService } from '../auth/authentication.service';
import { distinctUntilChanged, filter, first, switchMap, tap } from 'rxjs/operators';
import { Delta } from './editing/delta/delta';
import { CreateActivityDelta } from './editing/delta/activity/create-activity-delta';
import { DeleteActivityDelta } from './editing/delta/activity/delete-activity-delta';
import { CreateFlyerRunDelta } from './editing/delta/flyer-run/create-flyer-run-delta';
import { EditFlyerRunDelta } from './editing/delta/flyer-run/edit-flyer-run.delta';
import { DeleteFlyerRunDelta } from './editing/delta/flyer-run/delete-flyer-run-delta';
import { CreateFlyerTypeDelta } from './editing/delta/flyer-type/create-flyer-type-delta';
import { EditFlyerTypeDelta } from './editing/delta/flyer-type/edit-flyer-type-delta';
import { UpdateFlyerRunTypeDelta } from './editing/delta/flyer-run/update-flyer-run-type';
import { DeleteFlyerTypeDelta } from './editing/delta/flyer-type/delete-flyer-type-delta';
import { CreateInteractionDelta } from './editing/delta/interaction/create-interaction.delta';
import { ChangeInteractionStateDelta } from './editing/delta/interaction/change-interaction-state.delta';
import { CreateLocationDelta } from './editing/delta/location/create-location.delta';
import { EditLocationDelta } from './editing/delta/location/edit-location.delta';
import { DeleteLocationDelta } from './editing/delta/location/delete-location.delta';
import { AddPermissionDelta } from './editing/delta/permission/add-permission.delta';
import { EditPermissionDelta } from './editing/delta/permission/edit-permission.delta';
import { RemovePermissionDelta } from './editing/delta/permission/remove-permission.delta';
import { CreatePlacardDelta } from './editing/delta/placard/create-placard-delta';
import { DeletePlacardDelta } from './editing/delta/placard/delete-placard-delta';
import { CreatePlacardTypeDelta } from './editing/delta/placard-type/create-placard-type-delta';
import { EditPlacardTypeDelta } from './editing/delta/placard-type/edit-placard-type-delta';
import { DeletePlacardTypeDelta } from './editing/delta/placard-type/delete-placard-type-delta';
import { CreateCommunityDelta } from './editing/delta/community/create-community-delta';
import { EditCommunityDelta } from './editing/delta/community/edit-community-delta';
import { DeleteCommunityDelta } from './editing/delta/community/delete-community-delta';
import { CreateProjectDelta } from './editing/delta/project/create-project-delta';
import { EditProjectDelta } from './editing/delta/project/edit-project-delta';
import { DeleteProjectDelta } from './editing/delta/project/delete-project-delta';
import { EditUserDelta } from './editing/delta/user/edit-user.delta';
import { CreateUserDelta } from './editing/delta/user/create-user.delta';
import { DeleteUserDelta } from './editing/delta/user/delete-user.delta';
import { UserSettingDelta } from './editing/delta/user/user-setting.delta';
import { CommonTopicsService } from './common-topics.service';
import { EditFlyerRunPropertiesDelta } from './editing/delta/flyer-run/edit-flyer-run-properties.delta';
import { CreateInvitelinkDelta } from './editing/delta/invitelink/create-invitelink.delta';
import { DeleteInvitelinkDelta } from './editing/delta/invitelink/delete-invitelink.delta';
import { CreateRepsonsibilityAreaDelta } from './editing/delta/responsibility-area/create-responsibility-area-delta';
import { DeleteResponsibilityAreaDelta } from './editing/delta/responsibility-area/delete-responsibility-area-delta';
import { EditResponsibilityAreaDelta } from './editing/delta/responsibility-area/edit-responsibility-area-delta';
import { CreateRepsonsibilityLinkDelta } from './editing/delta/responsibility-link/create-responsibility-link.delta';
import { DeleteResponsibilityLinkDelta } from './editing/delta/responsibility-link/delete-responsibility-link-delta';
import { UpdateActivityImageDelta } from './editing/delta/activity/update-activity-image.delta';
import { LoggingInterceptor } from './logging.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Constructable } from '../util/lifecycle/constructable';
import { AbstractRestService } from './abstract-rest.service';
import { UpdateMultiplierDelta } from './editing/delta/activity/update-multiplier.delta';
import { DeleteNoteDelta } from './editing/delta/note/delete-note-delta';

@NgModule({
  imports: [],

  providers: [
    WebSocketService,
    DeltaService,
    ApplicationInitService,
    CommonTopicsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoggingInterceptor,
      multi: true,
    },
  ],
})
export class CommunicationModule {
  private readonly deltas: Type<Delta>[] = [
    CreateActivityDelta,
    DeleteActivityDelta,
    CreateFlyerRunDelta,
    EditFlyerRunDelta,
    DeleteFlyerRunDelta,
    CreateFlyerTypeDelta,
    EditFlyerTypeDelta,
    UpdateFlyerRunTypeDelta,
    DeleteFlyerTypeDelta,
    CreateInteractionDelta,
    ChangeInteractionStateDelta,
    CreateLocationDelta,
    EditLocationDelta,
    DeleteLocationDelta,
    AddPermissionDelta,
    EditPermissionDelta,
    RemovePermissionDelta,
    CreatePlacardDelta,
    DeletePlacardDelta,
    CreatePlacardTypeDelta,
    EditPlacardTypeDelta,
    DeletePlacardTypeDelta,
    CreateCommunityDelta,
    EditCommunityDelta,
    DeleteCommunityDelta,
    CreateProjectDelta,
    EditProjectDelta,
    DeleteProjectDelta,
    CreateUserDelta,
    EditUserDelta,
    DeleteUserDelta,
    UserSettingDelta,
    EditFlyerRunPropertiesDelta,
    CreateInvitelinkDelta,
    DeleteInvitelinkDelta,
    CreateRepsonsibilityAreaDelta,
    DeleteResponsibilityAreaDelta,
    EditResponsibilityAreaDelta,
    CreateRepsonsibilityLinkDelta,
    DeleteResponsibilityLinkDelta,
    UpdateActivityImageDelta,
    UpdateMultiplierDelta,
    DeleteNoteDelta,
  ];
  public constructor(
    private init: ApplicationInitService,
    private readonly authService: AuthenticationService,
    private readonly websocket: WebSocketService
  ) {
    this.authService
      .getLoggedIn$()
      .pipe(
        distinctUntilChanged(),
        filter((e) => e),
        switchMap(() => this.init.activate().pipe(first()))
      )
      .subscribe(() => {
        // this.websocket.activate();
      });

    const originalXhr: Constructable<XMLHttpRequest> = window['XMLHttpRequest'];

    window['XMLHttpRequest'] = CustomXHR;
  }
}

class CustomXHR extends XMLHttpRequest {
  public constructor() {
    super();
  }

  override open(method: string, url: string | URL) {
    if (url.toString().includes('1.base.maps.ls.')) {
      const safeUrl = new URL(url.toString());
      safeUrl.protocol = window.location.protocol;
      safeUrl.host = AbstractRestService.findDomain(false);
      safeUrl.pathname = '/proxy' + safeUrl.pathname;

      super.open(method, safeUrl);
    } else {
      super.open(method, url);
    }
  }
}
