import { Delta } from '../delta';
import { DeltaType } from '../../../../util/delta-type.annotation';
import { UUID } from '../../../../util/math/uuid';
import { PrivateUserDto } from '../../../../dto/private-user.dto';
import { CreateUserDelta } from './create-user.delta';
import { JsonIgnore } from '../../../../util/json';
import { StaticInject } from '../../../../util/lifecycle/static-injector';
import { Userspace } from '../../../../model/userspace.service';

@DeltaType('deleteUser')
export class DeleteUserDelta extends Delta {
  @JsonIgnore()
  @StaticInject(Userspace)
  private readonly userspace: Userspace;

  @JsonIgnore()
  private userDto: PrivateUserDto;

  public userId: UUID;

  public constructor(userId: UUID) {
    super();

    this.userId = this.userId ?? userId;
  }

  public apply(): void {
    const user = this.userspace.getUserStore().find(this.userId);
    this.userDto = user.buildDto();
    this.userspace.getUserStore().delete(user);
  }

  public override onConfirmation(confirmationDelta: Delta): void {}

  public override getUndoDelta(): Delta {
    return new CreateUserDelta(this.userDto);
  }

  public override getErrorMessage(): string {
    return `Benutzer konnte nicht gelöscht werden`;
  }
}
