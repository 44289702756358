import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GrafitiValidators } from '../../util/grafiti-validators';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'gr-request-password-page',
  templateUrl: './request-password.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GrRequestPasswordComponent {
  protected submitted = false;
  protected successful = false;
  protected user: { email: string } = { email: '' };
  public constructor(private readonly authController: AuthenticationService, private readonly router: Router) {}
  requestPass(): void {
    this.submitted = true;
    this.authController
      .requestPasswordReset(this.user.email)
      .pipe(
        catchError((err) => of(false)),
        map(() => true)
      )
      .subscribe((res) => {
        this.successful = res;
        this.router.navigate(['/auth/code-sent']);
      });
  }
}
