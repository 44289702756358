import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PrivateUserDto } from './dto/private-user.dto';
import { deepEquals } from './util/math/deep-equals';

/**
 * Represents a service to interact with the browser's
 * localStorage.
 */
@Injectable({
  providedIn: 'root', // TODO make own storage module
})
export class StorageService {
  private readonly currentUserKey = 'currentUser';
  private readonly sessionId = 'session';

  private readonly _currentUser$ = new BehaviorSubject<PrivateUserDto>(
    StorageService.getItem<PrivateUserDto>(this.currentUserKey)
  );

  private static hasItem(key: string): boolean {
    return !!localStorage.getItem(key);
  }

  private static getItem<T>(key: string): T {
    const value = localStorage.getItem(key);
    if (value) {
      return JSON.parse(value);
    }

    return null;
  }

  private static setItem<T>(key: string, value: T): void {
    if (value) {
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      localStorage.removeItem(key);
    }
  }

  /**
   * Returns the currently logged in user as observable.
   * Warning: This can return NULL immediately after logging out. If you don't handle that case, your code will probably fail and result in
   *          incomplete logout attempts!
   */
  public get currentUser$(): Observable<PrivateUserDto> {
    return this._currentUser$;
  }

  /**
   * Gets the user object that is currently stored in the browser's
   * localStorage.
   *
   * @return The user object if it exists, null otherwise.
   */
  public get currentUser(): PrivateUserDto {
    return this._currentUser$.value;
  }

  /**
   * Sets the current user object in the localStorage to the
   * given user or deletes it if the given value is null.
   *
   * Warning: This will cause side effects! Don't change the currentUser in a running application.
   *
   * @param value The new user object or null.
   */
  public setCurrentUser(value: PrivateUserDto): void {
    if (!deepEquals(this.currentUser, value, 4)) {
      StorageService.setItem(this.currentUserKey, value);
      this._currentUser$.next(value);
    }
  }

  /**
   * Checks if a user is stored in the browser's localStorage.
   *
   * @return True if a user object exists, false otherwise.
   */
  public hasCurrentUser(): boolean {
    return StorageService.hasItem(this.currentUserKey);
  }

  public getSessionId(): string {
    return StorageService.getItem(this.sessionId);
  }

  public setSessionId(sessionId: string): void {
    StorageService.setItem(this.sessionId, sessionId);
  }
}
