import { Delta } from '../delta';
import { PlacardDto } from '../../../../dto/placard.dto';
import { DeltaType } from '../../../../util/delta-type.annotation';
import { JsonIgnore } from '../../../../util/json';
import { DeletePlacardDelta } from './delete-placard-delta';
import { FromIndex } from '../../../../model/relink/managed-entity';
import { GrafitiLocation } from '../../../../model/entity/grafiti-location';
import { GrafitiPlacard } from '../../../../model/entity/grafiti-placard';

@DeltaType('createPlacard')
export class CreatePlacardDelta extends Delta {
  @JsonIgnore()
  @FromIndex<CreatePlacardDelta>((self) => self.placardDto.location)
  private location: GrafitiLocation;

  public placardDto: PlacardDto;

  public constructor(placardDto: PlacardDto) {
    super();
    this.placardDto = this.placardDto ?? placardDto;
  }

  public apply(): void {
    if (this.location.getPlacards().find((p) => p.getId() === this.placardDto.id)) {
      return;
    }
    const placard = new GrafitiPlacard(this.placardDto, this.location);
    this.location.setPlacards([...this.location.getPlacards(), placard]);
  }

  public onConfirmation(confirmationDelta: CreatePlacardDelta): void {}

  public override getUndoDelta(): Delta {
    return new DeletePlacardDelta(this.placardDto);
  }

  public override getErrorMessage(): string {
    return `Neues Plakat konnte nicht gespeichert werden!`;
  }
}
