import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

export class GrafitiValidators {
  public static nameCharsValidator(control: AbstractControl): ValidationErrors | null {
    const forbiddenChars: RegExp = /[!@#$%^&*()+=\[\]{};':"\\|,.<>\/?]/;
    const test = forbiddenChars.test(control.value);
    if (control.value && !test) {
      return null; // everything ok
    }
    return { invalidName: true };
  }

  public static usernameCharsValidator(control: AbstractControl): ValidationErrors | null {
    const allowedRegex: RegExp = /^[a-z][_\-a-z0-9]{2,}$/;
    const test = allowedRegex.test(control.value);
    if (control.value && test) {
      return null; // everything ok
    }
    return { pattern: true };
  }

  public static json(control: AbstractControl): ValidationErrors | null {
    try {
      JSON.parse(control.value);
      return null;
    } catch (jsonParseError) {
      return { jsonError: false };
    }
  }

  public static positiveNumberValidator(control: AbstractControl): ValidationErrors | null {
    const asNum = Number.parseFloat(control.value);
    if (Number.isNaN(asNum)) {
      return { notANumber: true };
    }
    if (asNum <= 0) {
      return { zeroOrLess: true };
    }
    return null;
  }
}

export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}

export const GrafitiNameValidators = [
  Validators.required,
  Validators.minLength(2),
  GrafitiValidators.nameCharsValidator,
];

export function CustomGrafitiNameValidators(minl: number, maxl: number) {
  return [
    Validators.required,
    Validators.minLength(minl),
    Validators.maxLength(maxl),
    GrafitiValidators.nameCharsValidator,
  ];
}

export const GrafitiUsernameValidators = [
  Validators.required,
  Validators.minLength(2),
  GrafitiValidators.nameCharsValidator,
];

export function ConfirmedValidator(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
      return;
    }
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ confirmedValidator: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}
