import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private readonly loadingOperations$ = new BehaviorSubject<string[]>([]);
  private readonly isLoading$ = this.loadingOperations$.pipe(map((ops) => ops.length > 0));

  private readonly tracker = new TaskTracker();
  private readonly progress$ = this.tracker.getProgress();
  private readonly determinate$ = this.tracker.isActive$();

  public constructor() {}

  public getIsLoading$(): Observable<boolean> {
    return this.isLoading$;
  }

  public startLoading(key: string): void {
    const keys = this.loadingOperations$.getValue();
    if (!keys.some((k) => k === key)) {
      this.loadingOperations$.next([...keys, key]);
    }
  }

  public setExpectedRemainingTime(key: string, ms: number): void {
    this.tracker.addTask(key, ms);
  }

  public endLoading(key: string): void {
    const keys = this.loadingOperations$.getValue();
    const next = keys.filter((k) => k !== key);
    this.loadingOperations$.next(next);
  }

  public getProgress$(): Observable<number> {
    return this.progress$;
  }

  public isDeterminate$(): Observable<boolean> {
    return this.determinate$;
  }
}

import { Subject } from 'rxjs';

interface Task {
  key: string;
  expectedTime: number;
  completed: boolean;
  remainingTime: number;
}

class TaskTracker {
  private tasks: Map<string, Task> = new Map();
  private progress: Subject<number> = new Subject();
  private checker: any;
  private readonly active$ = new BehaviorSubject<boolean>(false);

  public constructor() {}

  public getProgress() {
    return this.progress.asObservable();
  }

  public addTask(key: string, expectedTime: number) {
    const task: Task = {
      key,
      expectedTime,
      completed: false,
      remainingTime: expectedTime,
    };
    this.tasks.set(key, task);
    this.updateProgress();
    if (!this.checker) {
      this.createChecker();
    }
  }

  public completeTask(key: string) {
    const task = this.tasks.get(key);
    if (task) {
      task.completed = true;
      task.remainingTime = 0;
      this.updateProgress();
      this.tasks.delete(key);
      if (this.tasks.size === 0 && this.checker) {
        clearInterval(this.checker);
        this.checker = null;
        this.active$.next(false);
      }
    }
  }

  public updateTaskRemainingTime(key: string, newRemainingTime: number) {
    const task = this.tasks.get(key);
    if (task) {
      task.remainingTime = newRemainingTime;
      this.updateProgress();
    }
  }

  public isActive$(): Observable<boolean> {
    return this.active$;
  }

  private updateProgress() {
    const totalExpectedTime = Array.from(this.tasks.values()).reduce((sum, task) => sum + task.expectedTime, 0);
    const totalRemainingTime = Array.from(this.tasks.values()).reduce((sum, task) => sum + task.remainingTime, 0);
    const progress = ((totalExpectedTime - totalRemainingTime) / totalExpectedTime) * 100;
    this.progress.next(Number.isNaN(progress) ? 100 : progress);
  }

  private createChecker() {
    this.checker = setInterval(() => {
      [...this.tasks.entries()].forEach(([k, t]) => {
        t.remainingTime = t.remainingTime - 500;
        if (t.remainingTime <= 0) {
          this.completeTask(k);
        }
      });
      this.updateProgress();
    }, 500);
    this.active$.next(true);
  }
}
