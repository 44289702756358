<div id="outer-container">
  <!-- Header Above -->
  <gr-header (toggleLeftPanel)="toggleLeft()" (toggleRightPanel)="toggleRight()"> </gr-header>

  <div id="inner-container">
    <div id="left-panel" [style.display]="(leftOpen$ | async) ? 'block' : 'none'">
      <ng-content select="[leftpanel]"></ng-content>
    </div>

    <!--
    <mat-sidenav #rightSideNav position="end" mode="side">
      <ng-content select="[rightpanel]"></ng-content>
    </mat-sidenav>-->

    <div id="actual-content" [style.display]="(contentOpen$ | async) ? 'grid' : 'none'">
      <div id="content-bg">
        <ng-content select="[main]"></ng-content>
      </div>
      <div id="content-overlay" class="grafiti-pass-pointers">
        <ng-content select="[overlay]"></ng-content>
      </div>
    </div>
  </div>
  <gr-footer></gr-footer>
</div>
