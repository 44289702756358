import { EditPropertyDelta } from '../edit-property.delta';
import { invertDiffs, PropertyDiff } from '../../property-diff';
import { UUID } from '../../../../util/math/uuid';
import { DeltaType } from '../../../../util/delta-type.annotation';
import { JsonIgnore } from '../../../../util/json';
import { StaticInject } from '../../../../util/lifecycle/static-injector';
import { Userspace } from '../../../../model/userspace.service';
import { FromIndex } from '../../../../model/relink/managed-entity';
import { GrafitiResponsibilityArea } from '../../../../model/entity/grafiti-responsibility-area';

@DeltaType('editResponsibilityArea')
export class EditResponsibilityAreaDelta extends EditPropertyDelta<keyof GrafitiResponsibilityArea> {
  @JsonIgnore()
  @StaticInject(Userspace)
  private readonly userspace: Userspace;

  @JsonIgnore()
  @FromIndex<EditResponsibilityAreaDelta>((self) => self.entityId)
  private area: GrafitiResponsibilityArea;

  public constructor(areaId: UUID, diffs: PropertyDiff<keyof GrafitiResponsibilityArea>[]) {
    super();
    this.entityId = this.entityId ?? areaId;
    this.diff = this.diff ?? diffs;
  }

  public apply(): void {
    if (!this.area) {
      return;
    }
    this.forEachDiff(this.area);
  }

  public onConfirmation(confirmationDelta: EditResponsibilityAreaDelta): void {}

  public getUndoDelta(): EditResponsibilityAreaDelta {
    return new EditResponsibilityAreaDelta(this.entityId, invertDiffs(this.diff));
  }

  public override getErrorMessage(): string {
    return `Projekt konnte nicht bearbeitet werden`;
  }
}
