import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, delay, filter, first, map, mapTo, pairwise, switchMap } from 'rxjs/operators';
import { PrivateUserDto } from '../dto/private-user.dto';
import { sha512 } from 'js-sha512';
import { AuthenticationConfigurationDto } from '../dto/authentication-configuration.dto';
import { CheckPropertyDto } from '../dto/check-property.dto';
import { UserController } from '../communication/user/user-controller.service';
import { AuthenticationError, AuthenticationErrorType } from '../util/authentication-error';
import { AbstractRestService } from '../communication/abstract-rest.service';
import { StorageService } from '../storage.service';
import { EMAIL_INVALID_ERROR, USERNAME_EXISTS_ERROR, USERNAME_INVALID_ERROR } from './login-constants';
import { CanFail } from '../util/can-fail';
import { CommonErrors, toUnsafe, UnsafeObservable } from '../util/result';
import { TelegramLoginData } from '../telegram/telegram-login.service';

@Injectable()
export class AuthenticationService extends AbstractRestService {
  public readonly currentUser$: Observable<PrivateUserDto>;
  public readonly onLogout$ = new Subject<void>();
  private readonly loggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public readonly sessionLocked$: Observable<boolean>;
  private readonly _sessionLocked$ = new BehaviorSubject<boolean>(false);
  /**
   * Creates a new AuthenticationService.
   *
   * @param http The HttpClient to communicate with the server.
   * @param storageService The StorageService.
   * @param userService
   */
  public constructor(
    protected readonly http: HttpClient,
    protected readonly storageService: StorageService,
    protected readonly userService: UserController
  ) {
    super(http);
    this.currentUser$ = storageService.currentUser$;
    this.sessionLocked$ = this._sessionLocked$.asObservable();
  }

  /**
   * Logs the user in using the given name and password. This function
   * sends login credentials.
   *
   * @param username The name of the user.
   * @param password The password of the user.
   * @param rememberMe Whether the server should remember the user. This means that
   * the session will not expire after the user closes the browser.
   */
  public login(username: string, password: string, rememberMe = true): UnsafeObservable<PrivateUserDto, CommonErrors> {
    if (!username || username === '' || !password || password === '') {
      return toUnsafe(throwError(() => new AuthenticationError(AuthenticationErrorType.CREDENTIALS)));
    }
    let passwordToSend: string;
    passwordToSend = sha512(password);
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    const options = { headers: headers, withCredentials: true };

    const body = 'username=' + username + '&password=' + passwordToSend + '&remember-me=' + rememberMe;

    return toUnsafe(
      this.http.post(this.getUrl('/auth/login'), body, options).pipe(
        switchMap(() => this.autoLogin(false)),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 0) {
            throw new AuthenticationError(AuthenticationErrorType.CONNECTION_FAILED, error);
          }
          throw new AuthenticationError(AuthenticationErrorType.CREDENTIALS);
        })
      )
    );
  }

  public telegramLogin(tgData: TelegramLoginData): Observable<PrivateUserDto> {
    if (!tgData) {
      return throwError(() => new AuthenticationError(AuthenticationErrorType.CREDENTIALS));
    }
    const options = { withCredentials: true };

    return this.http.post(this.getUrl('/auth/telegram-login'), tgData, options).pipe(
      delay(500),
      switchMap(() => this.autoLogin(false)),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 0) {
          throw new AuthenticationError(AuthenticationErrorType.CONNECTION_FAILED, error);
        }
        throw new AuthenticationError(AuthenticationErrorType.CREDENTIALS);
      })
    );
  }

  /**
   * Attempts to perform an automatic login using cookies which may be set in the browser.
   * If the attempt is successful a user dto will be emitted. Else the observable returned will completely quietly.
   *
   * @return observable firing user when successful or completing silently. akakak
   */
  public autoLogin(silent: boolean): Observable<any> {
    return new Observable<any>((subscriber) => {
      this.userService
        .fetchCurrentUser$()
        .pipe(
          first(),
          catchError((err, src) => {
            if (!silent) {
              subscriber.error(err);
              subscriber.complete();
            }
            subscriber.complete();
            return of();
          })
        )
        .subscribe((user) => {
          if (user) {
            this.storageService.setCurrentUser(user);
            subscriber.next(user);
            this.setLoggedIn(true);
          } else {
            subscriber.complete();
          }
        });
    });
  }

  /**
   * Sends a request to the server checking if the given username is in use.
   *
   * @param username the username to check for
   * @returns an observable containing the response
   */
  public checkUsernameAvailability(username: string): Observable<CheckPropertyDto> {
    if (!username || username === '') {
      return of({ passed: false, propertyName: 'username' });
    }
    return this.http.get<CheckPropertyDto>(this.getUrl(`/auth/check/username/${username}`));
  }

  /**
   * Sends a registration request with the given credentials.
   *
   * @param username the username of the new user
   * @param password the clear text password (will be hashed before sending)
   * @param email the e-mail address
   * @param name the real name of the user
   */
  public register(username: string, password: string, email: string, name: string): Observable<void> {
    return this.http
      .post(this.getUrl('/auth/register'), {
        username: username,
        email: email,
        password: sha512(password),
        name: name,
      })
      .pipe(
        mapTo(void 0),
        catchError((res) => {
          if (
            res.status === 400 &&
            (res.error === EMAIL_INVALID_ERROR ||
              res.error === USERNAME_EXISTS_ERROR ||
              res.error === USERNAME_INVALID_ERROR)
          ) {
            return throwError(res.error);
          }
          return throwError(res);
        })
      );
  }

  public create(username: string, email: string, name: string): Observable<void> {
    return this.http
      .post(this.getUrl('/auth/create'), {
        username: username,
        email: email,
        name: name,
      })
      .pipe(
        mapTo(void 0),
        catchError((res) => {
          if (
            res.status === 400 &&
            (res.error === EMAIL_INVALID_ERROR ||
              res.error === USERNAME_EXISTS_ERROR ||
              res.error === USERNAME_INVALID_ERROR)
          ) {
            return throwError(res.error);
          }
          return throwError(res);
        })
      );
  }

  public confirmCreation(password: string, token: string): Observable<any> {
    return new Observable((subscriber) => {
      if (!password || password === '') {
        subscriber.error('password');
        return;
      }
      let passwordToSend: string;
      passwordToSend = sha512(password);
      let headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');

      const options = { headers: headers };
      const body = {
        password: passwordToSend,
        token: token,
      };
      this.http.post(this.getUrl('/auth/confirm-creation'), body, options).subscribe(
        (data) => {
          subscriber.next(data);
          subscriber.complete();
        },
        (error: HttpErrorResponse) => {
          if (error.status === 0) {
            subscriber.error('offline');
          } else if (error.status !== 200) {
            subscriber.error('credentials');
          }
        }
      );
    });
  }

  /**
   * Sends an initial password reset request to the server causing the
   * server to generate a token to be sent to the given e-mail address.
   *
   * @param email the email of the user
   */
  public requestPasswordReset(email: string): Observable<void> {
    return this.http.post(this.getUrl('/auth/reset?email=' + email), {}).pipe(mapTo(void 0));
  }

  /**
   * Sends a request to the server to validate the given password reset
   * token.
   *
   * @param token the token to validate
   */
  public validatePasswordResetToken(token: string): Observable<boolean> {
    return this.http.post(this.getUrl('/auth/checkResetToken'), { token }).pipe(
      mapTo(true),
      catchError(() => of(false))
    );
  }

  /**
   * Sends a request to the server to reset the password to the given password
   * with the given token.
   *
   * @param password the new password
   * @param token the password reset token
   */
  public resetPassword(password: string, token: string): Observable<boolean> {
    return this.http
      .post(this.getUrl('/auth/confirm-reset'), {
        passwordHash: sha512(password),
        token: token,
      })
      .pipe(
        mapTo(true),
        catchError(() => of(false))
      );
  }

  /**
   * Changes the password of the current user from the given old
   * password to the given new password.
   *
   * @param oldPassword the old password of the user
   * @param newPassword the new password of the user
   */
  public changePassword(oldPassword: string, newPassword: string): Observable<CanFail<any>> {
    return this.http
      .post(this.getUrl('/auth/changePassword'), {
        oldPassword: sha512(oldPassword),
        newPassword: sha512(newPassword),
      })
      .pipe(
        map((e) => <CanFail<any>>{ ok: 'ok', error: null }),
        catchError((err, obs) => of(<CanFail<any>>{ ok: null, error: err }))
      );
  }

  /**
   * Logs out the current user by telling the server to invalidate
   * the session and deleting the user information from the localStorage.
   */
  public logout(): Observable<void> {
    this.storageService.setCurrentUser(null);
    this.setLoggedIn(false);
    // location.reload();
    return new Observable<void>((subscriber) => {
      this.onLogout$.next();
      // Needed because if logout fails for whatever reason the user should
      // still be deleted and we should still show the login page.
      // If we don't delete the user, we are stuck in an infinite loading loop.
      this.storageService.setCurrentUser(null);
      this.http
        .get(this.getUrl('/auth/logout'))
        .pipe(catchError(() => of(true)))
        .subscribe(() => {
          subscriber.next();
        });
    });
  }

  public getLoggedIn$(): Observable<boolean> {
    return this.loggedIn$.pipe();
  }
  public getLoggedIn(): boolean {
    return this.loggedIn$.value;
  }

  public onLogin$(): Observable<void> {
    return this.loggedIn$.pipe(
      pairwise(),
      map(([prev, cur]) => !prev && cur),
      filter((e) => e),
      map(() => null as void)
    );
  }

  public getCurrentUser(): PrivateUserDto {
    return this.currentUser$.immediate();
  }

  private setLoggedIn(value: boolean): void {
    this.loggedIn$.next(value);
  }

  public fetchTelegramBotName$(): Observable<string> {
    return this.http.get(this.getUrl('/auth/tgbotname'), {
      responseType: 'text',
    });
  }

  /**
   * Used when the session has been ended without the user properly signing out to prevent endless reconnects.
   */
  public setLocked(locked: boolean): void {
    this._sessionLocked$.next(locked);
  }

  // TODO das da auch auf serverseite
  public getConfiguration(): Observable<AuthenticationConfigurationDto> {
    return of({ passwordResetEnabled: true, registrationEnabled: true });
  }
}
