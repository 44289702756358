import { Delta } from '../delta';
import { DeltaType } from '../../../../util/delta-type.annotation';
import { RoleAssignmentDto } from '../../../../dto/role-assignment.dto';
import { JsonIgnore } from '../../../../util/json';
import { StaticInject } from '../../../../util/lifecycle/static-injector';

import { UUID } from '../../../../util/math/uuid';
import { RemovePermissionDelta } from './remove-permission.delta';
import { FromIndex, ManagedEntity } from '../../../../model/relink/managed-entity';
import { GrafitiRoleAssignment } from '../../../../model/entity/grafiti-role-assignment';
import { GrafitiRoleAssignmentHolder } from '../../../../model/entity/grafiti-role-assignment-holder';
import { ProjectLoaderService } from '../../../../model/project-loader.service';
import { Userspace } from '../../../../model/userspace.service';
import { GrafitiProject } from '../../../../model/entity/grafiti-project';
import { CommunityLoaderService } from '../../../../model/community-loader.service';
import { GrafitiCommunity } from '../../../../model/entity/grafiti-community';
import { GrafitiPlacardType } from '../../../../model/entity/grafiti-placard-type';

@DeltaType('addPermission')
export class AddPermissionDelta extends Delta {
  @JsonIgnore()
  @StaticInject(ProjectLoaderService)
  private readonly projectLoader: ProjectLoaderService;

  @JsonIgnore()
  @StaticInject(CommunityLoaderService)
  private readonly communityLoader: CommunityLoaderService;

  @JsonIgnore()
  @FromIndex<AddPermissionDelta>((self) => self.dto.holder)
  private holder: GrafitiRoleAssignmentHolder;

  @JsonIgnore()
  @StaticInject(Userspace)
  private userspace: Userspace;

  private holderType: string;

  private dto: RoleAssignmentDto;
  public constructor(assignmentDto: RoleAssignmentDto) {
    super();
    this.dto = assignmentDto ?? this.dto;
  }

  prePublish() {
    super.prePublish();

    this.holderType = this.holder?.constructor.name;
  }

  public apply(): void {
    if (ManagedEntity.findFromIndex(this.dto.id)) {
      return;
    }

    const assignment = new GrafitiRoleAssignment(this.dto);

    if (this.dto.user.id === this.userspace.getUserStore().findLoggedInUser().getId()) {
      if (this.holderType === GrafitiProject.name) {
        setTimeout(() => {
          this.addProject(assignment.getHolderId(), assignment);
        }, 1000);
      } else if (this.holderType === GrafitiCommunity.name) {
        setTimeout(() => {
          this.addCommunity(assignment.getHolderId(), assignment);
        }, 1000);
      } else if (this.holder) {
        const holder = this.holder;
        holder.setRoleAssignments([...holder.getRoleAssignments(), assignment]);
      }
    } else if (this.holder) {
      const holder = this.holder;
      holder.setRoleAssignments([...holder.getRoleAssignments(), assignment]);
    }
  }

  public onConfirmation(confirmationDelta: Delta): void {}

  private addProject(id: UUID, assignment: GrafitiRoleAssignment) {
    this.projectLoader.fetchProject$(id, false).subscribe((project) => {
      project.setRoleAssignments([
        ...project.getRoleAssignments().filter((ra) => ra.getId() !== assignment.getId()),
        assignment,
      ]);
    });
  }

  private addCommunity(id: UUID, assignment: GrafitiRoleAssignment) {
    this.communityLoader.fetchCommunity$(id, false).subscribe((community) => {
      community.setRoleAssignments([
        ...community.getRoleAssignments().filter((ra) => ra.getId() !== assignment.getId()),
        assignment,
      ]);
    });
  }

  public getUndoDelta(): Delta {
    return new RemovePermissionDelta(this.dto.id);
  }

  public override getErrorMessage(): string {
    return `Konnte Berechtigung nicht ändern!`;
  }
}
