<div>
  <h2 mat-dialog-title>
    <fa-icon [icon]="faExclamationTriangle"></fa-icon>
    {{ data.title }}
    <span class="spacer"></span>
    <button class="close" mat-icon-button [matDialogClose]="false"><fa-icon [icon]="faTimes"></fa-icon></button>
  </h2>
  <mat-dialog-content>
    <p style="display: inline">{{ data.text }}</p>
    <form [formGroup]="enterTextGroup" (ngSubmit)="submit()">
      <mat-form-field class="tw-w-full">
        <input matInput placeholder="Text eingeben" formControlName="text" type="text" cdkFocusInitial />
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions class="gr-actions">
    <button
      mat-raised-button
      class="okay-button"
      color="accent"
      [matDialogClose]="getText()"
      [disabled]="buttonDisabled">
      {{ data.okText }}
    </button>
    <button mat-raised-button [matDialogClose]="null" class="abort-button">
      {{ data.abortText }}
    </button>
  </mat-dialog-actions>
</div>
