import { NgModule } from '@angular/core';
import { CreatePlacardDialogComponent } from './create-placard-dialog/create-placard-dialog.component';
import { CommonDialogsService } from './common-dialogs.service';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { OptionsDialogComponent } from './options-dialog/options-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RecordingModule } from '../recording/recording.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AsyncPipe, NgFor, NgIf, NgOptimizedImage, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { FunctionExpressionModule } from 'ngx-function-expression';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { ImportFlyerRunDialogComponent } from './import-flyer-run-dialog/import-flyer-run-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EnterTextDialogComponent } from './enter-text-dialog/enter-text-dialog.component';
import { StartRecordFlyerRunDialogComponent } from './record-flyer-run-dialog/start/start-record-flyer-run-dialog.component';
import { StopRecordFlyerRunDialogComponent } from './record-flyer-run-dialog/stop/stop-record-flyer-run-dialog.component';
import { ImportImagesDialogComponent } from './import-images-dialog/import-images-dialog.component';
import { UtilitiesUiModule } from '../utilities-ui/utilities-ui.module';
import { SingleImageImportComponent } from './import-images-dialog/single/single-image-import.component';
import { MultipleImageImportComponent } from './import-images-dialog/multiple/multiple-image-import.component';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [
    ConfirmationDialogComponent,
    OptionsDialogComponent,
    CreatePlacardDialogComponent,
    ImportFlyerRunDialogComponent,
    EnterTextDialogComponent,
    StartRecordFlyerRunDialogComponent,
    StopRecordFlyerRunDialogComponent,
    ImportImagesDialogComponent,
    SingleImageImportComponent,
    MultipleImageImportComponent,
  ],
  providers: [CommonDialogsService],
  imports: [
    MatDialogModule,
    MatButtonModule,
    FontAwesomeModule,
    RecordingModule,
    MatProgressSpinnerModule,
    AsyncPipe,
    FunctionExpressionModule,
    MatInputModule,
    NgIf,
    NgFor,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NgSwitch,
    NgSwitchCase,
    UtilitiesUiModule,
    NgOptimizedImage,
    NgSwitchDefault,
    MatSelectModule,
  ],
})
export class GrDialogModule {}
