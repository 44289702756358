import { KeyCombination } from './key-combination';

export enum IoType {
  KEY_UP,
  KEY_DOWN,
}

/**
 * Wrapper class for {@link KeyboardEvent}.
 *
 * KeyboardIoEvents are created by the {@link KeyboardIoService} and should not be created otherwise.
 */
export class KeyboardIoEvent {
  public readonly type: IoType;
  public readonly keyCombination: KeyCombination;
  /**
   * Time of creation in milliseconds.
   */
  public readonly timestamp: number;
  /**
   * True, if the event was caused by auto-repeat.
   *
   * When pressing and holding a key, the keydown will be fired multiple times until the key is released. This is called auto-repeat.
   * All auto-repeated events will be marked with repeat === true.
   */
  public readonly repeat: boolean; // This remodels the KeyboardEvent.repeat property, which is not working properly. (When holding
  // multiple keys at once and only releasing one the KeyboardEvent.repeat will falsely mark all remaining keys
  // as unrepeated and fire them again)

  private keyboardEvent: KeyboardEvent;

  constructor(type: IoType, keyboardEvent: KeyboardEvent, repeat = false) {
    this.type = type;
    this.keyCombination = KeyCombination.fromEvent(keyboardEvent);
    this.timestamp = Date.now();
    this.repeat = repeat;
    this.keyboardEvent = keyboardEvent;
  }

  preventDefault(): void {
    this.keyboardEvent.preventDefault();
  }
}
