import { Injectable } from '@angular/core';
import { GrafitiProject } from './entity/grafiti-project';
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject, zip } from 'rxjs';
import { ProjectController } from '../communication/project/project-controller.service';
import { UUID } from '../util/math/uuid';
import { catchError, filter, first, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { ManagedEntity } from './relink/managed-entity';
import { GrafitiCommunity } from './entity/grafiti-community';
import { HttpErrorResponse } from '@angular/common/http';
import { CommunityLoaderService } from './community-loader.service';
import { ObservableSet } from '../util/reactive/observable-set';

@Injectable({
  providedIn: 'root',
})
export class ProjectLoaderService {
  private readonly projects$ = new BehaviorSubject<GrafitiProject[]>([]);
  private readonly loadedProjects$ = new BehaviorSubject<GrafitiProject[]>([]);

  public constructor(
    private readonly projectController: ProjectController,
    private readonly communityLoader: CommunityLoaderService
  ) {}

  public getProject$(id: UUID): Observable<GrafitiProject> {
    return this.projects$.pipe(
      map((projects) => projects.find((p) => p.getId() === id)),
      filter((e) => !!e)
    );
  }

  public getLoadedProject$(id: UUID): Observable<GrafitiProject> {
    return this.loadedProjects$.pipe(
      map((projects) => projects.find((project) => project.getId() === id)),
      filter((e) => !!e),
      first()
    );
  }

  public fetchProject$(id: UUID, includeContent: boolean, force = false): Observable<GrafitiProject> {
    {
      let existing = this.projects$.getValue().find((p) => p.getId() === id);
      if (!existing && ManagedEntity.findFromIndex(id)) {
        existing = ManagedEntity.findFromIndex<GrafitiProject>(id);
        if (existing) {
          this.projects$.next([...this.projects$.getValue(), existing]);
        }
      }
    }

    const ret = new ReplaySubject<GrafitiProject>(1);

    const loaded = includeContent ? this.hasLoadedProject(id) : this.hasProject(id);
    if (!loaded || force) {
      // TODO fetch sparse always

      this.projectController
        .getById(id, !includeContent)
        .pipe(
          catchError((err) => of(err as HttpErrorResponse)),
          switchMap((dto) => {
            if (dto instanceof HttpErrorResponse) {
              return of(dto);
            }
            // ensure the existence of a community
            return this.communityLoader.fetchCommunity$(dto.communityId, false, false).pipe(
              catchError((err) => of(err as HttpErrorResponse)),
              map((communityDto) => {
                if (communityDto instanceof HttpErrorResponse) {
                  return communityDto;
                }
                return dto;
              })
            );
          })
        )
        .subscribe((dto) => {
          if (dto instanceof HttpErrorResponse) {
            ret.error(dto);
            return;
          }
          let project = ManagedEntity.findFromIndex<GrafitiProject>(id);
          if (project) {
            project.update(dto);
          } else {
            const community = ManagedEntity.findFromIndex<GrafitiCommunity>(dto.communityId);
            project = new GrafitiProject(dto, community);
            community.setProjects([...community.getProjects(), project]);
          }

          if (dto.content) {
            this.loadedProjects$.next([...this.loadedProjects$.getValue(), project]);
          }
          ret.next(project);
          ret.complete();
          this.projects$.next([...this.projects$.getValue()]);
        });
    } else {
      const project = this.getProject(id);
      if (
        !project
          .getCommunity()
          .getProjects()
          .some((p) => p.getId() === id)
      ) {
        project.getCommunity().setProjects([...project.getCommunity().getProjects(), project]);
      }
      ret.next(project);
      ret.complete();
    }

    if (includeContent) {
      return ret;
    } else {
      return ret;
    }
  }

  public fetchMultiple(ids: UUID[], includeContent: boolean, force = false): Observable<GrafitiProject[]> {
    const arr = ids.map((a) => this.fetchProject$(a, includeContent, force).pipe(catchError((err) => of(null))));
    if (arr.length === 0) {
      return of([]);
    }
    return zip(...arr).pipe(map((projects) => projects.filter((project) => !!project)));
  }

  public hasProject(id: UUID): boolean {
    return this.projects$.getValue().some((p) => p.getId() === id);
  }

  public setLoadedProject(project: GrafitiProject): void {
    if (!this.hasProject(project.getId())) {
      this.projects$.next([...this.projects$.getValue(), project]);
    }
    if (!this.hasLoadedProject(project.getId())) {
      this.loadedProjects$.next([...this.loadedProjects$.getValue(), project]);
    }
  }

  public getProject(id: UUID): GrafitiProject {
    return this.projects$.getValue().find((p) => p.getId() === id);
  }

  public hasLoadedProject(id: UUID): boolean {
    return this.loadedProjects$.getValue().some((p) => p.getId() === id);
  }

  public unload(id: UUID): void {
    this.loadedProjects$.next(this.loadedProjects$.getValue().filter((p) => p.getId() !== id));
  }

  public remove(id: UUID): void {
    this.unload(id);
    this.projects$.next(this.projects$.getValue().filter((p) => p.getId() !== id));
  }
}
