import { Observable, ObservableInput, of, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

interface Result<T, Err> {
  ok: T;
  error: Err;
}

export function ok<OkType>(value: OkType): Result<OkType, never> {
  return {
    ok: value,
    error: undefined as never,
  };
}
export function error<ErrorType>(errorValue: ErrorType): Result<never, ErrorType> {
  return {
    ok: undefined as never,
    error: errorValue,
  };
}

export function match<T, Err, TResolve, ErrResolve>(
  result: Result<T, Err>,
  block: { ok: () => TResolve; error: () => ErrResolve }
): ErrResolve | TResolve {
  if (result.error) {
    return block.error();
  }
  return block.ok();
}

/*
export function match<T>(input: T, block: { [I in keyof T]: () => unknown }): T {
  // export function match<T>(input: T, block: unknown): T {
  return null as T;
}*/

export type Continue = {
  name: 'continue';
  message: 'continue';
};
export type CommonErrors = Error | HttpErrorResponse;
export type OverwriteableCommonErrors = CommonErrors | Continue;

export function contErr(err: CommonErrors): OverwriteableCommonErrors {
  return {
    ...err,
    name: 'continue',
    message: 'continue',
  };
}

/**
 * Represents an observable that does not include error handling, but is known
 * for possible errors. Forces the developer using it to first handle or at least
 * acknowledge possible errors on a type level.
 */
export class UnsafeObservable<T, Error> {
  public constructor(private readonly src: Observable<T>) {}

  /**
   * Provides access to the underlying observable by forcing the developer
   * to handle possible errors, and puts error types downstream.
   * @param errorHandler a function to handle the errors. Proxies to {@link catchError}.
   */
  public unwrap<ErrorResult>(
    errorHandler: (err: Error, caught: Observable<T>) => Observable<ErrorResult>
  ): Observable<T | ErrorResult> {
    return this.src.pipe(catchError(errorHandler));
  }

  /**
   * Provides access to the underlying observable by forcing the developer
   * to handle possible errors, and puts error types downstream,
   * but keeps both error and data in result, for easier distinguishment.
   * @param errorHandler a function to handle the errors. Proxies to {@link catchError}.
   */
  public unwrapInline<ErrorResult>(
    errorHandler: (err: Error, caught: Observable<T>) => Observable<ErrorResult>
  ): Observable<Result<T, ErrorResult>> {
    return this.src.pipe(
      map((element) => ok(element)),
      catchError((err, caught) => {
        return errorHandler(err, caught.pipe(map((c) => c.ok))).pipe(map((originalError) => error(originalError)));
      })
    );
  }
}

export function ofResult<T, Err>(input: Result<T, Err>): UnsafeObservable<T, Err> {
  const srcObs = new Subject<T>();
  const retObs = new UnsafeObservable(srcObs);
  match(input, {
    error: () => srcObs.error(input.error),
    ok: () => srcObs.next(input.ok),
  });
  return retObs as UnsafeObservable<T, Err>;
}

export function toUnsafe<T, Err>(src: Observable<T>): UnsafeObservable<T, Err> {
  return new UnsafeObservable<T, Err>(src);
}
