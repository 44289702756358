import { Delta } from '../delta';
import { DeltaType } from '../../../../util/delta-type.annotation';
import { UserInteractionDto } from '../../../../dto/user-interaction.dto';

@DeltaType('createUserInteraction')
export class CreateInteractionDelta extends Delta {
  private dto: UserInteractionDto;

  public constructor(dto: UserInteractionDto) {
    super();
    this.dto = dto ?? this.dto;
  }

  public apply(): void {
    // TODO implement
    throw new Error('Not implemented!');
  }

  getErrorMessage(): string {
    return '';
  }

  onConfirmation(confirmationDelta: Delta): void {}
}
