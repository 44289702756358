import { Component } from '@angular/core';

@Component({
  selector: 'gr-auth-entry',
  template: `
    <div id="outer-container">
      <gr-background> </gr-background>
      <div id="content">
        <div id="inner">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['auth-entry.component.scss'],
})
export class GrAuthEntryComponent {}
