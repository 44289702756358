import { Observable } from 'rxjs';
import { Model } from './model';
import { ManagedEntity } from '../relink/managed-entity';
import { UUID } from '../../util/math/uuid';
import { EntityDto } from '../../dto/entity.dto';

export abstract class GrafitiEntity extends ManagedEntity<UUID> implements Model {
  private created?: number;
  protected readonly onModification$: Observable<unknown>;

  protected constructor(dto: EntityDto) {
    super(dto.id);
    this.created = dto.created;
  }

  public override update(dto: EntityDto): void {
    this.created = dto.created;
  }

  public getCreated(): number {
    return this.created ?? 0;
  }

  public getCreatedDate(): Date {
    return new Date(this.created);
  }

  public buildDto(): EntityDto {
    return {
      id: this.getId(),
      created: this.created,
    };
  }
}
