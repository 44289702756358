import { Observable } from 'rxjs';
import { PrivateUserDto } from '../../dto/private-user.dto';
import { SparseUserDto } from '../../dto/sparse-user.dto';
import { GrafitiEntity } from './grafiti-entity';
import { Searchable } from './searchable';
import { ValueSubject } from '../../util/reactive/value-subject';
import { AccessManaged, GrafitiPermission } from '../../dto/permission';
import { EmailLoginOptionDto } from '../../dto/email-login-option.dto';
import { TelegramLoginOptionDto } from '../../dto/telegram-login-option.dto';
import { GrafitiUser } from './grafiti-user';

export class GrafitiPrivateUser extends GrafitiUser {
  private readonly emailOption$: ValueSubject<EmailLoginOptionDto>;
  private readonly telegramOption$: ValueSubject<TelegramLoginOptionDto>;
  private readonly settings$: ValueSubject<Map<string, string>>;

  public constructor(dto: PrivateUserDto) {
    super(dto, true);

    this.emailOption$ = new ValueSubject<EmailLoginOptionDto>(dto.emailOption);
    this.telegramOption$ = new ValueSubject<TelegramLoginOptionDto>(dto.telegramOption);

    const settings = new Map<string, string>();
    if (dto['settings']) {
      for (const [key, value] of Object.entries(dto['settings'])) {
        settings.set(key, value as string);
      }
    }
    this.settings$ = new ValueSubject<Map<string, string>>(settings);
    this.ready();
  }

  public setEmailOption(value: EmailLoginOptionDto): void {
    this.emailOption$.next(value);
  }

  public getEmailOption(): EmailLoginOptionDto {
    return this.emailOption$.getValue();
  }

  public getEmailOption$(): Observable<EmailLoginOptionDto> {
    return this.emailOption$;
  }

  public get emailOption(): EmailLoginOptionDto {
    return this.getEmailOption();
  }

  public setTelegramOption(value: TelegramLoginOptionDto): void {
    this.telegramOption$.next(value);
  }

  public getTelegramOption(): TelegramLoginOptionDto {
    return this.telegramOption$.getValue();
  }

  public getTelegramOption$(): Observable<TelegramLoginOptionDto> {
    return this.telegramOption$;
  }

  public get telegramOption(): TelegramLoginOptionDto {
    return this.getTelegramOption();
  }

  public updateSetting(key: string, value: string): void {
    const a = new Map(this.settings$.getValue());
    a.set(key, value);
    this.settings$.next(a);
  }

  public getSettings(): Map<string, string> {
    return this.settings$.getValue();
  }

  public getSettings$(): Observable<Map<string, string>> {
    return this.settings$;
  }

  protected destructor(): void {
    super.destructor();
    this.emailOption$.complete();
    this.telegramOption$.complete();
    this.settings$.complete();
  }

  public override buildDto(): PrivateUserDto {
    return {
      ...super.buildDto(),
      settings: this.settings$.getValue(),
      telegramOption: this.getTelegramOption(),
      emailOption: this.getEmailOption(),
    };
  }

  matchesQueryFilters(queries: string): boolean {
    return false;
  }

  matchesTextFilter(filter: string): boolean {
    return this.getEntireSearchString().includes(filter);
  }

  protected override getEntireSearchString(): string {
    return [this.getUsername(), this.getName(), this.getEmailOption()?.email ?? '']
      .filter((e) => !!e)
      .join(',')
      .toLowerCase();
  }
}
