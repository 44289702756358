import { GrafitiLocation } from './grafiti-location';
import { GrafitiActivity } from './grafiti-activity';
import { ProjectDto } from '../../dto/project.dto';
import { GrafitiPlacard } from './grafiti-placard';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { GrafitiPlacardType } from './grafiti-placard-type';
import { UUID } from '../../util/math/uuid';
import { map, switchMap } from 'rxjs/operators';
import { GrafitiFlyerRun } from './grafiti-flyer-run';
import { GrafitiFlyerType } from './grafiti-flyer-type';
import { HasName } from '../interface/has-name';
import { HasDescription } from '../interface/has-description';
import { SparseProjectDto } from '../../dto/sparse-project.dto';
import { GrafitiCommunity } from './grafiti-community';
import { ValueSubject } from '../../util/reactive/value-subject';
import { GrafitiRoleAssignmentHolder } from './grafiti-role-assignment-holder';
import { ManagedEntity } from '../relink/managed-entity';
import { Searchable } from './searchable';
import { GrafitiResponsibilityArea } from './grafiti-responsibility-area';
import { ResponsibilityLinkDto } from '../../dto/responsibility-link.dto';
import { GrafitiUser } from './grafiti-user';
import { booleanContains } from '@turf/turf';
import booleanIntersects from '@turf/boolean-intersects';
import { isContentDto } from '../../dto/content.dto';
import { ProjectContentDto } from '../../dto/project-content.dto';

export class GrafitiProject extends GrafitiRoleAssignmentHolder implements HasName, HasDescription, Searchable {
  private readonly name$: BehaviorSubject<string>;
  private readonly description$: BehaviorSubject<string>;
  private readonly _startDate$: BehaviorSubject<number>;
  private readonly _endDate$: BehaviorSubject<number>;

  // private readonly loaded$ = new BehaviorSubject<boolean>(false);
  private readonly community$: ValueSubject<GrafitiCommunity>;
  private readonly locations$: ValueSubject<GrafitiLocation[]>;
  private readonly placardTypes$: ValueSubject<GrafitiPlacardType[]>;
  private readonly flyerTypes$: ValueSubject<GrafitiFlyerType[]>;
  private readonly flyerRuns$: ValueSubject<GrafitiFlyerRun[]>;
  private readonly responsibilityAreas$: ValueSubject<GrafitiResponsibilityArea[]>;
  private readonly responsibilityLinks$: ValueSubject<ResponsibilityLinkDto[]>;

  public constructor(dto: SparseProjectDto, community: GrafitiCommunity) {
    super(dto);

    const fullDto = dto as ProjectDto;

    this.name$ = new BehaviorSubject(dto.name);
    this.description$ = new BehaviorSubject(dto.description);
    this._startDate$ = new ValueSubject<number>(dto.startDate, this.destroy$);
    this._endDate$ = new ValueSubject<number>(dto.endDate, this.destroy$);
    this.community$ = new ValueSubject<GrafitiCommunity>(community, this.destroy$);
    this.placardTypes$ = new ValueSubject<GrafitiPlacardType[]>(
      fullDto.content?.placardTypes.map((type) => new GrafitiPlacardType(type, this)) ?? [],
      this.destroy$
    );
    this.locations$ = new ValueSubject<GrafitiLocation[]>(
      fullDto.content?.locations.map((loc) => new GrafitiLocation(loc, this)) ?? [],
      this.destroy$
    );
    this.flyerTypes$ = new ValueSubject<GrafitiFlyerType[]>(
      fullDto.content?.flyerTypes.map((type) => new GrafitiFlyerType(type, this)) ?? [],
      this.destroy$
    );
    this.flyerRuns$ = new ValueSubject<GrafitiFlyerRun[]>(
      fullDto.content?.flyerRuns.map((run) => new GrafitiFlyerRun(run, this)) ?? [],
      this.destroy$
    );
    this.responsibilityAreas$ = new ValueSubject<GrafitiResponsibilityArea[]>(
      fullDto.content?.responsibilityAreas.map((res) => new GrafitiResponsibilityArea(res, this)) ?? [],
      this.destroy$
    );
    this.responsibilityLinks$ = new ValueSubject<ResponsibilityLinkDto[]>(
      fullDto.content?.responsibilityLinks ?? [],
      this.destroy$
    );
    this.ready();
  }

  public override update(dto: SparseProjectDto) {
    super.update(dto);
    this.setName(dto.name);
    this.setDescription(dto.description);
    this.setStartDate(dto.startDate);
    this.setEndDate(dto.endDate);

    if (isContentDto<ProjectContentDto>(dto)) {
      const newPlacardTypes = ManagedEntity.updateArray({
        current: this.getPlacardTypes(),
        updatedDtos: dto.content.placardTypes,
        createFn: (d) => new GrafitiPlacardType(d, this),
      });
      this.setPlacardTypes(newPlacardTypes);

      const locations = ManagedEntity.updateArray({
        current: this.getLocations(),
        updatedDtos: dto.content.locations,
        createFn: (d) => new GrafitiLocation(d, this),
      });
      this.setLocations(locations);

      const flyerTypes = ManagedEntity.updateArray({
        current: this.getFlyerTypes(),
        updatedDtos: dto.content.flyerTypes,
        createFn: (d) => new GrafitiFlyerType(d, this),
      });
      this.setFlyerTypes(flyerTypes);

      const flyerRuns = ManagedEntity.updateArray({
        current: this.getFlyerRuns(),
        updatedDtos: dto.content.flyerRuns,
        createFn: (d) => new GrafitiFlyerRun(d, this),
      });
      this.setFlyerRuns(flyerRuns);

      const responsibilityAreas = ManagedEntity.updateArray({
        current: this.getResponsibilityAreas(),
        updatedDtos: dto.content.responsibilityAreas,
        createFn: (d) => new GrafitiResponsibilityArea(d, this),
      });
      this.setResponsibilityAreas(responsibilityAreas);

      this.setResponsibilityLinks(dto.content.responsibilityLinks);
    }
  }

  public setLocations(locations: GrafitiLocation[]): void {
    this.locations$.next(locations);
  }

  public getLocations(): GrafitiLocation[] {
    return this.locations$.getValue();
  }

  public getLocations$(): Observable<GrafitiLocation[]> {
    return this.locations$.asObservable();
  }

  public setFlyerRuns(runs: GrafitiFlyerRun[]): void {
    this.flyerRuns$.next(runs);
  }

  public getFlyerRuns(): GrafitiFlyerRun[] {
    return this.flyerRuns$.getValue();
  }

  public getFlyerRuns$(): Observable<GrafitiFlyerRun[]> {
    return this.flyerRuns$.asObservable();
  }

  public getPlacards(): GrafitiPlacard[] {
    return this.getLocations()
      .flatMap((location) => location.getPlacards())
      .distinct();
  }

  public getActivities(): GrafitiActivity[] {
    return this.getPlacards().flatMap((placard) => placard.getActivities());
  }

  public getPlacards$(): Observable<GrafitiPlacard[]> {
    return this.getLocations$().pipe(
      switchMap((locations) => {
        return combineLatest(locations.map((loc) => loc.getPlacards$()));
      }),
      map((a) => a.flatMap((b) => b))
    );
  }

  /**
   * @deprecated
   * this needs a rework
   */
  public getActivities$(): Observable<GrafitiActivity[]> {
    return this.getPlacards$().pipe(
      switchMap((locations) => {
        return combineLatest(locations.map((loc) => loc.getActivities$()));
      }),
      map((a) => a.flatMap((b) => b))
    );
  }

  public setPlacardTypes(types: GrafitiPlacardType[]): void {
    this.placardTypes$.next(types);
  }

  public getPlacardTypes(): GrafitiPlacardType[] {
    return this.placardTypes$.getValue();
  }

  public getPlacardTypes$(): Observable<GrafitiPlacardType[]> {
    return this.placardTypes$.asObservable();
  }

  public setFlyerTypes(types: GrafitiFlyerType[]): void {
    this.flyerTypes$.next(types);
  }

  public getFlyerTypes(): GrafitiFlyerType[] {
    return this.flyerTypes$.getValue();
  }

  public getFlyerTypes$(): Observable<GrafitiFlyerType[]> {
    return this.flyerTypes$.asObservable();
  }

  public setResponsibilityAreas(areas: GrafitiResponsibilityArea[]): void {
    this.responsibilityAreas$.next(areas);
  }

  public getResponsibilityAreas(): GrafitiResponsibilityArea[] {
    return this.responsibilityAreas$.getValue();
  }

  public getResponsibilityAreas$(): Observable<GrafitiResponsibilityArea[]> {
    return this.responsibilityAreas$.asObservable();
  }

  public setResponsibilityLinks(links: ResponsibilityLinkDto[]): void {
    this.responsibilityLinks$.next(links);
  }

  public getResponsibilityLinks(): ResponsibilityLinkDto[] {
    return this.responsibilityLinks$.getValue();
  }

  public getResponsibilityLinks$(): Observable<ResponsibilityLinkDto[]> {
    return this.responsibilityLinks$.asObservable();
  }

  public removeLocation(locationId: UUID): void {
    const toDelete = this.getLocations().findIndex((el) => el.getId() === locationId);
    const newArr = [...this.getLocations()];
    newArr.splice(toDelete, 1);
    this.setLocations(newArr);
  }

  public addLocation(location: GrafitiLocation): void {
    const locations = this.getLocations();
    this.setLocations([...locations, location]);
  }

  public addFlyerRun(flyerRun: GrafitiFlyerRun): void {
    const flyerRuns = this.getFlyerRuns();
    this.setFlyerRuns([...flyerRuns, flyerRun]);
  }

  public removeFlyerRun(areaId: UUID): void {
    const toDelete = this.getFlyerRuns().findIndex((el) => el.getId() === areaId);
    const newArr = [...this.getFlyerRuns()];
    newArr.splice(toDelete, 1);
    this.setFlyerRuns([...newArr]);
  }

  public addPlacardType(type: GrafitiPlacardType): void {
    const types = this.getPlacardTypes();
    this.setPlacardTypes([...types, type]);
  }

  public getCommunity(): GrafitiCommunity {
    return this.community$.getValue();
  }

  public getCommunity$(): Observable<GrafitiCommunity> {
    return this.community$.asObservable();
  }

  public setCommunity(community: GrafitiCommunity): void {
    this.community$.next(community);
  }

  /*
  public isLoaded(): boolean {
    return this.loaded$.getValue();
  }

  public isLoaded$(): Observable<boolean> {
    return this.loaded$;
  }

  public setLoaded(loaded: boolean): void {
    this.loaded$.next(loaded);
  }*/

  public getStartDate(): number {
    return this._startDate$.getValue();
  }

  public setStartDate(startDate: number): void {
    this._startDate$.next(startDate);
  }

  public getStartDate$(): Observable<number> {
    return this._startDate$;
  }

  public getEndDate(): number {
    return this._endDate$.getValue();
  }

  public setEndDate(endDate: number): void {
    this._endDate$.next(endDate);
  }

  public getEndDate$(): Observable<number> {
    return this._endDate$;
  }

  public get name(): string {
    return this.getName();
  }

  public get description(): string {
    return this.getDescription();
  }

  public get startDate(): number {
    return this.getStartDate();
  }

  public get endDate(): number {
    return this.getEndDate();
  }

  getDescription(): string {
    return this.description$.getValue();
  }

  getDescription$(): Observable<string> {
    return this.description$;
  }

  getName(): string {
    return this.name$.getValue();
  }

  getName$(): Observable<string> {
    return this.name$;
  }

  setDescription(name: string): void {
    this.description$.next(name);
  }

  setName(name: string): void {
    this.name$.next(name);
  }

  public buildDto(): ProjectDto {
    const dto = super.buildDto() as ProjectDto;
    dto.content = {
      locations: this.getLocations().map((location) => location.buildDto()),
      placardTypes: this.getPlacardTypes().map((type) => type.buildDto()),
      responsibilityAreas: this.getResponsibilityAreas().map((area) => area.buildDto()),
      flyerRuns: this.getFlyerRuns().map((fr) => fr.buildDto()),
      flyerTypes: this.getFlyerTypes().map((ft) => ft.buildDto()),
      responsibilityLinks: this.getResponsibilityLinks(),
    };

    return dto;
  }
  public buildSparseDto(): SparseProjectDto {
    const dto = super.buildDto() as SparseProjectDto;
    return dto;
  }

  matchesQueryFilters(queries: string): boolean {
    return false;
  }
  matchesTextFilter(filter: string): boolean {
    return [this.getName(), this.getDescription(), this.getCommunity().getName()].join(';;;').includes(filter);
  }

  public getMembers$(): Observable<GrafitiUser[]> {
    return this.getRoleAssignments$().pipe(map((roleAssignments) => roleAssignments.map((ra) => ra.getUser())));
  }

  public getMembers(): GrafitiUser[] {
    return this.getRoleAssignments()
      .map((roleAssignments) => roleAssignments)
      .map((ra) => ra.getUser());
  }

  /**
   * Checks whether the location is inside any area.
   * this is located here, as the project knows all areas, and there is room to optimize, so to not check areas far away, etc...
   * @param location
   * @param assignedTo the id of the user the location must be assigned to. If null, all areas are considered.
   */
  public anyAreaContainsLocation(location: GrafitiLocation, assignedTo: UUID): boolean {
    const containingAreas = this.getResponsibilityAreas().filter((area) => {
      return booleanContains(area.getTurfPolygon(), location.getTurfPoint());
    });

    if (assignedTo) {
      // TODO optimize join
      const e = containingAreas.innerJoin(
        this.getResponsibilityLinks().filter((link) => link.userId === assignedTo),
        (area) => area.getId(),
        (link) => link.areaId
      );

      return e.length > 0;
    } else {
      return containingAreas.length > 0;
    }
  }

  /**
   * Checks whether any area in this project intersects with the specified flyer run route.
   * @param flyerRun
   * @param assignedTo the id of the user the flyer run must be assigned to. If null, all areas are considered
   */
  public anyAreaPartiallyOrCompletelyContainsFlyerRun(flyerRun: GrafitiFlyerRun, assignedTo: UUID): boolean {
    const containingAreas = this.getResponsibilityAreas().filter((area) => {
      return booleanIntersects(area.getTurfPolygon(), flyerRun.getTurfLine());
    });

    if (assignedTo) {
      // TODO optimize join
      const e = containingAreas.innerJoin(
        this.getResponsibilityLinks().filter((link) => link.userId === assignedTo),
        (area) => area.getId(),
        (link) => link.areaId
      );

      return e.length > 0;
    } else {
      return containingAreas.length > 0;
    }
  }

  protected destructor(): void {}
}

export interface HasProject {
  project: GrafitiProject;
}
