import { GrafitiEntity } from './grafiti-entity';
import { RoleAssignmentHolder } from './role-assignment-holder';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, shareReplay, takeUntil } from 'rxjs/operators';
import { collectPermissions, GrafitiPermission, AccessManaged, getPermissionsFromRole } from '../../dto/permission';
import { ValueSubject } from '../../util/reactive/value-subject';
import { GrafitiRoleAssignment } from './grafiti-role-assignment';
import { EntityDto } from '../../dto/entity.dto';
import { RoleAssignmentHolderDto } from '../../dto/role-assignment-holder.dto';
import { StaticInjector } from '../../util/lifecycle/static-injector';
import { Userspace } from '../userspace.service';
import { RoleAssignmentDto } from '../../dto/role-assignment.dto';
import { ManagedEntity } from '../relink/managed-entity';

export abstract class GrafitiRoleAssignmentHolder extends GrafitiEntity implements RoleAssignmentHolder, AccessManaged {
  private readonly roleAssignments$: ValueSubject<GrafitiRoleAssignment[]>;
  private readonly userspace: Userspace;

  protected constructor(dto: EntityDto & RoleAssignmentHolderDto) {
    super(dto);

    this.userspace = StaticInjector.inject(Userspace);

    this.roleAssignments$ = new ValueSubject<GrafitiRoleAssignment[]>(
      dto.roleAssignments.map(
        (ra) => ManagedEntity.findFromIndex<GrafitiRoleAssignment>(ra.id) ?? new GrafitiRoleAssignment(ra)
      )
    );
  }

  protected anyRoleProvidesPermission(permission: GrafitiPermission): boolean {
    const user = this.userspace.getUserStore().findLoggedInUser();
    return this.getRoleAssignments()
      .filter((ra) => ra.getUser().getId() === user.getId())
      .map((ra) => ra.getRole())
      .some((role) => getPermissionsFromRole(role).includes(permission));
  }

  protected anyRoleProvidesPermission$(permission: GrafitiPermission): Observable<boolean> {
    return combineLatest([this.getRoleAssignments$(), this.userspace.getUserStore().findLoggedInUser$()]).pipe(
      takeUntil(this.destroy$),
      map(([roleAssignbments, user]) => {
        return roleAssignbments
          .filter((ra) => ra.getUser().getId() === user.getId())
          .map((ra) => ra.getRole())
          .some((role) => getPermissionsFromRole(role).includes(permission));
      }),
      shareReplay(1)
    );
  }

  public override update(dto: EntityDto & RoleAssignmentHolderDto) {
    super.update(dto);
    this.setRoleAssignments(
      ManagedEntity.updateArray({
        current: this.getRoleAssignments(),
        updatedDtos: dto.roleAssignments,
        createFn: (d) => new GrafitiRoleAssignment(d),
      })
    );
  }

  public hasPermission$(permission: GrafitiPermission): Observable<boolean> {
    return this.anyRoleProvidesPermission$(permission);
  }

  public hasPermission(permission: GrafitiPermission): boolean {
    return this.anyRoleProvidesPermission(permission);
  }

  public getRoleAssignments(): GrafitiRoleAssignment[] {
    return this.roleAssignments$.getValue();
  }

  public getRoleAssignments$(): Observable<GrafitiRoleAssignment[]> {
    return this.roleAssignments$.asObservable();
  }

  public setRoleAssignments(roleAssignments: GrafitiRoleAssignment[]): void {
    if (roleAssignments.some((ra) => ra.getHolderId() !== this.getId())) {
      throw new Error('RoleAssignmentHolderId does not equal this holders id!');
    }
    this.roleAssignments$.next(roleAssignments);
  }

  public updateRoleAssignments(raDtos: RoleAssignmentDto[]): void {
    this.getRoleAssignments().forEach((ra) => ra.destroy());
    this.roleAssignments$.next(raDtos.map((ra) => new GrafitiRoleAssignment(ra)));
  }

  buildDto(): EntityDto & RoleAssignmentHolderDto {
    return {
      ...super.buildDto(),
      roleAssignments: this.getRoleAssignments().map((ra) => ra.buildDto()),
    };
  }
}
