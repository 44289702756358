import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AbstractRestService } from './abstract-rest.service';
import { catchError, map } from 'rxjs/operators';
import { ApiKeyDto } from '../dto/api-key.dto';

@Injectable({
  providedIn: 'root',
})
export class VersionController extends AbstractRestService {
  constructor(protected readonly http: HttpClient) {
    super(http);
  }

  public getVersion$(): Observable<string> {
    return this.http.get(this.getUrl('/version'), { responseType: 'text' }).pipe(catchError(() => of(null)));
  }
}
