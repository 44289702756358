import { Delta } from '../delta';
import { DeltaType } from '../../../../util/delta-type.annotation';
import { LocationDto } from '../../../../dto/location.dto';
import { UUID } from '../../../../util/math/uuid';
import { JsonIgnore } from '../../../../util/json';
import { CreateLocationDelta } from './create-location.delta';
import { GrafitiLocation } from '../../../../model/entity/grafiti-location';
import { FromIndex } from '../../../../model/relink/managed-entity';

@DeltaType('deleteLocation')
export class DeleteLocationDelta extends Delta {
  @JsonIgnore()
  @FromIndex<DeleteLocationDelta>((self) => self.locationId)
  private location: GrafitiLocation;

  @JsonIgnore()
  private locationDto: LocationDto;

  public locationId: UUID;

  public constructor(locationDto: LocationDto) {
    super();
    this.locationId = locationDto?.id ?? this.locationId;
  }

  public apply(): void {
    if (!this.location) {
      return;
    }
    this.locationDto = this.location.buildDto();
    const project = this.location.getProject();
    project.setLocations(project.getLocations().filter((l) => l.getId() !== this.locationId));
    this.location.destroy();
  }

  public override onConfirmation(confirmationDelta: Delta): void {}

  public override getUndoDelta(): Delta {
    return new CreateLocationDelta(this.locationDto);
  }

  public override getErrorMessage(): string {
    return `Standort konnte nicht gelöscht werden`;
  }
}
