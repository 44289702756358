import { Delta } from '../delta';
import { DeltaType } from '../../../../util/delta-type.annotation';
import { UUID } from '../../../../util/math/uuid';
import { JsonIgnore } from '../../../../util/json';
import { PlacardDto } from '../../../../dto/placard.dto';
import { CreatePlacardDelta } from './create-placard-delta';
import { FromIndex } from '../../../../model/relink/managed-entity';
import { GrafitiPlacard } from '../../../../model/entity/grafiti-placard';
import { GrafitiLocation } from '../../../../model/entity/grafiti-location';

@DeltaType('deletePlacard')
export class DeletePlacardDelta extends Delta {
  @JsonIgnore()
  @FromIndex<DeletePlacardDelta>((self) => self.placardId)
  private placard: GrafitiPlacard;

  @JsonIgnore()
  @FromIndex<DeletePlacardDelta>((self) => self.placardDto.location)
  private location: GrafitiLocation;

  @JsonIgnore()
  private placardDto: PlacardDto;

  public placardId: UUID;

  public constructor(placardDto: PlacardDto) {
    super();
    this.placardId = placardDto?.id ?? this.placardId ?? null;
  }

  public apply(): void {
    if (!this.placard) {
      return;
    }

    this.placardDto = this.placard.buildDto();
    this.location.setPlacards(this.location.getPlacards().filter((loc) => loc.getId() !== this.placardId));
    this.placard.destroy();
  }

  public override onConfirmation(confirmationDelta: Delta): void {}

  public override getUndoDelta(): Delta {
    return new CreatePlacardDelta(this.placardDto);
  }

  public override getErrorMessage(): string {
    return `Plakat konnte nicht gelöscht werden!`;
  }
}
