import { Injectable } from '@angular/core';
import { AbstractRestService } from '../abstract-rest.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PrivateUserDto } from '../../dto/private-user.dto';

@Injectable({
  providedIn: 'root',
})
export class UserController extends AbstractRestService {
  constructor(protected readonly http: HttpClient) {
    super(http);
  }

  public fetchCurrentUser$(): Observable<PrivateUserDto> {
    return this.http.get<PrivateUserDto>(this.getUrl('/users/current'));
  }

  public fetchAllUsers$(): Observable<PrivateUserDto[]> {
    return this.http.get<PrivateUserDto[]>(this.getUrl('/users/all'));
  }
}
