export interface PropertyDiff<Properties = string> {
  name: Properties;
  oldValue: number | string | boolean | unknown;
  newValue: number | string | boolean | unknown;
}

export function invertDiff<Properties>(original: PropertyDiff<Properties>): PropertyDiff<Properties> {
  return {
    name: original.name,
    oldValue: original.newValue,
    newValue: original.oldValue,
  };
}

export function invertDiffs<Property>(original: PropertyDiff<Property>[]): PropertyDiff<Property>[] {
  return original.map((diff) => invertDiff(diff));
}
