import { Delta } from '../delta';
import { DeltaType } from '../../../../util/delta-type.annotation';
import { UUID } from '../../../../util/math/uuid';
import { JsonIgnore } from '../../../../util/json';
import { ActivityDto } from '../../../../dto/activity.dto';
import { CreateActivityDelta } from './create-activity-delta';
import { FromIndex } from '../../../../model/relink/managed-entity';
import { GrafitiActivity } from '../../../../model/entity/grafiti-activity';

@DeltaType('deleteActivity')
export class DeleteActivityDelta extends Delta {
  @JsonIgnore()
  @FromIndex<DeleteActivityDelta>((self) => self.activityId)
  private activity: GrafitiActivity;

  @JsonIgnore()
  private activityDto: ActivityDto;

  public activityId: UUID;

  public constructor(activityDto: ActivityDto) {
    super();
    this.activityId = activityDto?.id ?? this.activityId ?? null;
  }

  public apply(): void {
    if (!this.activity) {
      return;
    }

    this.activityDto = this.activity.buildDto();
    const placard = this.activity.getPlacard();
    placard.setActivities(placard.getActivities().filter((a) => a.getId() !== this.activityId));
    this.activity.destroy();
  }

  public override onConfirmation(confirmationDelta: Delta): void {}

  public override getUndoDelta(): Delta {
    return new CreateActivityDelta(this.activityDto);
  }

  public override getErrorMessage(): string {
    return `Aktivität konnte nicht gelöscht werden!`;
  }
}
