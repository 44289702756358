import { NgModule } from '@angular/core';
import { ManagedMapComponent } from './managed-map/managed-map.component';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { FunctionExpressionModule } from 'ngx-function-expression';
import { KeyboardModule } from '../keyboard/keyboard.module';
import { GrDialogModule } from '../dialog/dialog.module';
import { SelectionModule } from '../selection/selection.module';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletGeomanModule } from '@dvina/ngx-leaflet-geoman';

@NgModule({
  declarations: [ManagedMapComponent],
  imports: [
    CommonModule,
    MatMenuModule,
    FunctionExpressionModule,
    KeyboardModule,
    GrDialogModule,
    SelectionModule,
    LeafletModule,
    LeafletGeomanModule,
  ],
  exports: [ManagedMapComponent],
  providers: [],
})
export class MapModule {}
