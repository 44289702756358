import { Delta } from '../delta';
import { DeltaType } from '../../../../util/delta-type.annotation';
import { UUID } from '../../../../util/math/uuid';
import { JsonIgnore } from '../../../../util/json';
import { StaticInject } from '../../../../util/lifecycle/static-injector';
import { AddPermissionDelta } from './add-permission.delta';
import { RoleAssignmentDto } from '../../../../dto/role-assignment.dto';
import { Userspace } from '../../../../model/userspace.service';
import { FromIndex } from '../../../../model/relink/managed-entity';
import { GrafitiRoleAssignment } from '../../../../model/entity/grafiti-role-assignment';
import { GrafitiRoleAssignmentHolder } from '../../../../model/entity/grafiti-role-assignment-holder';
import { GrafitiProject } from '../../../../model/entity/grafiti-project';
import { GrafitiCommunity } from '../../../../model/entity/grafiti-community';
import { ActivatedRoute, Router } from '@angular/router';

@DeltaType('removePermission')
export class RemovePermissionDelta extends Delta {
  @JsonIgnore()
  @StaticInject(Userspace)
  private readonly userspace: Userspace;

  @JsonIgnore()
  @StaticInject(Router)
  private readonly router: Router;

  @JsonIgnore()
  @StaticInject(ActivatedRoute)
  private readonly route: ActivatedRoute;

  @JsonIgnore()
  private dto: RoleAssignmentDto;

  @JsonIgnore()
  @FromIndex<RemovePermissionDelta>((self) => self.assignmentId)
  private roleAssignment: GrafitiRoleAssignment;

  @JsonIgnore()
  @FromIndex<RemovePermissionDelta>((self) => self.dto.holder)
  private holder: GrafitiRoleAssignmentHolder;

  protected assignmentId: UUID;

  public constructor(assignmentId: UUID) {
    super();
    this.assignmentId = assignmentId ?? this.assignmentId;
  }

  public apply(): void {
    const assignment = this.roleAssignment;
    this.requireNonNullish(assignment, 'Assignment does not exist');
    this.dto = assignment.buildDto();
    if (!this.holder) {
      return;
    }
    this.holder.setRoleAssignments(this.holder.getRoleAssignments().filter((ra) => ra.getId() !== this.assignmentId));
    this.roleAssignment.destroy();

    if (!this.holder.hasPermission('READ')) {
      if (this.holder instanceof GrafitiProject) {
        const community = this.holder.getCommunity();
        community.setProjects(community.getProjects().filter((c) => c.getId() !== this.holder.getId()));

        const projectId =
          this.route.snapshot.firstChild?.firstChild?.firstChild?.firstChild?.firstChild?.firstChild?.paramMap?.get(
            'pid'
          ) ?? this.route.snapshot.firstChild?.firstChild?.firstChild?.paramMap.get('pid');

        if (projectId === this.holder.getId().toString()) {
          this.router.navigate([`/pages/community/${community.getId()}/overview`]).then();
        }
      } else if (this.holder instanceof GrafitiCommunity) {
        this.userspace.getCommunities().remove(this.holder);

        const communityId = this.route.snapshot.firstChild?.firstChild?.firstChild?.firstChild?.paramMap?.get('cid');
        if (communityId === this.holder.getId().toString()) {
          this.router.navigate(['/pages/home']).then();
        }
      }
      this.holder.destroy();
    }
  }

  public onConfirmation(confirmationDelta: Delta): void {}

  public navigateHome(): void {}

  public override getUndoDelta(): Delta {
    return new AddPermissionDelta(this.dto);
  }

  public override getErrorMessage(): string {
    return `Berechtigung konnte nicht entfernt werden!`;
  }
}
