import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, pairwise, tap } from 'rxjs/operators';
import { ValueSubject } from '../util/reactive/value-subject';
import { Model } from '../model/entity/model';
import { UUID } from '../util/math/uuid';
import { InjectionToken } from '@angular/core';

export interface IEditorSelection {
  select(items: Selectable[]): void;

  add(item: Selectable): void;

  remove(item: Selectable): void;

  has(item: Selectable): boolean;

  getItems(): Selectable[];

  getSingleItem(): Selectable;

  getSingleItem$(): Observable<Selectable>;

  getItems$(): Observable<Selectable[]>;

  isSelected$(el: Selectable): Observable<boolean>;

  getSelectionChange$(): Observable<SelectionChange>;
  selectById(id: UUID): void;
  idSelected$(id: UUID): Observable<void>;
}

export const SELECTION_TOKEN = new InjectionToken<any>('selection');

export class EditorSelection implements IEditorSelection {
  private selectId$ = new Subject<UUID>();
  private items$ = new ValueSubject<Selectable[]>([]);
  private selectionChange$ = this.items$.pipe(
    pairwise(),
    map(([last, current]) => {
      const removed = last.difference(current);
      const added = current.difference(last);
      return {
        removed,
        added,
      } as SelectionChange;
    })
  );

  public constructor() {
    this.select([]);
  }

  public select(items: Selectable[]): void {
    this.items$.next(items);
  }

  public add(item: Selectable): void {
    this.select([...this.getItems(), item].distinct());
  }

  public remove(item: Selectable): void {
    this.select(this.getItems().filter((s) => s.getId() !== item.getId()));
  }

  public has(item: Selectable): boolean {
    return !!this.getItems().find((s) => s.getId() === item.getId());
  }

  public getItems(): Selectable[] {
    return this.items$.getValue();
  }

  public getSingleItem(): Selectable {
    return this.getItems().length >= 1 ? this.getItems()[0] : null;
  }

  public getSingleItem$(): Observable<Selectable> {
    return this.items$.pipe(map((items) => (items.length >= 1 ? items[0] : null)));
  }

  public getItems$(): Observable<Selectable[]> {
    return this.items$.asObservable();
  }

  public isSelected$(el: Selectable): Observable<boolean> {
    return this.items$.pipe(
      map((sel) => !!sel.find((e) => e === el)),
      distinctUntilChanged()
    );
  }

  public getSelectionChange$(): Observable<SelectionChange> {
    return this.selectionChange$;
  }

  public selectById(id: UUID): void {
    this.selectId$.next(id);
  }

  public idSelected$(id: UUID): Observable<void> {
    return this.selectId$.pipe(
      filter((i) => i === id),
      map(() => null)
    );
  }
}

export interface Selectable {
  getSelectionTitle(): string;
  getTitle$(): Observable<string>;
  getData(): Model;
  getId(): String;
}

export function isSelectable(obj: unknown): obj is Selectable {
  return (
    typeof obj['getSelectionTitle'] === 'function' &&
    typeof obj['getTitle$'] === 'function' &&
    typeof obj['getData'] === 'function' &&
    typeof obj['getId'] === 'function'
  );
}

export interface SelectionChange {
  removed: Selectable[];
  added: Selectable[];
}
