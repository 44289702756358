export function safePath(...segments: string[]): string {
  const startWithProtocol = segments[0].includes('://');
  let proto: string;
  if (startWithProtocol) {
    proto = segments.shift();
    const protoPart = proto.match(HAS_PROTOCOL);
    const prefix = proto.substring(0, protoPart[1].length + 2);
    const suffix = proto.substring(protoPart[1].length + 2);
    segments.unshift(suffix);
    proto = prefix;
  }

  let combined = '/' + segments.filter((e) => !!e).join('/');
  while (combined.includes('//')) {
    combined = combined.replace('//', '/');
  }
  if (startWithProtocol) {
    combined = proto + combined;
  }
  return combined;
}

const HAS_PROTOCOL = /(\w*):\/\/(\w*)/;
