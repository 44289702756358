import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, of, ReplaySubject } from 'rxjs';
import { CommunityController } from '../communication/community-controller';
import { GrafitiCommunity } from './entity/grafiti-community';
import { catchError, filter } from 'rxjs/operators';
import { UUID } from '../util/math/uuid';
import { ManagedEntity } from './relink/managed-entity';
import { HttpErrorResponse } from '@angular/common/http';
import { Userspace } from './userspace.service';
import { diff } from '../util/reactive/diff';

@Injectable({
  providedIn: 'root',
})
export class CommunityLoaderService {
  private readonly communities$ = new BehaviorSubject<GrafitiCommunity[]>([]);

  public constructor(private readonly communityController: CommunityController, private readonly userspace: Userspace) {
    this.userspace
      .getCommunities()
      .array$.pipe(diff())
      .subscribe((changes) => {
        changes.removed.forEach((toRemove) => {
          this.communities$.next(
            this.communities$.getValue().filter((community) => community.getId() !== toRemove.getId())
          );
        });
      });
  }

  public getCommunity$(id: UUID): Observable<GrafitiCommunity> {
    return this.communities$.pipe(
      map((communities) => communities.find((c) => c.getId() === id)),
      filter((e) => !!e)
    );
  }

  public fetchCommunity$(id: UUID, includeContent: boolean, force = false): Observable<GrafitiCommunity> {
    {
      let existing = this.communities$.getValue().find((c) => c.getId() === id);
      if (!existing && ManagedEntity.findFromIndex(id)) {
        existing = ManagedEntity.findFromIndex<GrafitiCommunity>(id);
        if (existing) {
          this.communities$.next([...this.communities$.getValue(), existing]);
        }
      }
    }

    const ret = new ReplaySubject<GrafitiCommunity>(1);
    const loaded = this.hasCommunity(id);

    if (!loaded || force) {
      this.communityController
        .getById(id, !includeContent)
        .pipe(catchError((err) => of(err as HttpErrorResponse)))
        .subscribe((dto) => {
          if (dto instanceof HttpErrorResponse) {
            ret.error(dto);
            return;
          }
          let community = ManagedEntity.findFromIndex<GrafitiCommunity>(id);
          if (community) {
            community.update(dto);
          } else {
            community = new GrafitiCommunity(dto);
            this.userspace.getCommunities().add(community);
          }

          if (dto.content) {
            // community.setLoaded(true)
          }
          ret.next(community);
          ret.complete();
        });
    } else {
      const community = this.getCommunity(id);
      ret.next(community);
      ret.complete();
    }

    return ret;
  }

  public hasCommunity(id: UUID): boolean {
    return this.communities$.getValue().some((p) => p.getId() === id);
  }

  public getCommunity(id: UUID): GrafitiCommunity {
    return this.communities$.getValue().find((p) => p.getId() === id);
  }
}
