import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterModule, RouterStateSnapshot } from '@angular/router';

import { GrLoginComponent } from './login/gr-login.component';
import { GrLogoutComponent } from './logout/gr-logout.component';
import { GrRegisterComponent } from './register/gr-register.component';
import { GrRequestPasswordComponent } from './request-password/request-password.component';
import { GrConfirmResetComponent } from './confirm-reset/confirm-reset.component';
import { AuthInterceptor } from './auth.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { GrAuthEntryComponent } from './auth-entry/auth-entry.component';
import { ThemeModule } from '../@theme/theme.module';
import { StorageService } from '../storage.service';
import { noop } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { OnResetCodeSentComponent } from './on-reset-code-sent/on-reset-code-sent.component';
import { TelegramLoginWidgetComponent } from '../telegram/telegram-login-widget.component';
import { TelegramLoginService } from '../telegram/telegram-login.service';
import { MatListModule } from '@angular/material/list';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    ThemeModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    TranslateModule,
    FontAwesomeModule,
    TelegramLoginWidgetComponent,
    MatListModule,
  ],
  declarations: [
    // ... here goes our new components
    GrAuthEntryComponent,
    GrLoginComponent,
    GrLogoutComponent,
    GrRegisterComponent,
    GrRequestPasswordComponent,
    GrConfirmResetComponent,
    OnResetCodeSentComponent,
  ],
  providers: [
    AuthenticationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
})
export class AuthenticationModule {
  public constructor(
    authService: AuthenticationService,
    storageService: StorageService,
    private readonly router: Router,
    private readonly telegramLoginService: TelegramLoginService
  ) {
    telegramLoginService.init();
    authService.autoLogin(false).subscribe(
      (obs) => {},
      (error) => {
        storageService.setSessionId(null);
        storageService.setCurrentUser(null);
        if (document.location.pathname.startsWith('/auth/')) {
          return;
        }
        const returnUrl = (() => {
          if (this.router.lastSuccessfulNavigation === null) {
            return document.location.pathname;
          }
          const url = this.router.routerState.snapshot.url;
          if (!url || url.length === 0) {
            return undefined;
          }
          return url;
        })();
        this.router.lastSuccessfulNavigation;
        router
          .navigate(['/auth/login'], {
            queryParamsHandling: 'merge',
            queryParams: {
              returnUrl,
            },
          })
          .then(noop);
      }
    );
  }
}
