import { JsonObject } from './json';
import { Delta } from '../communication/editing/delta/delta';

export const deltaTypes: { [key: string]: new (...args) => Delta } = {};

/**
 * Sets the type of a delta class.
 * Implicitly contains the {@link JsonObject} decorator
 *
 * @param name type name of the delta
 */
export function DeltaType(name: string): Function {
  const jsonAnnotation = JsonObject();
  return function <T extends Delta>(constructor: new (...args) => T): void {
    jsonAnnotation(constructor);

    if (deltaTypes[name]) {
      throw new Error(`Trying to add multiple Deltas with same name (${name})`);
    }

    deltaTypes[name] = constructor;
    (constructor.prototype as Delta).getType = () => name;
  };
}
