import { Delta } from '../delta';
import { DeltaType } from '../../../../util/delta-type.annotation';
import { UUID } from '../../../../util/math/uuid';
import { CreateFlyerRunDelta } from './create-flyer-run-delta';
import { FlyerRunDto } from '../../../../dto/flyer-run.dto';
import { JsonIgnore } from '../../../../util/json';
import { FromIndex } from '../../../../model/relink/managed-entity';
import { GrafitiFlyerRun } from '../../../../model/entity/grafiti-flyer-run';

@DeltaType('deleteFlyerRun')
export class DeleteFlyerRunDelta extends Delta {
  public flyerRunId: UUID;

  @JsonIgnore()
  @FromIndex<DeleteFlyerRunDelta>((self) => self.flyerRunId)
  private flyerRun: GrafitiFlyerRun;

  @JsonIgnore()
  private flyerRunDto: FlyerRunDto;

  public constructor(flyerRunDto: FlyerRunDto) {
    super();
    this.flyerRunId = flyerRunDto?.id ?? this.flyerRunId;
  }

  public apply(): void {
    this.flyerRunDto = this.flyerRun.buildDto();
    const project = this.flyerRun.getProject();
    project.setFlyerRuns(project.getFlyerRuns().filter((run) => run.getId() !== this.flyerRunId));
    this.flyerRun.destroy();
  }

  public onConfirmation(confirmationDelta: DeleteFlyerRunDelta): void {}

  public override getUndoDelta(): Delta {
    return new CreateFlyerRunDelta(this.flyerRunDto);
  }

  public override getErrorMessage(): string {
    return `Flyerbereich konnte nicht gelöscht werden!`;
  }
}
