import { AfterContentInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { LifecycleComponent } from '../util/lifecycle/lifecycle-component';
import { Breadcrumb, BreadcrumbsService } from './breadcrumbs.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { map } from 'rxjs/operators';

@Component({
  selector: 'gr-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent extends LifecycleComponent implements AfterContentInit {
  private readonly _breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>([]);
  protected readonly breadcrumbs$: Observable<Breadcrumb[]>;

  protected desktop: boolean;

  public constructor(
    private readonly breadcrumbsService: BreadcrumbsService,
    private readonly device: DeviceDetectorService
  ) {
    super();

    this.desktop = device.isDesktop();
    this.breadcrumbs$ = this._breadcrumbs$.pipe(
      map((bcs) => {
        if (this.desktop || bcs.length <= 2) {
          return bcs;
        }
        return bcs.slice(bcs.length - 2);
      })
    );
  }

  public ngAfterContentInit() {
    setTimeout(() => {
      // timeout fixes an aftercontentchecked error
      this.autoCleanup(this.breadcrumbsService.breadcrumbs$).subscribe((breadcrumbs) => {
        this._breadcrumbs$.next(breadcrumbs);
      });
    });
  }

  public override ngOnDestroy() {
    super.ngOnDestroy();
  }
}
