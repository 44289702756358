<mat-card class="grpanel">
  <!--<mat-card-title class="tw-font-bold">
    <h1 id="title" class="title">{{ 'AUTH.REGISTER.TITLE' | translate }}</h1>
  </mat-card-title>-->

  <div class="image-container">
    <img src="assets/images/grafiti-logo-black.png" width="400px" />
  </div>

  <form
    (ngSubmit)="register($event)"
    aria-labelledby="title"
    class="tw-flex tw-flex-col tw-mb-6"
    [formGroup]="registerGroup">
    <mat-form-field>
      <mat-label>{{ 'AUTH.REGISTER.NAME.LABEL' | translate }}</mat-label>
      <input
        matInput
        id="input-name"
        name="fullName"
        placeholder="{{ 'AUTH.REGISTER.NAME.LABEL' | translate }}"
        formControlName="fullName"
        autofocus
        [required]="true"
        [attr.aria-invalid]="fullName.invalid && fullName.touched ? true : null" />
    </mat-form-field>

    <ng-container *ngIf="fullName.invalid && fullName.touched">
      <mat-error *ngIf="fullName.errors?.required">{{ 'AUTH.REGISTER.NAME.ERROR.REQUIRED' | translate }}</mat-error>
      <mat-error *ngIf="fullName.errors?.minlength || fullName.errors?.maxlength">
        {{
          'AUTH.REGISTER.NAME.ERROR.SIZE'
            | translate : { min: config.username.minLength, max: config.username.maxLength }
        }}
      </mat-error>
    </ng-container>

    <mat-form-field>
      <mat-label>{{ 'AUTH.REGISTER.USERNAME.LABEL' | translate }}</mat-label>
      <input
        matInput
        id="input-username"
        name="username"
        placeholder="{{ 'AUTH.REGISTER.USERNAME.LABEL' | translate }}"
        formControlName="username"
        [attr.aria-invalid]="username.invalid && username.touched ? true : null" />
    </mat-form-field>

    <ng-container *ngIf="username.invalid && username.touched">
      <mat-error *ngIf="username.errors?.required">Ein Benutzername ist Pflicht!</mat-error>
      <mat-error *ngIf="username.errors?.pattern">
        Der Benutzername muss mindestens drei Zeichen enthalten, aber nur Kleinbuchstaben und Zahlen
      </mat-error>
    </ng-container>

    <mat-form-field>
      <mat-label>eMail-Adresse</mat-label>
      <input
        matInput
        id="input-email"
        name="email"
        pattern=".+@.+..+"
        placeholder="eMail-Adresse"
        formControlName="email"
        [attr.aria-invalid]="email.invalid && email.touched ? true : null" />
    </mat-form-field>

    <ng-container *ngIf="email.invalid && email.touched">
      <mat-error *ngIf="email.errors?.required">Eine eMail-Adresse ist Pflicht!</mat-error>
      <mat-error *ngIf="email.errors?.pattern">Bitte gibt eine richtige eMail-Adresse an!</mat-error>
    </ng-container>

    <mat-form-field>
      <mat-label>Passwort</mat-label>
      <input
        matInput
        [type]="showPassword ? 'text' : 'password'"
        id="input-password"
        name="password"
        placeholder="Passwort"
        formControlName="password"
        [attr.aria-invalid]="password.invalid && password.touched ? true : null" />
      <button matIconSuffix mat-icon-button (click)="toggleVisibility($event)" type="button">
        <fa-icon [icon]="showPassword ? faEye : faEyeSlash"></fa-icon>
      </button>
    </mat-form-field>
    <ng-container *ngIf="password.invalid && password.touched">
      <mat-error *ngIf="password.errors?.required">Ein Passwort ist Pflicht!</mat-error>
      <mat-error *ngIf="password.errors?.minlength || password.errors?.maxlength">
        Das Passwort sollte {{ config.password.minLength }} bis {{ config.password.maxLength }} Zeichen enthalten
      </mat-error>
    </ng-container>

    <mat-form-field>
      <mat-label>Passwort wiederholen:</mat-label>
      <input
        matInput
        [type]="showPassword ? 'text' : 'password'"
        id="input-re-password"
        name="rePass"
        placeholder="Passwort wiederholen"
        formControlName="rePass"
        [attr.aria-invalid]="registerGroup.errors?.mismatch && rePass.touched ? true : null" />
      <button matIconSuffix mat-icon-button (click)="toggleVisibility($event)" type="button">
        <fa-icon [icon]="showPassword ? faEye : faEyeSlash"></fa-icon>
      </button>
    </mat-form-field>

    <ng-container *ngIf="registerGroup.errors?.mismatch && (rePass.touched || password.touched)">
      <mat-error *ngIf="rePass.errors?.required">Du musst das Passwort bestätigen!</mat-error>
      <mat-error *ngIf="registerGroup.errors?.mismatch"> Die Passwörter stimmen nicht überein. </mat-error>
    </ng-container>

    <!--
    <div class="form-control-group accept-group" *ngIf="getConfigValue('forms.register.terms')">
      <mat-checkbox name="terms" [(ngModel)]="user.terms" [required]="getConfigValue('forms.register.terms')">
        Agree to <a href="#" target="_blank"><strong>Terms & Conditions</strong></a>
      </mat-checkbox>
    </div>-->

    <button
      mat-raised-button
      [disabled]="submitted || registerGroup.invalid || email.invalid || password.invalid || rePass.invalid"
      [class.btn-pulse]="submitted"
      color="accent"
      (click)="register($event)">
      Registrieren
    </button>
  </form>

  <section class="another-action" aria-label="Sign in">
    Hast du bereits einen Account? <a class="text-link" routerLink="../login">Anmelden</a>
  </section>
</mat-card>
