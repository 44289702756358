import { PlacardDto } from '../../dto/placard.dto';
import { GrafitiLocation } from './grafiti-location';
import { GrafitiPlacardType } from './grafiti-placard-type';
import { GrafitiActivity } from './grafiti-activity';
import { UUID } from '../../util/math/uuid';
import { ProjectEntity } from './project-entity';
import { GrafitiProject } from './grafiti-project';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ValueSubject } from '../../util/reactive/value-subject';
import { GrafitiEntity } from './grafiti-entity';
import { Searchable } from './searchable';
import { forContentDefault, GrafitiPermission, AccessManaged } from '../../dto/permission';
import { Sortable } from './sortable';

export class GrafitiPlacard extends GrafitiEntity implements ProjectEntity, Searchable, AccessManaged, Sortable {
  private readonly location$: ValueSubject<GrafitiLocation>;
  private readonly activities$: ValueSubject<GrafitiActivity[]>;

  public constructor(dto: PlacardDto, location: GrafitiLocation) {
    super(dto);

    this.location$ = new ValueSubject<GrafitiLocation>(location, this.destroy$);
    this.activities$ = new ValueSubject<GrafitiActivity[]>(dto.activities.map((a) => new GrafitiActivity(a, this)));
    this.ready();
  }

  public getLocation(): GrafitiLocation {
    return this.location$.getValue();
  }

  public setLocation(location: GrafitiLocation): void {
    this.location$.next(location);
  }

  public getActivities(): GrafitiActivity[] {
    return this.activities$.getValue();
  }

  public getActivities$(): Observable<GrafitiActivity[]> {
    return this.activities$.asObservable();
  }

  public getActivitiesAscendingByDate$(): Observable<GrafitiActivity[]> {
    return this.getActivities$().pipe(
      map((activities) => {
        return activities.sort((a, b) => a.getCreated() - b.getCreated());
      })
    );
  }

  public getActivitiesAscendingByDate(): GrafitiActivity[] {
    return this.getActivities().sort((a, b) => a.getCreated() - b.getCreated());
  }

  public setActivities(activities: GrafitiActivity[]): void {
    this.activities$.next(activities);
  }

  public getLatestPlacardType(): GrafitiPlacardType {
    let activity = this.getActivitiesAscendingByDate().last();
    while (!!activity) {
      let type = activity.getAddedPlacardType();
      if (!!type) {
        return type;
      }
      activity = activity.getPredecessor();
    }
    return null;
  }

  public getLatestActivity(): GrafitiActivity {
    return this.getActivitiesAscendingByDate().last();
  }

  public getLatestImageId(): UUID {
    let activity = this.getActivitiesAscendingByDate().last();
    while (!!activity) {
      let imageId = activity.getImageId();
      if (!!imageId) {
        return imageId;
      }
      activity = activity.getPredecessor();
    }
    return null;
  }

  public getLatestImageId$(): Observable<UUID> {
    return this.getActivities$().pipe(map(() => this.getLatestImageId()));
  }

  public getProject(): GrafitiProject {
    return this.getLocation().getProject();
  }

  public getCurrentMotive$(): Observable<string> {
    return this.getActivitiesAscendingByDate$().pipe(
      map((activities) => {
        const activity = activities.filter((a) => a.getAddedPlacardType()).last();
        if (!activity) {
          return '<NA>';
        }
        // TODO maybe make more reactive
        return activity.getAddedPlacardType().getMotive();
      })
    );
  }

  public getCurrentMotive(): string {
    const activities = this.getActivitiesAscendingByDate();
    const activity = activities.filter((a) => a.getAddedPlacardType()).last();
    if (!activity) {
      return '<NA>';
    }

    return activity.getAddedPlacardType().getMotive();
  }

  public getCurrentImageHeight(): Observable<number> {
    return this.getActivitiesAscendingByDate$().pipe(
      map((activities) => {
        const activity = activities.filter((a) => a.getAddedPlacardType()).last();
        if (!activity) {
          return 250;
        }
        return activity.getImage().height;
      })
    );
  }

  public buildDto(): PlacardDto {
    const dto = super.buildDto() as PlacardDto;
    dto.activities = this.getActivities().map((activity) => activity.buildDto());
    dto.typeId = this.getLatestPlacardType()?.getId(); // optional as this property is not written in constructor
    dto.location = this.getLocation().getId();
    return dto;
  }

  protected destructor(): void {}

  matchesQueryFilters(queries: string): boolean {
    return false;
  }

  matchesTextFilter(filter: string): boolean {
    return this.getLocation().getEntireSearchString().includes(filter);
  }

  public hasPermission(permission: GrafitiPermission): boolean {
    return this.getLocation().hasPermission(forContentDefault(permission));
  }

  public hasPermission$(permission: GrafitiPermission): Observable<boolean> {
    return this.getLocation().hasPermission$(forContentDefault(permission));
  }

  public getSortString(column: string): string {
    switch (column) {
      case 'address':
        return this.getLocation().getStringifiedAddress();
      case 'motive':
        return this.getCurrentMotive();
      default:
        return '';
    }
  }
}
