import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from '../../storage.service';

@Injectable()
export class LoggedInGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly storageService: StorageService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.storageService.hasCurrentUser()) {
      return true;
    }

    if (this.route.snapshot.url[0]?.path.startsWith('/auth')) {
      return true;
    }

    this.router
      .navigate(['/auth/login'], {
        queryParamsHandling: 'merge',
        queryParams: {
          returnUrl: state.url,
        },
      })
      .then();

    return false;
  }
}
