import { GrafitiEntity } from './grafiti-entity';
import { NoteDto } from '../../dto/note.dto';
import { ValueSubject } from '../../util/reactive/value-subject';
import { UUID } from '../../util/math/uuid';
import { combineLatest, Observable, of, withLatestFrom } from 'rxjs';
import { AccessManaged, GrafitiPermission, isAccessManaged, select } from '../../dto/permission';
import { Userspace } from '../userspace.service';
import { StaticInject } from '../../util/lifecycle/static-injector';
import { map } from 'rxjs/operators';
import { ManagedEntity } from '../relink/managed-entity';
import { isNoteHolder, NotesHolder } from '../interface/notes-holder';
import { Class } from '../../util/lifecycle/constructable';

export class GrafitiNote extends GrafitiEntity implements AccessManaged {
  private readonly content$: ValueSubject<string>;
  private readonly locked$: ValueSubject<boolean>;
  private readonly noteIndex$: ValueSubject<number>;
  private readonly objectId: UUID;
  private readonly ownerId: UUID;

  @StaticInject(Userspace)
  private readonly userspace: Userspace;

  public constructor(dto: NoteDto) {
    super({ id: dto.id, created: new Date(dto.created).getTime() });
    this.content$ = new ValueSubject<string>(dto.content, this.destroy$);
    this.noteIndex$ = new ValueSubject<number>(dto.noteIndex);
    this.objectId = dto.objectId;
    this.locked$ = new ValueSubject<boolean>(dto.locked);
    this.ownerId = dto.ownerId;

    this.ready();
  }

  public getContent(): string {
    return this.content$.getValue();
  }

  public getContent$(): Observable<string> {
    return this.content$;
  }

  public setContent(content: string): void {
    this.content$.next(content);
  }

  public getLocked(): boolean {
    return this.locked$.getValue();
  }

  public getLocked$(): Observable<boolean> {
    return this.locked$;
  }

  public setLocked(locked: boolean): void {
    this.locked$.next(locked);
  }

  public getNoteIndex(): number {
    return this.noteIndex$.getValue();
  }

  public getNoteIndex$(): Observable<number> {
    return this.noteIndex$;
  }

  public setNoteIndex(index: number): void {
    this.noteIndex$.next(index);
  }

  public getObjectId(): UUID {
    return this.objectId;
  }

  public getOwnerId(): UUID {
    return this.ownerId;
  }

  public computeHolder<T extends NotesHolder & AccessManaged>(): T {
    const id = this.getObjectId();
    const element = ManagedEntity.findFromIndex(id);
    return element as unknown as T;
  }

  public hasPermission$(permission: GrafitiPermission): Observable<boolean> {
    const holder = ManagedEntity.findFromIndex(this.getObjectId());
    if (isNoteHolder(holder) && isAccessManaged(holder)) {
      return combineLatest([this.getLocked$(), holder.hasPermission$('MANAGE')]).pipe(
        map(([locked, hasManage]) => hasManage || this.hasPermission(permission))
      );
    }
    return this.getLocked$().pipe(map(() => this.hasPermission(permission)));
  }

  public hasPermission(permission: GrafitiPermission): boolean {
    const me = this.userspace.getUserStore().findLoggedInUser();
    if (!me) {
      return false;
    }

    if (me.getId() === this.getOwnerId()) {
      return true;
    }

    if (this.getLocked()) {
      if (this.computeHolder().hasPermission('MANAGE')) {
        return true;
      }
      return permission === 'READ';
    }

    return true;
  }

  public override buildDto(): NoteDto {
    return {
      objectId: this.objectId,
      noteIndex: this.getNoteIndex(),
      content: this.getContent(),
      id: this.getId(),
      ownerId: this.getOwnerId(),
      locked: this.getLocked(),
    };
  }

  protected destructor(): void {}
}
