import { BehaviorSubject, ObjectUnsubscribedError } from 'rxjs';

export class LockableSubject<T> extends BehaviorSubject<T> {
  private locked: boolean = false;
  private lockedCache: T = null;
  private cacheIsHot: boolean = false;

  public lock(): void {
    this.locked = true;
  }

  public unlock(): void {
    this.locked = false;

    if (this.cacheIsHot) {
      this.next(this.lockedCache);
    }
  }

  public isLocked(): boolean {
    return this.locked;
  }

  getValue(): T {
    if (this.hasError) {
      throw this.thrownError;
    } else if (this.closed) {
      throw new ObjectUnsubscribedError();
    } else if (this.locked) {
      return this.lockedCache;
    } else {
      return super.getValue();
    }
  }

  next(value: T): void {
    this.lockedCache = value;
    if (this.locked) {
      this.cacheIsHot = true;
    } else {
      super.next(value);
    }
  }
}
