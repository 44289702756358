import { NgModule } from '@angular/core';
import { FileInputComponent } from '../shared-ui/file-input/file-input.component';
import { MatIconModule } from '@angular/material/icon';
import { AsyncPipe, NgIf } from '@angular/common';
import { SharedUiModule } from '../shared-ui/shared-ui.module';

@NgModule({
  declarations: [FileInputComponent],
  exports: [FileInputComponent],
  imports: [MatIconModule, NgIf, AsyncPipe],
})
export class UtilitiesUiModule {}
