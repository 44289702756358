import { Delta } from '../delta';
import { DeltaType } from '../../../../util/delta-type.annotation';
import { JsonIgnore } from '../../../../util/json';
import { FromIndex } from '../../../../model/relink/managed-entity';
import { GrafitiProject } from '../../../../model/entity/grafiti-project';
import { ResponsibilityLinkDto } from '../../../../dto/responsibility-link.dto';
import { UUID } from '../../../../util/math/uuid';

@DeltaType('createResponsibilityLink')
export class CreateRepsonsibilityLinkDelta extends Delta {
  @JsonIgnore()
  @FromIndex<CreateRepsonsibilityLinkDelta>((self) => self.projectId)
  private project: GrafitiProject;

  public projectId: UUID;
  public dto: ResponsibilityLinkDto;

  constructor(dto: ResponsibilityLinkDto, projectId: UUID) {
    super();
    this.dto = dto ?? this.dto;
    this.projectId = projectId ?? this.projectId;
  }

  public apply(): void {
    const area = this.project.getResponsibilityAreas().find((area) => area.getId() === this.dto.areaId);
    this.requireTrue(area.getProject().getId() === this.projectId, 'invalid project');

    const existing = area
      .getResponsibilityLinks()
      .some((link) => link.id === this.dto.id || (link.areaId === this.dto.areaId && link.userId === this.dto.userId));

    this.requireFalse(existing, `responsibility link ${this.dto.id} is already existing!`);

    this.project.setResponsibilityLinks([...this.project.getResponsibilityLinks(), this.dto]);
  }

  public override onConfirmation(confirmationDelta: CreateRepsonsibilityLinkDelta) {}

  public override getUndoDelta(): Delta {
    return null;
  }

  public override getErrorMessage(): string {
    return `Neuer Zuständigkeitsbereich konnte nicht gespeichert werden!`;
  }
}
