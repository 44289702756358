import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export interface ButtonDefinition<T> {
  text: string;
  value: T;
  class?: string[];
  primary?: boolean;
}

export interface OptionsDialogData<T = unknown> {
  title: string;
  text: string;
  options: ButtonDefinition<T>[];
  translateParams?: Record<string, unknown>;
}

/**
 * "Calculates" the possible return values of the input ButtonDefinitions.
 * This is used to narrow the return type of the dialog.
 *
 * @example
 * ButtonValues<[{text: 'Button 1', value: 1}, {text: 'Button 2', value: 2}]> = 1 | 2;
 */
export type ButtonValues<Options extends ButtonDefinition<unknown>[]> = {
  [I in keyof Options]: Options[I] extends ButtonDefinition<unknown> ? Options[I]['value'] : never;
}[keyof Options & number];

@Component({
  selector: 'hwe-options-dialog',
  templateUrl: 'options-dialog.component.html',
})
export class OptionsDialogComponent {
  /**
   * Fontawesome icons
   */
  public readonly faTimes = faTimes;

  public title: string;
  public text: string;
  public translateParams: Record<string, unknown>;

  public buttons: ButtonDefinition<unknown>[];

  public constructor(@Inject(MAT_DIALOG_DATA) public data: OptionsDialogData) {
    this.title = data.title;
    this.text = data.text;
    this.buttons = data.options;
    this.translateParams = data.translateParams;
  }
}
