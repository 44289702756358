import { Delta } from '../delta';
import { DeltaType } from '../../../../util/delta-type.annotation';
import { UUID } from '../../../../util/math/uuid';
import { FromIndex } from '../../../../model/relink/managed-entity';
import { GrafitiActivity } from '../../../../model/entity/grafiti-activity';

@DeltaType('updateMultiplier')
export class UpdateMultiplierDelta extends Delta {
  @FromIndex<UpdateMultiplierDelta>((self) => self.activityId)
  private activity: GrafitiActivity;

  private activityId: UUID;
  private oldMultiplier: number;
  private newMultiplier: number;

  public constructor(activityId: UUID, oldMultiplier: number, newMultiplier: number) {
    super();
    this.oldMultiplier = oldMultiplier ?? this.oldMultiplier;
    this.newMultiplier = newMultiplier ?? this.newMultiplier;
    this.activityId = activityId ?? this.activityId;
  }

  apply(): void {
    if (this.oldMultiplier !== this.activity.getMultiplier()) {
      return;
    }

    this.activity.setMultiplier(this.newMultiplier);
  }

  onConfirmation(confirmationDelta: Delta): void {}

  getErrorMessage(): string {
    return 'could not update multiplier for activity ' + this.activityId;
  }

  public getUndoDelta(): Delta {
    return new UpdateMultiplierDelta(this.activityId, this.newMultiplier, this.oldMultiplier);
  }
}
