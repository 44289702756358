import { PrivateUserDto } from '../../../../dto/private-user.dto';
import { Delta } from '../delta';
import { DeltaType } from '../../../../util/delta-type.annotation';
import { JsonIgnore } from '../../../../util/json';
import { StaticInject } from '../../../../util/lifecycle/static-injector';
import { DeleteUserDelta } from './delete-user.delta';
import { Userspace } from '../../../../model/userspace.service';
import { GrafitiUser } from '../../../../model/entity/grafiti-user';

@DeltaType('createUser')
export class CreateUserDelta extends Delta {
  @JsonIgnore()
  @StaticInject(Userspace)
  private readonly userspace: Userspace;

  public userDto: PrivateUserDto;

  public constructor(userDto: PrivateUserDto) {
    super();

    this.userDto = userDto;
  }

  public apply(): void {
    const user = new GrafitiUser(this.userDto);
    this.userspace.getUserStore().save(user);
  }

  public override onConfirmation(confirmationDelta: Delta): void {}

  public getUndoDelta(): Delta {
    return new DeleteUserDelta(this.userDto.id);
  }

  public override getErrorMessage(): string {
    return `Neuer Benutzer '${this.userDto.username}' konnte nicht erstellt werden!`;
  }
}
