import { Component, OnInit } from '@angular/core';
import { AUTH_CONFIG, AuthConfigType } from '../auth-config';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { LoginService } from '../../login/login.service';
import { ToastrService } from 'ngx-toastr';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { of } from 'rxjs';
import { CommonErrors } from '../../util/result';
import { TelegramLoginService } from '../../telegram/telegram-login.service';
import { LifecycleComponent } from '../../util/lifecycle/lifecycle-component';
import { delay, switchMap } from 'rxjs/operators';
// import WebApp from '@twa-dev/sdk';

@Component({
  selector: 'gr-login',
  templateUrl: 'gr-login.component.html',
  styleUrls: ['gr-login.component.scss'],
})
export class GrLoginComponent extends LifecycleComponent implements OnInit {
  protected submitted: boolean;
  protected pending: boolean;
  protected formGroup: FormGroup;

  protected rememberMe = true;

  protected showPassword = false;
  protected faEye = faEye;
  protected faEyeSlash = faEyeSlash;

  protected config: AuthConfigType = AUTH_CONFIG;
  protected loginError: string = null;

  protected openedFromTelegram = false;
  protected tgDevelopmentOpts: string[] = [];

  constructor(
    protected authService: AuthenticationService,
    private readonly loginService: LoginService,
    private readonly route: ActivatedRoute,
    private readonly toastr: ToastrService,
    private readonly formBuilder: FormBuilder,
    private readonly telegramLoginService: TelegramLoginService
  ) {
    super();
    this.formGroup = formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
      // rememberMe: [false],
    });
  }

  public ngOnInit() {
    this.loginService.setReturnURL(this.route.snapshot.queryParams['returnUrl'] || '/');
    this.authService.autoLogin(false).subscribe({
      next: () => {
        this.loginService.forward();
        this.pending = false;
      },
      error: () => {
        this.pending = false;
      },
    });

    this.autoCleanup(this.telegramLoginService.result$)
      .pipe(
        switchMap((error) => {
          if (error) {
            this.toastr.error(error.message, 'Login fehlgeschlagen!');
            return of();
          }
          this.toastr.success('Du wirst in einigen Sekunden automatisch eingeloggt!', 'Login erfolgreich');
          return this.authService.autoLogin(true);
        }),
        delay(1000)
      )
      .subscribe(() => this.loginService.forward());

    // TODO maybe this should be sourced out
    this.openedFromTelegram = location.search
      .substring(1)
      .split('&')
      .some((query) => {
        const [k, v] = query.split('=');
        return k === 'telegram' && v === 'true';
      });

    this.tgDevelopmentOpts = location.search
      .substring(1)
      .split('&')
      .flatMap((query) => {
        const [k, v] = query.split('=');
        return k === 'tgdopts' ? v.split(',') : [];
      })
      .map((opt) => opt.toLowerCase());

    /* TODO re-add if we ever support the webapp
    if (this.openedFromTelegram) {
      WebApp.ready();
      WebApp.expand();
    }*/
  }

  protected login(): void {
    this.pending = true;
    this.authService
      .login(this.formGroup.get('username').value, this.formGroup.get('password').value, this.rememberMe)
      .unwrap((err: CommonErrors) => {
        this.pending = false;
        // this.loginError = error.type;
        this.loginError = err.message;
        this.toastr.error(err?.message ?? 'Login unsuccessful');
        return of(null);
      })
      .subscribe((userDto) => {
        if (!userDto) {
          return;
        }
        this.pending = false;
        setTimeout(() => {
          this.loginService.forward();
        }, 100);
      });
  }

  protected toggleVisibility(evt: Event): void {
    this.showPassword = !this.showPassword;
  }
}
