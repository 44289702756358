/**
 * Represents a basic service to communicate with either the REST or
 * web socket server connection.
 */
import { safePath } from '../util/path';
import { environment } from '../../environments/environment';

export abstract class AbstractCommunicationService {
  private static domain: string = AbstractCommunicationService.findDomain();
  private static serverBase: string = AbstractCommunicationService.findBasePath();
  private static serverUrl: string = AbstractCommunicationService.domain + AbstractCommunicationService.serverBase;

  private readonly suffix: string;

  /**
   * Creates a new AbstractCommunicationService.
   *
   * @param suffix The suffix of the server URL.
   */
  protected constructor(suffix: string) {
    this.suffix = suffix ? suffix : '';
  }

  /**
   * Returns the basic server url like 'http://example.com/'.
   */
  public static getDomain(): string {
    return this.domain;
  }

  /**
   * Returns the base part of the url ('/' or 'hwe/').
   */
  public static getBasePath(): string {
    return this.serverBase;
  }

  /**
   * Returns the basic server url including the base path: 'http://example.com/base/'
   */
  public static getServerUrl(): string {
    return this.serverUrl;
  }

  /*public static updateServerInformation(serverDomain: string, serverBase = '/'): void {
    this.domain = serverDomain;
    this.serverBase = serverBase;
    this.serverUrl = this.domain + this.serverBase;
  }*/

  public static queryBasePathFromIndex(): string | null {
    const baseElements = window.document.getElementsByTagName('base');
    if (baseElements.length > 0) {
      const baseUrl = baseElements[0].href;
      if (baseUrl !== undefined && baseUrl.length > 0) {
        return this.extractPath(baseUrl);
      }
    }
    return null;
  }

  public static findDomain(includeProtocol = true): string {
    /*if (environment.serverUrl) {
      return environment.serverUrl;
    }*/

    /*const baseElements = window.document.getElementsByTagName('base');
    if (baseElements.length > 0) {
      const baseUrl = baseElements[0].href;
      if (baseUrl !== undefined && baseUrl.length > 0) {
        return this.extractDomain(baseUrl);
      }
    }*/

    const ret = `${includeProtocol ? window.location.protocol + '//' : ''}${window.location.hostname}${
      environment.backendPort ? ':' + environment.backendPort : window.location.port ? ':' + window.location.port : ''
    }`;

    return ret;
  }

  public static findScheme(): 'http' | 'https' {
    return window.location.protocol.replace(':', '') as 'http' | 'https';
  }

  private static findBasePath(): string {
    if (environment.basePath) {
      return environment.basePath;
    }

    return this.queryBasePathFromIndex() ?? '/';
  }

  private static extractDomain(url: string): string {
    const httpsPrefixLength = 'https://'.length;
    const index = url.indexOf('/', httpsPrefixLength);
    if (index >= 0) {
      return url.substring(0, index);
    } else {
      return url;
    }
  }

  private static extractPath(url: string): string {
    const domainLength = this.extractDomain(url).length;
    return url.substring(domainLength);
  }

  /**
   * Returns the encoded base URL of this service. ('http://example.com/base/suffix')
   */
  public getBaseUrl(): string {
    return AbstractCommunicationService.getServerUrl() + safePath(this.suffix);
  }

  /**
   * Builds the full REST URL ending with the given REST path.
   *
   * @param restPath The server's REST path.
   * @return The full REST URL.
   */
  protected getUrl(restPath: string): string {
    return safePath(this.getBaseUrl(), restPath);
  }
}
