import { Delta } from '../delta';
import { UUID } from '../../../../util/math/uuid';
import { InviteLinkDto } from '../../../../dto/invite-link.dto';
import { FromIndex } from '../../../../model/relink/managed-entity';
import { GrafitiCommunity } from '../../../../model/entity/grafiti-community';
import { DeltaType } from '../../../../util/delta-type.annotation';

@DeltaType('createInviteLink')
export class CreateInvitelinkDelta extends Delta {
  private communityId: UUID;
  private dto: InviteLinkDto;

  @FromIndex<CreateInvitelinkDelta>((self) => self.communityId)
  private community: GrafitiCommunity;

  public constructor(holderId: UUID) {
    super();

    this.communityId = holderId ?? this.communityId;
    this.dto = {
      created: null,
      code: null,
      expiryDate: null,
      id: UUID.randomUUID(),
      communityId: this.communityId,
    };
  }

  public apply(): void {}

  public onConfirmation(confirmationDelta: Delta): void {
    this.requireTrue(this.community instanceof GrafitiCommunity, 'Invite links are only implemented for communities');
    const inviteLink = (confirmationDelta as CreateInvitelinkDelta).dto;
    this.community.setInviteLinks([inviteLink]);
  }

  getErrorMessage(): string {
    return 'could not create invite link!';
  }
}
