import { Delta } from '../delta';
import { DeltaType } from '../../../../util/delta-type.annotation';
import { UUID } from '../../../../util/math/uuid';
import { CreateCommunityDelta } from './create-community-delta';
import { CommunityDto } from '../../../../dto/community.dto';
import { JsonIgnore } from '../../../../util/json';
import { FromIndex } from '../../../../model/relink/managed-entity';
import { GrafitiCommunity } from '../../../../model/entity/grafiti-community';
import { Userspace } from '../../../../model/userspace.service';
import { StaticInject } from '../../../../util/lifecycle/static-injector';

@DeltaType('deleteCommunity')
export class DeleteCommunityDelta extends Delta {
  @JsonIgnore()
  @FromIndex<DeleteCommunityDelta>((self) => self.communityId)
  private readonly community: GrafitiCommunity;

  @JsonIgnore()
  @StaticInject(Userspace)
  private userspace: Userspace;

  @JsonIgnore()
  private communityDto: CommunityDto;

  public communityId: UUID;

  public constructor(communityId: UUID) {
    super();
    this.communityId = this.communityId ?? communityId;
  }

  public apply(): void {
    const toDelete = this.community;
    this.communityDto = toDelete.buildDto();
    this.userspace.getCommunities().remove(toDelete);
    toDelete.destroy();
  }

  public onConfirmation(confirmationDelta: DeleteCommunityDelta): void {}

  public override getUndoDelta(): Delta {
    return new CreateCommunityDelta(this.communityDto);
  }

  public override getErrorMessage(): string {
    return `Community '${this.communityDto.name}' konnte nicht gelöscht werden!`;
  }
}
