import { Delta } from '../delta';
import { DeltaType } from '../../../../util/delta-type.annotation';
import { JsonIgnore } from '../../../../util/json';
import { FromIndex } from '../../../../model/relink/managed-entity';
import { GrafitiProject } from '../../../../model/entity/grafiti-project';
import { ResponsibilityAreaDto } from '../../../../dto/responsibility-area.dto';
import { GrafitiResponsibilityArea } from '../../../../model/entity/grafiti-responsibility-area';
import { DeleteResponsibilityAreaDelta } from './delete-responsibility-area-delta';

@DeltaType('createResponsibilityArea')
export class CreateRepsonsibilityAreaDelta extends Delta {
  @JsonIgnore()
  @FromIndex<CreateRepsonsibilityAreaDelta>((self) => self.dto.projectId)
  private project: GrafitiProject;

  public dto: ResponsibilityAreaDto;

  constructor(dto: ResponsibilityAreaDto) {
    super();
    this.dto = this.dto ?? dto;
  }

  public apply(): void {
    const responsibilityArea = new GrafitiResponsibilityArea(this.dto, this.project);
    this.project.setResponsibilityAreas([...this.project.getResponsibilityAreas(), responsibilityArea]);
  }

  public override onConfirmation(confirmationDelta: CreateRepsonsibilityAreaDelta) {
    this.project
      .getResponsibilityAreas()
      .find((a) => a.getId() === this.dto.id)
      .updateRoleAssignments(confirmationDelta.dto.roleAssignments);
  }

  public override getUndoDelta(): Delta {
    return new DeleteResponsibilityAreaDelta(this.dto.id);
  }

  public override getErrorMessage(): string {
    return `Neuer Zuständigkeitsbereich konnte nicht gespeichert werden!`;
  }
}
