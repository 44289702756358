import { Delta } from '../delta';
import { DeltaType } from '../../../../util/delta-type.annotation';
import { UUID } from '../../../../util/math/uuid';
import { InteractionState } from '../../../../model/entity/grafiti-user-interaction';
@DeltaType('changeInteractionState')
export class ChangeInteractionStateDelta extends Delta {
  private newState: InteractionState;
  private interactionId: UUID;
  private response: string;

  public constructor(id: UUID, newState: InteractionState) {
    super();
    this.interactionId = id ?? this.interactionId;
    this.newState = newState ?? this.newState;
  }

  public apply(): void {
    throw new Error('Not implemented');
    /*const interaction = this.interactionRepository.find(this.interactionId);
    if (interaction) {
      interaction.setState(this.newState);
    }
    if (this.response) {
    }*/
  }

  getErrorMessage(): string {
    return '';
  }

  onConfirmation(confirmationDelta: ChangeInteractionStateDelta): void {
    if (this.response) {
    }
  }
}
