import { Delta } from './delta';
import { DeltaType } from '../../../util/delta-type.annotation';
import { PropertyDiff } from '../property-diff';
import { UUID } from '../../../util/math/uuid';
import { getGetterName, getSetterName } from '../../../util/getter';

@DeltaType('editProperty')
export abstract class EditPropertyDelta<Property = string, T = string | number | boolean> extends Delta {
  public entityId: UUID;

  public diff: PropertyDiff<Property>[];

  protected forEachDiff<T>(entity: T, depth = 1): void {
    const overwrites = this.getOverwrites();
    this.diff.forEach((diff) => {
      const propName = diff.name.toString();

      const oldValue = overwrites[propName] ? overwrites[propName].getter() : getProperty(entity, propName);

      if (oldValue === undefined) {
        throw new Error(`Entity ${entity} has no property named '${propName}'!`);
      }

      this.requireDeepEquals(
        diff.oldValue,
        oldValue,
        depth,
        `Old value for key '${diff.name.toString()}' in entity (${oldValue}) and old value in delta (${
          diff.oldValue
        }) did not match!`
      );

      if (overwrites[propName]) {
        overwrites[propName].setter(diff.newValue);
      } else {
        setProperty(entity, propName, diff.newValue);
      }
    });
  }

  public getOverwrites(): { [id: string]: SetterGetterOverwrite } {
    return {};
  }
}

function getProperty(obj: unknown, propertyIdentifier: string): unknown {
  const getterIdentifier = propertyIdentifier.startsWith('get')
    ? propertyIdentifier
    : getGetterName(propertyIdentifier);

  if (typeof obj[getterIdentifier] === 'function') {
    return obj[getterIdentifier]();
  }

  if (typeof obj[getterIdentifier] !== 'function') {
    return obj[getterIdentifier];
  }
  return undefined;
}

function setProperty<T>(obj: unknown, propertyIdentifier: string, newVal: T): void {
  const setterIdentifier = propertyIdentifier.startsWith('set')
    ? propertyIdentifier
    : getSetterName(propertyIdentifier);
  if (typeof obj[setterIdentifier] === 'function') {
    obj[setterIdentifier](newVal);
    return;
  }

  if (obj[propertyIdentifier] !== undefined && typeof obj[setterIdentifier] !== 'function') {
    try {
      obj[propertyIdentifier] = newVal;
    } catch (e) {
      // retry with setter
      if (typeof obj[setterIdentifier] === 'function') {
        obj[setterIdentifier](newVal);
      }
    }
  }
}

export type SetterGetterOverwrite = { setter: (el: unknown) => void; getter: () => unknown };
