import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { isLanguageSupported, LanguageCode } from './language';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  public constructor(private readonly translate: TranslateService) {}

  public init(): Observable<void> {
    this.translate.setDefaultLang('en');
    const langCode = navigator.language.split('-')[0] as LanguageCode;

    if (isLanguageSupported(langCode)) {
      return this.translate.use(langCode);
    } else {
      return this.translate.use('en');
    }
  }
}
