import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

/**
 * Represents a service for navigating to the correct destination after login
 */
@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private url: string;
  private username: string;
  /**
   * Creates a new LoginService.
   */
  public constructor(public readonly router: Router) {}

  /**
   * Sets the URL to return to once the login is finished.
   * @param url the URL
   */
  public setReturnURL(url: string): void {
    this.url = url;
  }

  /**
   * Executes the previously specified action, should be called once the login is finished.
   */
  public forward(): void {
    if (this.url != null) {
      console.log('@@D');
      this.router
        .navigate([this.url], {
          queryParams: {
            returnUtl: undefined,
          },
        })
        .then(() => {
          this.url = null;
        });
    }
  }

  /**
   * Returns the username of the previously logged user in if displayed within a dialog.
   */
  public getUsername(): string {
    return this.username;
  }
}
