import { RoleAssignmentDto } from '../../dto/role-assignment.dto';
import { Observable } from 'rxjs';
import { RoleDto } from '../../dto/role.dto';
import { UUID } from '../../util/math/uuid';
import { ValueSubject } from '../../util/reactive/value-subject';
import { GrafitiUser } from './grafiti-user';
import { ManagedEntity } from '../relink/managed-entity';
import { StaticInject } from '../../util/lifecycle/static-injector';
import { Userspace } from '../userspace.service';

export class GrafitiRoleAssignment extends ManagedEntity {
  private readonly user$: ValueSubject<GrafitiUser>;

  @StaticInject(Userspace)
  private userspace: Userspace;

  private readonly role$: ValueSubject<RoleDto>;

  public constructor(dto: RoleAssignmentDto) {
    super(dto.id);

    const user = this.userspace.getUserStore().find(dto.user.id) ?? new GrafitiUser(dto.user);
    this.userspace.getUserStore().save(user);
    this.user$ = new ValueSubject<GrafitiUser>(user);

    this.setHolderId(dto.holder);

    this.role$ = new ValueSubject<RoleDto>(dto.role, this.destroy$);
    this.ready();
  }

  public getUser(): GrafitiUser {
    return this.user$.getValue();
  }

  public getUser$(): Observable<GrafitiUser> {
    return this.user$;
  }

  public setUser(user: GrafitiUser): void {
    this.user$.next(user);
  }

  public getRole(): RoleDto {
    return this.role$.getValue();
  }

  public getRole$(): Observable<RoleDto> {
    return this.role$;
  }

  public setRole(role: RoleDto): void {
    this.role$.next(role);
  }

  public get role(): RoleDto {
    return this.getRole();
  }

  public getHolderId(): UUID {
    return this.getEmbeddedAttribute('holder');
  }

  public setHolderId(id: UUID): void {
    this.setEmbeddedAttribute('holder', id);
  }

  public getHolderId$(): Observable<UUID> {
    return this.getEmbeddedAttribute$('holder');
  }

  public getUsername(): string {
    return this.getUser().getUsername();
  }

  protected destructor(): void {}

  public buildDto(): RoleAssignmentDto {
    return {
      id: this.getId(),
      role: this.getRole(),
      user: this.getUser().buildSparseDto(),
      holder: this.getHolderId(),
    };
  }
}
