import { OperatorFunction, startWith } from 'rxjs';
import { map, pairwise } from 'rxjs/operators';

export function diff<T, I>(idFunction?: (e: T) => I): OperatorFunction<T[], DiffResult<T>> {
  return (source) => {
    return source.pipe(
      startWith([] as T[]),
      pairwise(),
      map(([last, current]) => {
        const removed = last.difference(current, idFunction);
        const added = current.difference(last, idFunction);
        return <DiffResult<T>>{
          total: current,
          removed,
          added,
        };
      })
    );
  };
}

interface DiffResult<T> {
  total: T[];
  added: T[];
  removed: T[];
}
