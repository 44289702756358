import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { AUTH_CONFIG, AuthConfigType } from '../auth-config';

@Component({
  selector: 'gr-confirm-reset',
  templateUrl: 'confirm-reset.component.html',
})
export class GrConfirmResetComponent {
  protected submitted = false;
  protected config: AuthConfigType = AUTH_CONFIG;
  protected user: { password: string; confirmPassword: string } = {
    password: '',
    confirmPassword: '',
  };

  public constructor(protected readonly router: Router) {}

  resetPass(): void {}
}
