export class LimitedMap<K, V> extends Map<K, V> {
  private counter: number = 0;
  private order = new Map<K, number>();

  public constructor(private readonly maxSize: number, entries?: readonly (readonly [K, V])[] | null) {
    super(entries);
  }

  public set(key: K, value: V): this {
    if (this.size >= this.maxSize) {
      const oldestKey = this.findOldestKey();
      super.delete(oldestKey);
      this.order.delete(oldestKey);
    }
    this.order.set(key, ++this.counter);
    super.set(key, value);

    return this;
  }

  public override delete(key: K): boolean {
    this.order.delete(key);
    return super.delete(key);
  }
  public override clear() {
    this.order.clear();
    super.clear();
  }

  private findOldestKey(): K {
    const [oldest] = [...this.order.entries()].min(([e, added]) => added);
    return oldest;
  }
}
