import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { AuthenticationService } from '../auth/authentication.service';

@Component({
  selector: 'app-telegram-login-widget',
  template: ` <div #script [style.display]="'none'">
    <ng-content></ng-content>
  </div>`,
  styleUrls: ['./telegram-login-widget.component.scss'],
  standalone: true,
})
export class TelegramLoginWidgetComponent implements AfterViewInit {
  @ViewChild('script', { static: true }) script: ElementRef;

  public constructor(private readonly authService: AuthenticationService) {}

  convertToScript(botName: string) {
    const element = this.script.nativeElement;
    const script = document.createElement('script');
    script.src = 'https://telegram.org/js/telegram-widget.js?5';
    script.setAttribute('data-telegram-login', botName);
    script.setAttribute('data-size', 'large');
    // Callback function in global scope
    script.setAttribute('data-onauth', 'loginViaTelegram(user)');
    script.setAttribute('data-request-access', 'write');
    element.parentElement.replaceChild(script, element);
  }

  ngAfterViewInit() {
    this.authService.fetchTelegramBotName$().subscribe((botName) => {
      this.convertToScript(botName);
    });
  }
}
