import { NgModule } from '@angular/core';
import { BreadcrumbsComponent } from './breadcrumbs.component';
import { BreadcrumbsService } from './breadcrumbs.service';
import { AsyncPipe, NgForOf } from '@angular/common';
import { RouterLink } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [BreadcrumbsComponent],
  imports: [NgForOf, AsyncPipe, RouterLink, MatIconModule, MatButtonModule],
  providers: [],
  exports: [BreadcrumbsComponent],
})
export class BreadcrumbsModule {}
