import { Delta } from '../delta';
import { DeltaType } from '../../../../util/delta-type.annotation';
import { PlacardTypeDto } from '../../../../dto/placard-type.dto';
import { UUID } from '../../../../util/math/uuid';
import { CreatePlacardTypeDelta } from './create-placard-type-delta';
import { JsonIgnore } from '../../../../util/json';
import { FromIndex } from '../../../../model/relink/managed-entity';
import { GrafitiPlacardType } from '../../../../model/entity/grafiti-placard-type';

@DeltaType('deletePlacardType')
export class DeletePlacardTypeDelta extends Delta {
  @JsonIgnore()
  private dto: PlacardTypeDto;

  @JsonIgnore()
  @FromIndex<DeletePlacardTypeDelta>((self) => self.placardTypeId)
  private placardType: GrafitiPlacardType;

  public placardTypeId: UUID;

  public constructor(typeDto: PlacardTypeDto) {
    super();
    this.placardTypeId = typeDto?.id ?? this.placardTypeId;
  }

  public apply(): void {
    this.dto = this.placardType.buildDto();
    const project = this.placardType.getProject();
    const type = this.placardType;
    if (project.getPlacardTypes().filter((pt) => pt.getId() === this.placardTypeId).length > 0) {
      throw new Error('Placard type is in use');
    }
    project.setPlacardTypes(project.getPlacardTypes().filter((p) => p.getId() !== this.placardTypeId));
    this.placardType.destroy();
  }

  public onConfirmation(confirmationDelta: DeletePlacardTypeDelta): void {}

  public getUndoDelta(): CreatePlacardTypeDelta {
    return new CreatePlacardTypeDelta(this.dto);
  }

  public override getErrorMessage(): string {
    return `Plakattyp konnte nicht gelöscht werden`;
  }
}
