import { DeltaType } from '../../../../util/delta-type.annotation';
import { Delta } from '../delta';
import { PlacardTypeDto } from '../../../../dto/placard-type.dto';
import { DeletePlacardTypeDelta } from './delete-placard-type-delta';
import { JsonIgnore } from '../../../../util/json';
import { FromIndex } from '../../../../model/relink/managed-entity';
import { GrafitiProject } from '../../../../model/entity/grafiti-project';
import { GrafitiPlacardType } from '../../../../model/entity/grafiti-placard-type';

@DeltaType('createPlacardType')
export class CreatePlacardTypeDelta extends Delta {
  @JsonIgnore()
  @FromIndex<CreatePlacardTypeDelta>((self) => self.placardTypeDto.project)
  private project: GrafitiProject;

  public placardTypeDto: PlacardTypeDto;

  constructor(placardTypeDto: PlacardTypeDto) {
    super();
    this.placardTypeDto = this.placardTypeDto ?? placardTypeDto;
  }

  public apply(): void {
    if (this.project.getPlacardTypes().find((e) => e.getId() === this.placardTypeDto.id)) {
      return;
    }
    const type = new GrafitiPlacardType(this.placardTypeDto, this.project);
    this.project.setPlacardTypes([...type.getProject().getPlacardTypes(), type]);
  }

  public onConfirmation(confirmationDelta: CreatePlacardTypeDelta) {}

  public override getUndoDelta(): Delta {
    return new DeletePlacardTypeDelta(this.placardTypeDto);
  }

  public override getErrorMessage(): string {
    return `Neuer Plakattyp '${this.placardTypeDto.motive}' konnte nicht gespeichert werden!`;
  }
}
