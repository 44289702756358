import { EditPropertyDelta } from '../edit-property.delta';
import { invertDiffs, PropertyDiff } from '../../property-diff';
import { UUID } from '../../../../util/math/uuid';
import { DeltaType } from '../../../../util/delta-type.annotation';
import { JsonIgnore } from '../../../../util/json';
import { GrafitiCommunity } from '../../../../model/entity/grafiti-community';
import { FromIndex } from '../../../../model/relink/managed-entity';

@DeltaType('editCommunity')
export class EditCommunityDelta extends EditPropertyDelta<keyof GrafitiCommunity> {
  @JsonIgnore()
  @FromIndex<EditCommunityDelta>((self) => self.entityId)
  private community: GrafitiCommunity;

  public constructor(communityId: UUID, diffs: PropertyDiff<keyof GrafitiCommunity>[]) {
    super();
    this.entityId = this.entityId ?? communityId;
    this.diff = this.diff ?? diffs;
  }

  public apply(): void {
    this.diff.forEach((diff) => {
      if (diff.name === 'name') {
        this.community.setName(diff.newValue as string);
      }
      if (diff.name === 'description') {
        this.community.setDescription(diff.newValue as string);
      }
    });
  }

  public onConfirmation(confirmationDelta: EditCommunityDelta): void {}

  public getUndoDelta(): EditCommunityDelta {
    return new EditCommunityDelta(this.entityId, invertDiffs(this.diff));
  }

  public override getErrorMessage(): string {
    return `Community konnte nicht bearbeitet werden`;
  }

  public override wouldBeNoop(): boolean {
    return this.diff.filter((d) => d.newValue !== d.oldValue).length === 0;
  }
}
