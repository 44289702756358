import { EditPropertyDelta } from '../edit-property.delta';
import { invertDiffs, PropertyDiff } from '../../property-diff';
import { UUID } from '../../../../util/math/uuid';
import { JsonIgnore } from '../../../../util/json';
import { DeltaType } from '../../../../util/delta-type.annotation';
import { FromIndex } from '../../../../model/relink/managed-entity';
import { GrafitiPlacardType } from '../../../../model/entity/grafiti-placard-type';

@DeltaType('editPlacardType')
export class EditPlacardTypeDelta extends EditPropertyDelta<keyof GrafitiPlacardType> {
  @JsonIgnore()
  @FromIndex<EditPlacardTypeDelta>((self) => self.entityId)
  private placardType: GrafitiPlacardType;

  public constructor(id: UUID, diffs: PropertyDiff<keyof GrafitiPlacardType>[]) {
    super();
    this.entityId = id;
    this.diff = diffs;
  }

  public apply(): void {
    const type = this.placardType;
    this.forEachDiff(type);
  }

  public onConfirmation(confirmationDelta: EditPlacardTypeDelta): void {}

  public getUndoDelta(): EditPlacardTypeDelta {
    return new EditPlacardTypeDelta(this.entityId, invertDiffs(this.diff));
  }

  public override getErrorMessage(): string {
    return `Plakattyp konnte nicht bearbeitet werden`;
  }
}
