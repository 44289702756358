import {
  HttpContext,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpParams,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';

import { combineLatest, Observable } from 'rxjs';
import { catchError, filter, first, skip, switchMap, tap } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  public constructor(
    private readonly router: Router,
    private readonly authenticationService: AuthenticationService,
    private readonly route: ActivatedRoute
  ) {}

  /**
   * @inheritDoc
   */
  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const cloned = request.clone({
      withCredentials: true,
    });
    return next.handle(cloned).pipe(
      /*tap((e) => {
        console.log(e);
        if (e instanceof HttpResponse) {
          if (
            e.status !== 401 &&
            /http(s?):\/\/(.+)\/api\//.test(e.url) &&
            !this.authenticationService.isAuthenticated()
          ) {
            this.authenticationService.login();
          }
        }
      }),*/
      catchError((error: HttpErrorResponse, originalObservable: Observable<HttpEvent<unknown>>) => {
        // don't block auth errors from the requests for login() and autoLogin()
        if (
          error?.status === 401 &&
          // this.authenticationService.isAuthenticated() &&
          !(error.url.endsWith('/api/auth') /*|| error.url.endsWith('/users/current')*/)
        ) {
          if (this.route.snapshot.firstChild.firstChild.url[0]?.path !== 'login') {
            this.authenticationService
              .logout()
              .pipe(skip(1))
              .subscribe((a) => {
                this.router.navigate(['/auth/login']).then();
              });

            // whenever we are logged in again, retry the http request
            return combineLatest([
              this.authenticationService.getLoggedIn$(),
              // this.authenticationService.sessionLocked$,
              // this.authenticationService.currentUser$,
            ]).pipe(
              filter(([authenticated /*user*/]) => authenticated),
              first(),
              switchMap(() => originalObservable)
            );
          }
        }
        throw error;
      })
    );
  }
}

interface HttpOptions {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  context?: HttpContext;
  observe?: 'body';
  params?:
    | HttpParams
    | {
        [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
      };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}
