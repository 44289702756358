import { Delta } from './delta';
import { DeltaType } from '../../../util/delta-type.annotation';

@DeltaType('noop')
export class NoopDelta extends Delta {
  public constructor() {
    super();
  }
  apply(): void {}

  onConfirmation(confirmationDelta: Delta): void {}

  public getUndoDelta(): Delta {
    return new NoopDelta();
  }

  public override getErrorMessage(): string {
    return `Konnte noop-delta nicht speichern`;
  }
}
