export interface AuthConfigType {
  password: {
    minLength: number;
    maxLength: number;
  };
  username: {
    minLength: number;
    maxLength: number;
    pattern: RegExp;
  };
  fullName: {
    minLength: number;
    maxLength: number;
  };
}

export const AUTH_CONFIG: AuthConfigType = {
  password: { minLength: 6, maxLength: 32 },
  username: {
    minLength: 3,
    maxLength: 32,
    pattern: /^[_\w0-9]{3,}$/,
  },
  fullName: {
    minLength: 2,
    maxLength: 64,
  },
};
