import { Delta } from '../delta';
import { DeltaType } from '../../../../util/delta-type.annotation';
import { JsonIgnore } from '../../../../util/json';
import { DeleteFlyerRunDelta } from './delete-flyer-run-delta';
import { FlyerRunDto } from '../../../../dto/flyer-run.dto';
import { FromIndex } from '../../../../model/relink/managed-entity';
import { GrafitiProject } from '../../../../model/entity/grafiti-project';
import { GrafitiFlyerRun } from '../../../../model/entity/grafiti-flyer-run';

@DeltaType('createFlyerRun')
export class CreateFlyerRunDelta extends Delta {
  @JsonIgnore()
  @FromIndex<CreateFlyerRunDelta>((self) => self.flyerRunDto.projectId)
  private project: GrafitiProject;

  public flyerRunDto: FlyerRunDto;

  constructor(flyerRunDto: FlyerRunDto) {
    super();
    this.flyerRunDto = this.flyerRunDto ?? flyerRunDto;
  }

  public apply(): void {
    const flyerRun = new GrafitiFlyerRun(this.flyerRunDto, this.project);
    this.project.setFlyerRuns([...this.project.getFlyerRuns(), flyerRun]);
  }

  public override onConfirmation(confirmationDelta: CreateFlyerRunDelta) {}

  public override getUndoDelta(): Delta {
    return new DeleteFlyerRunDelta(this.flyerRunDto);
  }

  public override getErrorMessage(): string {
    return `Neuer Flyerbereich konnte nicht gespeichert werden!`;
  }
}
