import { Delta } from '../delta';
import { DeltaType } from '../../../../util/delta-type.annotation';
import { LocationDto } from '../../../../dto/location.dto';
import { DeleteLocationDelta } from './delete-location.delta';
import { FromIndex } from '../../../../model/relink/managed-entity';
import { GrafitiProject } from '../../../../model/entity/grafiti-project';
import { GrafitiLocation } from '../../../../model/entity/grafiti-location';
import { JsonIgnore } from '../../../../util/json';

@DeltaType('createLocation')
export class CreateLocationDelta extends Delta {
  @FromIndex<CreateLocationDelta>((self) => self.locationDto.projectId)
  @JsonIgnore()
  private project: GrafitiProject;

  public locationDto: LocationDto;

  constructor(locationDto: LocationDto) {
    super();
    this.locationDto = locationDto ?? this.locationDto;
  }

  public apply(): void {
    if (this.project.getLocations().find((l) => l.getId() === this.locationDto.id)) {
      return;
    }
    const location = new GrafitiLocation(this.locationDto, this.project);
    this.project.setLocations([...this.project.getLocations(), location]);
  }

  public override onConfirmation(confirmationDelta: CreateLocationDelta) {
    this.project
      .getLocations()
      .find((a) => a.getId() === this.locationDto.id)
      .updateRoleAssignments(confirmationDelta.locationDto.roleAssignments);
  }

  public override getUndoDelta(): Delta {
    return new DeleteLocationDelta(this.locationDto);
  }

  public override getErrorMessage(): string {
    return `Neuer Standort bei '${this.locationDto.position.lat}/${this.locationDto.position.lng}' konnte nicht gespeichert werden!`;
  } //
}
