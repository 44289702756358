import { Delta } from '../delta';
import { DeltaType } from '../../../../util/delta-type.annotation';
import { UUID } from '../../../../util/math/uuid';
import { FromIndex } from '../../../../model/relink/managed-entity';
import { GrafitiActivity } from '../../../../model/entity/grafiti-activity';

@DeltaType('updateActivityImage')
export class UpdateActivityImageDelta extends Delta {
  @FromIndex<UpdateActivityImageDelta>((self) => self.activityId)
  private activity: GrafitiActivity;

  private activityId: UUID;
  private oldImageId: UUID;
  private newImageId: UUID;

  public constructor(activityId: UUID, oldImageId: UUID, newImageId: UUID) {
    super();
    this.oldImageId = oldImageId ?? this.oldImageId;
    this.newImageId = newImageId ?? this.newImageId;
    this.activityId = activityId ?? this.activityId;
  }

  apply(): void {
    if (this.oldImageId !== this.activity.getImageId()) {
      return;
    }

    this.activity.setImageId(this.newImageId);
  }

  onConfirmation(confirmationDelta: Delta): void {}

  getErrorMessage(): string {
    return 'could not update image for activity ' + this.activityId;
  }

  public getUndoDelta(): Delta {
    return new UpdateActivityImageDelta(this.activityId, this.newImageId, this.oldImageId);
  }
}
