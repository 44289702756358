/**
 * Represents a basic REST service.
 * This class provides basic functions to communicate with the
 * REST endpoint of the server.
 */
import { AbstractCommunicationService } from './abstract-communication.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { safePath } from '../util/path';

export abstract class AbstractRestService extends AbstractCommunicationService {
  /**
   * Creates a new AbstractRestService.
   *
   * @param http The HttpClient.
   * @param endpoint endpoint for this REST service, e.g. "download" to prepend all URLs with "api/downloads"
   */
  protected constructor(protected readonly http: HttpClient, endpoint?: string) {
    super(safePath(environment.restEndpoint, endpoint));
  }
}
