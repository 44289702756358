import { Injectable } from '@angular/core';
import { ObservableSet } from '../util/reactive/observable-set';
import { UUID } from '../util/math/uuid';
import { merge, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { ValueSubject } from '../util/reactive/value-subject';
import { GrafitiCommunity } from './entity/grafiti-community';
import { GrafitiRoleAssignmentHolder } from './entity/grafiti-role-assignment-holder';
import { GrafitiUser } from './entity/grafiti-user';
import { PrivateUserDto } from '../dto/private-user.dto';
import { chooseFirst } from '../util/reactive/choose-first';
import { GrafitiProject } from './entity/grafiti-project';
import { GrafitiPrivateUser } from './entity/grafiti-private-user';
import { SparseUserDto } from '../dto/sparse-user.dto';

interface IStore<T> {
  find(id: UUID): T;
  find$(id: UUID): Observable<T>;
  findAll(): T[];
  findAll$(): Observable<T[]>;
  save(t: T): void;
  delete(t: T): void;
}
interface IUserspace {
  getCommunities(): ObservableSet<GrafitiCommunity, UUID>;
  getUserStore(): IStore<GrafitiUser>;
  getOrgaStore(): IStore<GrafitiRoleAssignmentHolder>;
}

/**
 * A userspace is the top level root node of the entire loaded data.
 * It contains everything the user can see. Think of it maybe as a unix home dir.
 */
@Injectable({
  providedIn: 'root',
})
export class Userspace implements IUserspace {
  private communities$$ = new ObservableSet<GrafitiCommunity, UUID>((community) => community.getId());
  private userStore = new UserStore();
  private orgaStore = new OrgaStore(this);

  constructor() {}

  public getCommunities(): ObservableSet<GrafitiCommunity, UUID> {
    return this.communities$$;
  }

  public getUserStore(): UserStore {
    return this.userStore;
  }

  public getOrgaStore(): IStore<GrafitiRoleAssignmentHolder> {
    return this.orgaStore;
  }
}

class UserStore implements IStore<GrafitiUser> {
  private users = new ObservableSet<GrafitiUser, UUID>((user) => user.getId());
  public createFromDto(userDto: PrivateUserDto): GrafitiUser {
    const user = new GrafitiUser(userDto);
    this.save(user);
    return user;
  }
  public save(user: GrafitiUser): GrafitiUser {
    if (this.users.has(user.getId())) {
      this.users.update(user);
    } else {
      this.users.add(user);
    }
    return user;
  }

  public find(id: UUID): GrafitiUser {
    return this.users.get(id);
  }

  public findByUsername(username: string): GrafitiUser {
    return this.users.find((u) => u.getUsername() === username);
  }

  public find$(id: UUID): Observable<GrafitiUser> {
    return this.users.get$(id);
  }

  public findAll(): GrafitiUser[] {
    return this.users.array();
  }

  public findAll$(): Observable<GrafitiUser[]> {
    return this.users.array$;
  }

  public delete(user: GrafitiUser): void {
    this.users.delete(user.getId());
  }

  public findLoggedInUser(): GrafitiPrivateUser {
    return this.users.array().find((user) => user.isActive()) as GrafitiPrivateUser;
  }

  public findLoggedInUser$(): Observable<GrafitiPrivateUser> {
    return this.users.array$.pipe(
      map((users) => users.find((user) => user.isActive()))
    ) as Observable<GrafitiPrivateUser>;
  }

  public updateAll(userDtos: SparseUserDto[]): void {
    const old = this.users.array();
    const firstLoggedIn = this.findLoggedInUser().buildDto();
    old.forEach((u) => u.destroy());
    const updatedMeDto = { ...firstLoggedIn, ...userDtos.find((u) => u.id === firstLoggedIn.id) } as PrivateUserDto;
    const updatedLoggedIn = new GrafitiPrivateUser(updatedMeDto);
    const newUsers = [
      updatedLoggedIn,
      ...userDtos.filter((u) => u.id !== firstLoggedIn.id).map((user) => new GrafitiUser(user)),
    ];
    this.users.clear();
    this.users.addAll(newUsers);
  }

  public getSetting$(key: string): Observable<string> {
    return this.findLoggedInUser$().pipe(
      filter((u) => !!u),
      switchMap((u) => u.getSettings$()),
      map((settings) => settings.get(key)),
      distinctUntilChanged()
    );
  }
}

class OrgaStore implements IStore<GrafitiRoleAssignmentHolder> {
  private readonly communities$: Observable<GrafitiRoleAssignmentHolder[]>;
  private readonly projects$: Observable<GrafitiRoleAssignmentHolder[]>;

  public constructor(private readonly userspace: IUserspace) {
    this.communities$ = userspace.getCommunities().array$;
    this.projects$ = userspace
      .getCommunities()
      .array$.pipe(switchMap((communities) => merge(...communities.map((community) => community.getProjects$()))));
  }

  find(id: String): GrafitiRoleAssignmentHolder {
    return this.userspace.getCommunities().get(id) ?? this.findProject(id);
  }

  find$(id: String): Observable<GrafitiRoleAssignmentHolder> {
    return chooseFirst<GrafitiRoleAssignmentHolder>(
      this.userspace.getCommunities().get$(id),
      this.projects$.pipe(
        map((projects) => projects.find((project) => project.getId() === id)),
        filter((project) => !!project)
      )
    );
  }

  findAll(): GrafitiRoleAssignmentHolder[] {
    return [
      ...(this.userspace.getCommunities().array() as GrafitiRoleAssignmentHolder[]),
      ...this.userspace
        .getCommunities()
        .array()
        .flatMap((c) => c.getProjects()),
    ];
  }

  findAll$(): Observable<GrafitiRoleAssignmentHolder[]> {
    return chooseFirst<GrafitiRoleAssignmentHolder[]>(this.userspace.getCommunities().array$, this.projects$);
  }

  private findProject(id: String): GrafitiProject {
    return this.userspace
      .getCommunities()
      .array()
      .flatMap((community) => community.getProjects())
      .find((project) => project.getId() == id);
  }

  delete(t: GrafitiRoleAssignmentHolder): void {}

  save(t: GrafitiRoleAssignmentHolder): void {
    throw new Error('Unsupported Operation!');
  }
}
