import { Delta } from '../delta';
import { DeltaType } from '../../../../util/delta-type.annotation';
import { UUID } from '../../../../util/math/uuid';
import { FlyerTypeDto } from '../../../../dto/flyer-type.dto';
import { CreateFlyerTypeDelta } from './create-flyer-type-delta';
import { JsonIgnore } from '../../../../util/json';
import { FromIndex } from '../../../../model/relink/managed-entity';
import { GrafitiFlyerType } from '../../../../model/entity/grafiti-flyer-type';
import { DeltaFailedException } from '../../delta-failed-exception';

@DeltaType('deleteFlyerType')
export class DeleteFlyerTypeDelta extends Delta {
  public flyerTypeId: UUID;

  @JsonIgnore()
  private flyerTypeDto: FlyerTypeDto;

  @JsonIgnore()
  @FromIndex<DeleteFlyerTypeDelta>((self) => self.flyerTypeId)
  private flyerType: GrafitiFlyerType;

  public constructor(typeDto: FlyerTypeDto) {
    super();
    this.flyerTypeId = typeDto?.id ?? this.flyerTypeId;
  }

  public apply(): void {
    this.flyerTypeDto = this.flyerType.buildDto();

    if (
      this.flyerType
        .getProject()
        .getFlyerRuns()
        .filter((fr) => fr.getType().getId() === this.flyerType.getId()).length > 0
    ) {
      throw new Error('Flyer type is in use');
    }

    const project = this.flyerType.getProject();
    project.setFlyerTypes(project.getFlyerTypes().filter((ft) => ft.getId() !== this.flyerTypeId));
    this.flyerType.destroy();
  }

  public onConfirmation(confirmationDelta: DeleteFlyerTypeDelta): void {}

  public getUndoDelta(): Delta {
    return new CreateFlyerTypeDelta(this.flyerTypeDto);
  }

  public override getErrorMessage(): string {
    return `Flyertyp konnte nicht gelöscht werden`;
  }
}
