export function hash(obj: unknown, depth = 1): number {
  if (typeof obj === 'undefined') {
    return hash('undefined');
  }
  if (typeof obj === 'function') {
    return hash(JSON.stringify(obj));
  }
  if (Array.isArray(obj)) {
    return hash((obj as unknown[]).map(hash).join(','));
  }

  if (typeof obj !== 'string') {
    const hcFn = obj['hashCode'];
    if (hcFn && typeof hcFn === 'function') {
      return hcFn();
    }

    return hash(JSON.safeStringify(obj));
  }
  let hashCode = 0;

  if (obj.length === 0) {
    return hashCode;
  }
  for (let i = 0; i < obj.length; i++) {
    let chr = obj.charCodeAt(i);
    hashCode = (hashCode << 5) - hashCode + chr;
    hashCode |= 0; // Convert to 32bit integer
  }
  return hashCode;
}
