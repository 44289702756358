import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { CheckPropertyDto } from '../../dto/check-property.dto';
import { HttpClient } from '@angular/common/http';
import { AbstractControl, FormBuilder, FormControl, FormGroup, NgModel, Validators } from '@angular/forms';
import { AUTH_CONFIG, AuthConfigType } from '../auth-config';
import { AuthenticationService } from '../authentication.service';
import { ToastrService } from 'ngx-toastr';
import { CustomGrafitiNameValidators, GrafitiValidators } from '../../util/grafiti-validators';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { LoginService } from '../../login/login.service';
import { catchError, delay, switchMap, tap } from 'rxjs/operators';
import { error } from '../../util/result';

@Component({
  selector: 'gr-register',
  templateUrl: './gr-register.component.html',
  styleUrls: ['gr-register.component.scss'],
})
export class GrRegisterComponent implements OnInit {
  protected registerGroup: FormGroup;

  protected submitted = false;
  protected pending: boolean;

  protected showPassword = false;
  protected faEye = faEye;
  protected faEyeSlash = faEyeSlash;

  protected config: AuthConfigType = AUTH_CONFIG;

  constructor(
    protected readonly router: Router,
    private readonly http: HttpClient,
    private readonly toastr: ToastrService,
    protected authService: AuthenticationService,
    private readonly formBuilder: FormBuilder,
    private readonly loginService: LoginService
  ) {
    this.registerGroup = this.formBuilder.group(
      {
        fullName: new FormControl(
          '',
          CustomGrafitiNameValidators(this.config.fullName.minLength, this.config.fullName.maxLength)
        ),
        username: new FormControl('', [
          GrafitiValidators.usernameCharsValidator,
          Validators.required,
          Validators.minLength(this.config.username.minLength),
          Validators.maxLength(this.config.username.maxLength),
        ]),
        password: new FormControl('', [Validators.minLength(8), Validators.required]),
        rePass: new FormControl('', [Validators.minLength(8), Validators.required]),
        email: new FormControl('', [Validators.email, Validators.required]),
      },
      {
        validators: this.checkPasswords,
      }
    );
  }

  public ngOnInit() {
    /*this.usernameModel.control.addAsyncValidators((control) =>
      control.valueChanges.pipe(
        map((e) => {
          return e;
        }),
        distinctUntilChanged(),
        debounceTime(500),
        switchMap((name) => this.usernameAvailabilityRequest$(name)),
        map((res) => {
          return {
            inUse: true,
          };
        })
      )
    );*/
  }

  checkUsername(username: string): void {
    // this.usernameToCheck$.next(username);
  }

  /**
   * Sends a request to the server checking if the given username is in use.
   *
   * @param username the username to check for
   * @returns an observable containing the response
   */
  public usernameAvailabilityRequest$(username: string): Observable<CheckPropertyDto> {
    if (!username || username === '') {
      return of({ passed: false, propertyName: 'username' });
    }
    return this.http.get<CheckPropertyDto>(`http://localhost:8082/api/auth/check/username/${username}`);
  }

  public register(event: Event): void {
    event.preventDefault();
    this.submitted = true;
    this.pending = true;
    this.authService
      .register(this.username.value, this.password.value, this.email.value, this.fullName.value)
      .pipe(
        catchError((error) => {
          this.pending = false;
          this.toastr.error(error.message, 'Registrierung fehlgeschlagen');
          /*if (error.error === 'username_exists') {
            this.registrationFormGroup.controls.username.setErrors({ inUse: true });
          } else if (error.error === 'email') {
            this.registrationFormGroup.controls.email.setErrors({ invalid: true });
          } else if (error.error === 'username_invalid') {
            this.registrationFormGroup.controls.username.setErrors({ invalid: true });
          }*/
          return of();
        }),
        tap(() => {
          this.pending = false;
          this.toastr.success('Du wirst in einigen Sekunden automatisch eingeloggt', 'Registrierung erfolgreich!');
        }),
        delay(1000),
        switchMap((a) => {
          return this.authService.login(this.username.value, this.password.value, true).unwrap((err) => of(error(err)));
        }),
        delay(1000),
        switchMap((result) => {
          return this.authService.autoLogin(true).pipe(tap(() => this.router.navigate(['/pages/home'])));
        })
      )
      .subscribe();
  }

  protected get fullName(): AbstractControl {
    return this.registerGroup.get('fullName');
  }

  protected get username(): AbstractControl {
    return this.registerGroup.get('username');
  }

  protected get email(): AbstractControl {
    return this.registerGroup.get('email');
  }

  protected get password(): AbstractControl {
    return this.registerGroup.get('password');
  }

  protected get rePass(): AbstractControl {
    return this.registerGroup.get('rePass');
  }
  private checkPasswords(group: FormGroup) {
    const pass = group.controls.password.value;
    const confirmPass = group.controls.rePass.value;

    return pass === confirmPass ? null : { mismatch: true };
  }

  protected toggleVisibility(evt: Event): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.showPassword = !this.showPassword;
  }
}
