import { Delta } from '../delta';
import { UUID } from '../../../../util/math/uuid';
import { InviteLinkDto } from '../../../../dto/invite-link.dto';
import { FromIndex } from '../../../../model/relink/managed-entity';
import { GrafitiCommunity } from '../../../../model/entity/grafiti-community';
import { DeltaType } from '../../../../util/delta-type.annotation';

@DeltaType('deleteInviteLink')
export class DeleteInvitelinkDelta extends Delta {
  private dto: InviteLinkDto;

  @FromIndex<DeleteInvitelinkDelta>((self) => self.dto.communityId)
  private community: GrafitiCommunity;

  public constructor(inviteLinkDto: InviteLinkDto) {
    super();

    this.dto = inviteLinkDto ?? this.dto;
  }

  public apply(): void {}

  public override onConfirmation(confirmationDelta: DeleteInvitelinkDelta): void {
    this.requireTrue(this.community instanceof GrafitiCommunity, 'Invite links are only implemented for communities');
    const inviteLink = confirmationDelta.dto;
    this.community.setInviteLinks(this.community.getInviteLinks().filter((link) => link.id !== inviteLink.id));
  }

  getErrorMessage(): string {
    return 'could not create invite link!';
  }
}
