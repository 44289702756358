import { APP_INITIALIZER, NgModule } from '@angular/core';
import { LanguageService } from './language.service';
import { appInitializerFactory } from './app-initializer.factory';
import { TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { createTranslateLoader } from './http-loader.factory';
import { HttpClient } from '@angular/common/http';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';

@NgModule({
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [LanguageService],
      multi: true,
    },
  ],
})
export class GrafitiTranslateModule {}
