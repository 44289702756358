import { Delta } from '../delta';
import { DeltaType } from '../../../../util/delta-type.annotation';
import { ActivityDto } from '../../../../dto/activity.dto';
import { DeleteActivityDelta } from './delete-activity-delta';
import { JsonIgnore } from '../../../../util/json';
import { FromIndex } from '../../../../model/relink/managed-entity';
import { GrafitiPlacard } from '../../../../model/entity/grafiti-placard';
import { GrafitiPlacardType } from '../../../../model/entity/grafiti-placard-type';
import { GrafitiActivity } from '../../../../model/entity/grafiti-activity';

@DeltaType('createActivity')
export class CreateActivityDelta extends Delta {
  @JsonIgnore()
  @FromIndex<CreateActivityDelta>((self) => self.activityDto.placard)
  private placard: GrafitiPlacard;

  @JsonIgnore()
  @FromIndex<CreateActivityDelta>((self) => self.activityDto.addedType)
  private addedType: GrafitiPlacardType;

  public activityDto: ActivityDto;

  public constructor(activityDto: ActivityDto) {
    super();
    this.activityDto = activityDto ?? this.activityDto;
  }

  public apply(): void {
    const activity = new GrafitiActivity(this.activityDto, this.placard);
    if (this.activityDto.addedType !== null) {
      if (!this.addedType) {
        throw new Error('Provided addedType does not exist!');
      }
      activity.setAddedType(this.addedType);
    }

    this.placard.setActivities([...this.placard.getActivities(), activity]);
  }

  public onConfirmation(confirmationDelta: CreateActivityDelta): void {}

  public getUndoDelta(): Delta {
    return new DeleteActivityDelta(this.activityDto);
  }

  public override getErrorMessage(): string {
    return `Neue Aktivität konnte nicht gespeichert werden`;
  }
}
