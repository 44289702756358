import { LocationDto } from '../../dto/location.dto';
import { AddressDto } from '../../dto/address.dto';
import { GrafitiProject } from './grafiti-project';
import { ProjectEntity } from './project-entity';
import { combineLatest, merge, Observable } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { GrafitiPlacard } from './grafiti-placard';
import { LocationState } from '../../util/location-state';
import { getLocationStateForActivityType } from './activity-type';
import { ValueSubject } from '../../util/reactive/value-subject';
import { GrafitiEntity } from './grafiti-entity';
import { Searchable } from './searchable';
import { GrafitiActivity } from './grafiti-activity';
import { Feature, point, Point } from '@turf/turf';
import { SimpleCoords } from '../../dto/simple-coords';
import { forContentDefault, GrafitiPermission, AccessManaged } from '../../dto/permission';
import { Sortable } from './sortable';
import { GrafitiRoleAssignmentHolder } from './grafiti-role-assignment-holder';
import { SparseLocationDto } from '../../dto/sparse-location.dto';
import { RoleAssignmentHolder } from './role-assignment-holder';
import { NotesHolder } from '../interface/notes-holder';
import { GrafitiNote } from './grafiti-note';
import { comparingNumber } from '../../util/comparator';

export class GrafitiLocation
  extends GrafitiRoleAssignmentHolder
  implements ProjectEntity, Searchable, Sortable, NotesHolder
{
  private readonly locationState$: Observable<LocationState>;
  private readonly project$: ValueSubject<GrafitiProject>;
  private readonly placards$: ValueSubject<GrafitiPlacard[]>;
  private readonly activities$: Observable<GrafitiActivity[]>;
  private readonly notes$: ValueSubject<GrafitiNote[]>;

  public constructor(dto: LocationDto, project: GrafitiProject) {
    super(dto);

    this.setPosition(dto.position);
    this.setAddress(dto.address);

    this.project$ = new ValueSubject<GrafitiProject>(project);
    this.placards$ = new ValueSubject<GrafitiPlacard[]>(
      dto.placards.map((placard) => new GrafitiPlacard(placard, this))
    );

    this.activities$ = this.placards$.pipe(
      switchMap((placards) => merge(...placards.map((placard) => placard.getActivities$())))
    );

    this.locationState$ = this.getActivities$().pipe(
      map((activities) => {
        const activitiesByPlacard = activities.groupBy((activity) => activity.getPlacard());
        const newestActivities = activitiesByPlacard
          .map((as) => {
            return as.max((a) => a.getCreated());
          })
          .nonNull();
        // ich bracuh die neueste aktivität

        const mostImportantActivity = newestActivities.max((a) => a.getActivityType().getOrder());
        return getLocationStateForActivityType(mostImportantActivity?.getActivityType().getTypeName());
      }),
      distinctUntilChanged()
    );

    this.notes$ = new ValueSubject<GrafitiNote[]>([], this.destroy$);
    this.setNotes(dto.notes.map((note) => new GrafitiNote(note)));

    this.ready();
  }

  public getPosition(): SimpleCoords {
    const pos = { ...(this.getEmbeddedAttribute('position') as SimpleCoords) };
    // Object.freeze(pos);
    return pos;
  }

  public setPosition(pos: SimpleCoords): void {
    this.setEmbeddedAttribute('position', pos);
  }

  public getPosition$(): Observable<SimpleCoords> {
    return this.getEmbeddedAttribute$('position');
  }

  public getAddress(): AddressDto {
    const addr = this.getEmbeddedAttribute('address');
    Object.freeze(addr);
    return addr as AddressDto;
  }

  public setAddress(address: AddressDto): void {
    this.setEmbeddedAttribute('address', address);
  }

  public getAddress$(): Observable<AddressDto> {
    return this.getEmbeddedAttribute$('address');
  }

  public setPlacards(placards: GrafitiPlacard[]): void {
    this.placards$.next(placards);
  }

  public getPlacards(): GrafitiPlacard[] {
    return this.placards$.getValue();
  }

  public getPlacards$(): Observable<GrafitiPlacard[]> {
    return this.placards$;
  }

  public getActivities(): GrafitiActivity[] {
    return this.getPlacards()
      .flatMap((a) => a.getActivities())
      .distinct();
  }

  public getActivities$(): Observable<GrafitiActivity[]> {
    return this.activities$;
  }

  public getStringifiedAddress(): string {
    if (this.getAddress().street && this.getAddress().houseNumber) {
      return this.getAddress().street + ' ' + this.getAddress().houseNumber;
    } else if (this.getAddress().street) {
      return this.getAddress().street;
    } else {
      const label = this.getAddress().description.split(',')[0];
      if (label) {
        return label;
      }
      return 'Unbekannt';
    }
  }

  public getStringifiedAddress$(): Observable<string> {
    return this.getAddress$().pipe(map(() => this.getStringifiedAddress()));
  }

  public getAllAddressPropertiesAsString(): string {
    let str = '';
    for (let addressKey in this.getAddress()) {
      str += this.getAddress()[addressKey] + ',';
    }
    return str;
  }

  public getStringifiedPosition(): string {
    return this.getPosition().lat + ', ' + this.getPosition().lng;
    //  return this.pos.getLat().toPrecision(5) + ', ' + this.pos.getLong().toPrecision(5);
  }

  public getArea(): string {
    if (this.getAddress().district && this.getAddress().district.length > 0) {
      return this.getAddress().district;
    } else if (this.getAddress().city && this.getAddress().city.length > 0) {
      return this.getAddress().city;
    } else {
      return 'Unbekannt';
    }
  }

  public getProject(): GrafitiProject {
    return this.project$.getValue();
  }

  public getLocationState(): LocationState {
    const newestActivities = this.getPlacards()
      .map((placard) => placard.getActivitiesAscendingByDate().last())
      .nonNull();

    const mostImportantActivity = newestActivities.max((activity) => activity.getActivityType().getOrder());

    return getLocationStateForActivityType(mostImportantActivity?.getActivityType().getTypeName());
  }

  public getLocationState$(): Observable<LocationState> {
    return this.locationState$;
  }

  public getLatitude$(): Observable<number> {
    return this.getPosition$().pipe(map((p) => p.lat));
  }

  public getLongitude$(): Observable<number> {
    return this.getPosition$().pipe(map((p) => p.lng));
  }

  protected destructor(): void {}

  public buildDto(): LocationDto {
    const dto = super.buildDto() as LocationDto;
    dto.address = this.getAddress();
    dto.position = this.getPosition();
    dto.projectId = this.getProject()?.getId() ?? null;
    dto.placards = this.getPlacards().map((a) => a.buildDto());
    return dto;
  }

  public matchesQueryFilters(queries: string): boolean {
    return false;
  }

  public matchesTextFilter(filter: string): boolean {
    return this.getEntireSearchString().includes(filter);
  }

  public get address(): AddressDto {
    return this.getAddress();
  }

  public getEntireSearchString(): string {
    return [
      // maybe add more things in the future
      this.getAllAddressPropertiesAsString(),
    ]
      .join(',')
      .toLowerCase();
  }

  public getTurfPoint(): Feature<Point> {
    return point([this.getPosition().lng, this.getPosition().lat]);
  }

  public hasPermission(permission: GrafitiPermission): boolean {
    return this.anyRoleProvidesPermission(permission) || this.getProject().hasPermission(forContentDefault(permission));
  }

  public hasPermission$(permission: GrafitiPermission): Observable<boolean> {
    return combineLatest([
      this.anyRoleProvidesPermission$(permission),
      this.getProject().hasPermission$(forContentDefault(permission)),
    ]).pipe(map(([myRes, projectRes]) => myRes || projectRes));
  }

  public getSortString(column: string): string {
    switch (column) {
      case 'address':
        return this.getStringifiedAddress();
      case 'description':
        return this.getLocationState();
      default:
        return '';
    }
  }

  public getNotes(): GrafitiNote[] {
    return this.notes$.getValue();
  }

  public getNotes$(): Observable<GrafitiNote[]> {
    return this.notes$;
  }

  public setNotes(notes: GrafitiNote[]): void {
    this.notes$.next([...notes].sort(comparingNumber((e) => e.getNoteIndex())));
  }
}
