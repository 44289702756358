<mat-card class="grpanel">
  <!--<mat-card-title class="tw-flex tw-justify-center">
    <h1 id="title">{{ 'AUTH.LOGIN.TITLE' | translate }}</h1>
  </mat-card-title>-->
  <div class="image-container">
    <img src="assets/images/grafiti-logo-black.png" width="400px" />
  </div>
  <form (submit)="login()" [formGroup]="formGroup" aria-labelledby="title" class="tw-flex tw-flex-col tw-mb-6">
    <mat-form-field class="tw-mt-5">
      <mat-label>Benutzername</mat-label>
      <input matInput name="username" formControlName="username" placeholder="Benutzername" autofocus />
    </mat-form-field>
    <ng-container *ngIf="formGroup.invalid && formGroup.touched">
      <mat-error *ngIf="formGroup.get('username').errors?.required">{{
        'AUTH.LOGIN.USERNAME.ERROR.REQUIRED' | translate
      }}</mat-error>
      <mat-error *ngIf="formGroup.get('username').errors?.pattern">{{
        'AUTH.LOGIN.USERNAME.ERROR.PATTERN' | translate
      }}</mat-error>
    </ng-container>

    <mat-form-field>
      <mat-label>{{ 'AUTH.LOGIN.PASSWORD.LABEL' | translate }}</mat-label>
      <input
        matInput
        name="password"
        formControlName="password"
        [type]="showPassword ? 'text' : 'password'"
        placeholder="{{ 'AUTH.LOGIN.PASSWORD.PLACEHOLDER' | translate }}" />
      <button matIconSuffix mat-icon-button [autofocus]="false" type="button" (click)="toggleVisibility($event)">
        <fa-icon [icon]="showPassword ? faEye : faEyeSlash"></fa-icon>
      </button>
    </mat-form-field>
    <ng-container *ngIf="formGroup.invalid && formGroup.touched">
      <mat-error *ngIf="formGroup.get('password').errors?.required">{{
        'AUTH.LOGIN.PASSWORD.ERROR.REQUIRED' | translate
      }}</mat-error>
      <mat-error *ngIf="formGroup.get('password').errors?.minlength || formGroup.get('password').errors?.maxlength">
        {{
          'AUTH.LOGIN.PASSWORD.ERROR.SIZE'
            | translate : { min: config.password.minLength, max: config.password.maxLength }
        }}
      </mat-error>
    </ng-container>
    <mat-error *ngIf="loginError">{{ loginError }}</mat-error>
    <!--
      <mat-checkbox (change)="rememberMe = $event.checked">{{ 'AUTH.LOGIN.REMEMBERME' | translate }}</mat-checkbox>
    -->
    <button
      class="tw-w-full"
      mat-raised-button
      color="accent"
      [disabled]="submitted || !formGroup.valid"
      [class.btn-pulse]="submitted">
      <span>{{ 'AUTH.LOGIN.LOGIN' | translate }}</span>
    </button>
  </form>

  <p>
    <a class="forgot-password" routerLink="../request-password">{{ 'AUTH.LOGIN.RESET_PASSWORD' | translate }}</a>
  </p>
  <p>
    {{ 'AUTH.LOGIN.REGISTER_PREFIX' | translate
    }}<a class="tw-font-bold" routerLink="../register">{{ 'AUTH.LOGIN.REGISTER' | translate }}</a>
  </p>

  <mat-divider></mat-divider>
  <br />

  <app-telegram-login-widget [style.display]="openedFromTelegram ? 'none' : 'block'"></app-telegram-login-widget>
</mat-card>
